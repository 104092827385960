import React, { useContext, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import styled, { keyframes } from 'styled-components';
import { Button, Modal } from 'antd';
import { ArrowDropDown } from '@styled-icons/material';
import theme from 'shared/theme';
import {
  clearRedirect, partTypeFromDesc, popRedirect, redirect,
} from 'shared/util';
import { useTestDataAtom } from 'shared/state/pricingState';
import { SaveText, SpinnerWrapper, SaveSpinner } from 'shared/styledComponents/utility';
import useFirebase from 'vendor/Firebase';
import { currentQaTicketAtom, QA_TICKETS_COLLECTION, qaTicketEdited } from 'shared/state/qualityAssuranceState';
import { AuthContext } from 'vendor/Firebase/AuthProvider';
import firebase from 'firebase';
import SimpleModal from '../../../../../shared/components/Utility/SimpleModal';
import { inventoryPartEditedAtom } from '../../../../../shared/state/inventoryState';

/**
 * Button component that is responsible for saving inventory records
 */

const DropdownIcon = styled(ArrowDropDown)`
  width: 24px;
  margin-bottom: 2px;
`;

const SaveButton = styled(Button)`
  margin-left: ${theme.spacing(3)};
  background-color: ${(props: any) => (props.testMode ? theme.palette.error.D500 : theme.palette.success[900])} !important;
  border-color: ${(props: any) => (props.testMode ? theme.palette.error.D500 : theme.palette.neutral.white)};
  border-radius: ${theme.spacing(1)};
  border: none;
  color: ${theme.palette.neutral.white};

  &:hover {
    color: ${theme.palette.neutral.white};
    background-color: ${(props: any) => (props.testMode ? theme.palette.error.hue : theme.palette.success.D100)} !important;
    border-color: transparent;
  }
`;
const SaveQATicketButton = () => {
  const { currentUser } = useContext(AuthContext);
  const { firestore, database } = useFirebase();
  const qaTicket = useRecoilValue(currentQaTicketAtom);
  const useTestData = useRecoilValue(useTestDataAtom);
  const qaDbCollectionString = useRecoilValue(QA_TICKETS_COLLECTION);
  const setQATicketEdited = useSetRecoilState(qaTicketEdited);

  // @ts-ignore
  const [isSaving, setIsSaving] = useState(false);

  const onSave = async (e: any) => {
    // Validation: part cannot be saved unless passing these checks
    if (qaTicket.nonConformanceDescription === '' && qaTicket.nonConformanceExceptionCode === 'Other') {
      Modal.error({
        title: 'Missing Description!',
        content: 'A description of the non-conformity is required to save the ticket!',
      });
      return;
    }
    // VALIDATION: repeat as needed

    // END VALIDATION
    setIsSaving(true);
    // save logic
    await firestore.collection(qaDbCollectionString).doc(qaTicket.id).set({
      ...qaTicket,
      type: partTypeFromDesc(qaTicket.partDescription),
      estimatedCost: qaTicket.estimatedCost || 0,
      lastUpdatedDate: firebase.firestore.Timestamp.fromDate(new Date()),
    });
    // post-save
    if (window.location.href.match(/new/)) {
      const currentRecordNumberData = await database.ref('/recordNumbers/qaTicket').once('value');
      const currentRecordNumber = currentRecordNumberData.val();
      if (currentRecordNumber.toString() === qaTicket.id.toString()) {
        await database.ref('/recordNumbers/qaTicket').set(currentRecordNumber + 1);
      }
    }
    setIsSaving(false);
    setQATicketEdited(false);
  };

  return (
    <>
      {isSaving ? (
        <SpinnerWrapper>
          <SaveSpinner />
        </SpinnerWrapper>
      ) : (
        <SaveButton id="inventory-record-save-button" type="primary" onClick={onSave} test={useTestData}>Save</SaveButton>
      )}
    </>
  );
};

export default SaveQATicketButton;
