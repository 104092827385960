import { find } from 'lodash';
import {
  IItemAdjustment,
  IQBOItem, IQBOSparseItemAdjustmentLine, IQuantityAdjustmentItem,
} from 'shared/types/qbo';
import axios from 'axios';
import QBOItem from './item';
import API_BASE_URL from './index';
import { qboDateString } from '../calendar';
import { IInventoryPart, IUploadItem } from '../../types/dbRecords';
import { adminUserEmailsAtom } from '../../state/routingState';

const quantityAdjustmentItem = (i: IInventoryPart|IUploadItem, inventoryParts: IInventoryPart[]) => {
  const part = find(inventoryParts, (p) => p.Sku === i.Sku);
  if (!part) return { ...i, qtyDiff: 0 };
  return { ...part, qtyDiff: i.QtyOnHand - part.QtyOnHand };
};
const generateLineItems = async (quantityAdjustmentItems: IQuantityAdjustmentItem[], qboItems: IQBOItem[]|null = null): Promise<IQBOSparseItemAdjustmentLine[]> => {
  if (quantityAdjustmentItems.length === 0) return [];
  const _qboItems = qboItems !== null ? qboItems : await QBOItem.fetchMany(quantityAdjustmentItems.map((i) => i.Sku));
  return _qboItems.map((i: IQBOItem, index: number) => {
    const adjustmentItem = find(quantityAdjustmentItems, (q: IQBOItem) => q.Sku === i.Sku) as IQuantityAdjustmentItem;
    return {
      DetailType: 'ItemAdjustmentLineDetail',
      ItemAdjustmentLineDetail: {
        ItemRef: {
          value: i.Id,
          name: i.Sku,
        },
        QtyDiff: adjustmentItem?.adjustmentQuantity || 0,
      },
    };
  }).filter((i) => i.ItemAdjustmentLineDetail.QtyDiff !== 0) as IQBOSparseItemAdjustmentLine[];
};

const itemAdjustmentFromParts = async (quantityAdjustments: IQuantityAdjustmentItem[], qboItems: IQBOItem[]|null = null, adjustmentDate: Date|null, memo: string) => {
  if (quantityAdjustments.length === 0) return null;
  const LineItems = await generateLineItems(quantityAdjustments, qboItems);
  const date = adjustmentDate || new Date();
  return {
    sparse: true,
    TxnDate: qboDateString(date),
    AdjustAccountRef: {
      value: '259', // inventory shrinkage account id
    },
    PrivateNote: memo,
    Line: LineItems,
  };
};

const create = async (itemAdjustment: IItemAdjustment) => {
  if (itemAdjustment?.Line?.length === 0) return true; // no items needed adjusting, no reason to go further and no real error
  const responseData = await axios.post(`${API_BASE_URL}/inventoryadjustment/create`, itemAdjustment);
  return responseData.data.json.InventoryAdjustment.Id;
};

export default {
  create,
  generateLineItems,
  itemAdjustmentFromParts,
  quantityAdjustmentItem,
};
