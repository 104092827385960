import React, { useEffect } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import useFirebase from 'vendor/Firebase';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { find } from 'lodash';
import shortid from 'shortid';
import styled from 'styled-components';
import qs from 'qs';
import {
  Divider,
} from 'antd';
import { customerPartsAtom } from 'shared/state/pricingState';
import { ConfigurationCol } from 'shared/pageElements/styledComponents';
import { adminUserEmailsAtom, salesOrderEditedAtom, superAdminUserEmailAtom } from 'shared/state/routingState';
import OrderConfirmationPDFDrawer from 'pages/PDF/Display/OrderConfimationPDFDrawer';
import WorkOrderPDFDrawer from 'pages/PDF/Display/WorkOrderPDFDrawer';
import InvoicePDFDrawer from 'pages/PDF/Display/InvoicePDFDrawer';
import {
  ICustomerRecord, IOrderItem, IShipment, IShippingAddress,
} from 'shared/types/dbRecords';
import { toppedOrBound, orderType } from 'shared/data/order';
import {
  currentShopOrderAtom, NEXT_ORDER_NUMBER_PATH,
  orderItemsAtom, ORDERS_DB_COLLECTION, orderShipmentsAtom, showShipOrderDrawerAtom,
} from 'shared/state/orderState';
import theme from 'shared/theme';
import DetailInputWithCallback from 'shared/components/Input/DetailInputWithCallback';
import DetailDateWithCallback from 'shared/components/Input/DetailDateWIthCallback';
import { devLog } from 'shared/util/logging';
import { currentCustomerAtom, customersAtom } from 'shared/state/customerState';
import PackSlipPDFDrawer from 'pages/PDF/Display/PackSlipPDFDrawer';
import {
  ActionRow,
  AddRunnerColumn,
  RunnerHeaderRow,
  RunnerPageTitle,
} from '../ProductionSchedule/styledComponents';
import {
  FieldRow,
  FieldWrapper, OrderShipmentsWrapper,
  WorkOrderWrapper,
} from '../ProductionSchedule/Components/styledComponents';
import WorkOrderList from './Components/WorkOrder/WorkOrderList';
import PrintDocumentButton from './Components/SalesOrderRecord/Buttons/PrintDocumentButton';
import OrderItemList from './Components/OrderItems/OrderItemList';
import OrderValue from './Components/SalesOrderRecord/SalesOrderDetail/OrderValue';
import PartCount from './Components/SalesOrderRecord/SalesOrderDetail/PartCount';
import OrderCustomerSearchSelect from './Components/SalesOrderRecord/OrderCustomerSearchSelect';
import OrderJobType from './Components/SalesOrderRecord/SalesOrderDetail/OrderJobType';
import OrderToppedOrBound from './Components/SalesOrderRecord/SalesOrderDetail/OrderToppedOrBound';
import OrderWeightReduction from './Components/SalesOrderRecord/SalesOrderDetail/OrderWeightReduction';
import OrderFinishingRequired from './Components/SalesOrderRecord/SalesOrderDetail/OrderFinishingRequired';
import OrderHouseSample from './Components/SalesOrderRecord/SalesOrderDetail/OrderHouseSample';
import OrderHoldStatus from './Components/SalesOrderRecord/SalesOrderDetail/OrderHoldStatus';
import DeleteOrderButton from './Components/SalesOrderRecord/Buttons/DeleteOrderButton';
import { IRunner, IShopOrder, IWorkOrder } from './types';
import SaveDocumentButton from './Components/SalesOrderRecord/Buttons/SaveDocumentButton';
import ConfirmOrderButton from './Components/SalesOrderRecord/Buttons/ConfirmOrderButton';
import OrderShipment from './Components/Shipments/OrderShipment';
import { PART_VIEWER_COLLECTION } from '../../shared/state/partViewState';
import ConfirmMaterialsButton from './Components/SalesOrderRecord/Buttons/ConfirmMaterialsButton';
import { FlexColumn, FlexRow } from '../../shared/containers/FlexContainer';
import ShippingAddressSelector
  from '../Customer/Components/ShippingAddresses/ShippingAddressDetailFields/ShippingAddressSelector';
import OrderShippingAddress from './Components/SalesOrderRecord/ShipAddress/OrderShippingAddress';
import ShipOrderDrawer from './Components/ShipComponents/ShipOrderDrawer';
import OrderItemEditDrawer from './Components/OrderItems/OrderItemEditDrawer';
import OrderJobTypePicker from './Components/SalesOrderRecord/SalesOrderDetail/OrderJobTypePicker';
import { IWeightReductionObject } from '../../shared/types/order';
import ScopedComponent from '../../shared/components/Utility/ScopedComponent';
import CNCLoad from './Components/SalesOrderRecord/SalesOrderDetail/CNCLoad';
import { userSettingsAtom } from '../../shared/state/siteState';
import DetailActiveSelectorWithCallback from '../../shared/components/Input/DetailActiveSelectorWithCallback';

const ConfirmButtonRow = styled(FlexRow)`
  justify-content: flex-start;
  gap: 12px;
`;

const CustomerDetailsRow = styled(FlexColumn)`
  width: 100%;
  margin-bottom: 40px;
  justify-content: flex-start;
  align-items: flex-start;
    
  @media ${theme.device.laptopL} {
      flex-direction: row;
  }
`;
const CustomerOrderDetailsColumn = styled(FlexRow)`
  width: 100%;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 40px;
  gap: 24px;

  @media ${theme.device.laptopL} {
    width: 30%;
    flex-direction: column;
    margin-bottom: unset;
    gap: unset;
  }
`;
const ShipAddressWrapper = styled(FlexRow)`
    align-items: flex-start;
    justify-content: flex-start;
    
    @media ${theme.device.laptopL} {
        flex-direction: column;
    }
`;
const CustomerOrderDetailsRow = styled(FlexRow)`
  width: 100%;
  justify-content: flex-start;
  gap: 24px;
`;

const OrderDetailsRow = styled(FlexRow)`
  width: 100%;
  justify-content: flex-start;
    //align-items: flex-start;
  gap: 12px;
`;
const SalesOrderRecord = () => {
  const { firebase, firestore, database } = useFirebase();
  // @ts-ignore
  const [currentShopOrder, setCurrentShopOrder] = useRecoilState(currentShopOrderAtom);
  const [customerParts, setCustomerParts] = useRecoilState(customerPartsAtom);
  const [orderShipments, setOrderShipments] = useRecoilState(orderShipmentsAtom);
  const [orderItems, setOrderItems] = useRecoilState(orderItemsAtom);
  const setCurrentCustomer = useSetRecoilState(currentCustomerAtom);
  const customers = useRecoilValue(customersAtom);
  const ordersDbCollectionString = useRecoilValue(ORDERS_DB_COLLECTION);
  const userSettings = useRecoilValue(userSettingsAtom);
  const nextOrderNumberPath = useRecoilValue(NEXT_ORDER_NUMBER_PATH);
  const setEdited = useSetRecoilState(salesOrderEditedAtom);
  const superAdminUsers = useRecoilValue(superAdminUserEmailAtom);
  const adminUsers = useRecoilValue(adminUserEmailsAtom);

  const { edit, orderId, returnView } = qs.parse(window.location.search.replace('?', ''));
  const onCustomerChange = (e: any) => {
    const cust = find(customers, (c: ICustomerRecord) => c.DisplayName === e) as ICustomerRecord;
    setCurrentCustomer(cust);
    localStorage.setItem('currentCustomerId', cust.id);
  };
  const updateOrderRecord = async (updateObject: any, blur: boolean = false) => {
    if (blur && orderId) {
      await firestore.collection(ordersDbCollectionString).doc(orderId as string).update(updateObject);
      setCurrentShopOrder({ ...currentShopOrder, ...updateObject });
    } else {
      setCurrentShopOrder({ ...currentShopOrder, ...updateObject });
    }
  };

  const onChangeSalesOrder = (salesOrder: string, blur: boolean = false) => {
    setEdited(true);
    if (blur) {
      if (salesOrder === '') {
        database.ref(nextOrderNumberPath).once('value').then((recordData) => {
          const orderNumber = recordData.val();
          setCurrentShopOrder({ ...currentShopOrder, salesOrder: orderNumber.toString() });
        });
      } else {
        // Remove all whitespace from the sales order
        setCurrentShopOrder({ ...currentShopOrder, salesOrder: salesOrder.replace(/\s/g, '') });
      }
    } else {
      setCurrentShopOrder({ ...currentShopOrder, salesOrder });
    }
  };
  const onChangePO = (purchaseOrder: string, blur: boolean) => {
    setEdited(true);
    updateOrderRecord({ purchaseOrder }, blur);
  };
  const onChangeDescription = (description: string, blur: boolean = false) => {
    setEdited(true);

    updateOrderRecord({ description: description.replace(/-/g, '—') }, blur);
  };
  const onChangeReleaseId = (releaseId: string, blur: boolean) => {
    setEdited(true);
    updateOrderRecord({ releaseId }, blur);
  };
  const onChangeOrderValue = (value: number, blur: boolean) => {
    setEdited(true);
    updateOrderRecord({ orderValue: value }, blur);
  };
  const onChangePartCount = (count: number, blur: boolean) => {
    setEdited(true);
    updateOrderRecord({ partCount: count }, blur);
  };
  const onChangeOrderDate = (date: Date, blur: boolean) => {
    setEdited(true);
    const orderTimestamp = firebase.firestore.Timestamp.fromDate(date);
    updateOrderRecord({ orderDate: orderTimestamp }, blur);
  };
  const onChangeShipDate = (date: Date, blur: boolean) => {
    setEdited(true);
    const shipTimestamp = firebase.firestore.Timestamp.fromDate(date);
    const completedDate = currentShopOrder.completed ? shipTimestamp : null;
    updateOrderRecord({ shipDate: shipTimestamp, completedDate }, blur);
  };

  const onChangeShipAddress = (shipAddress: IShippingAddress, blur: boolean) => {
    updateOrderRecord({ customer: { ...currentShopOrder.customer, ShipAddr: shipAddress } }, blur);
  };
  const onEditWorkOrders = (updatedWorkOrders: IRunner[], blur: boolean) => {
    // Need to update order items here
    const newRunners = updatedWorkOrders.length === 0 ? null : updatedWorkOrders;
    updateOrderRecord({ runners: newRunners }, blur);
  };

  const onChangeWeightReduction = (weightReduction: IWeightReductionObject, blur: boolean) => {
    updateOrderRecord({ weightReduction }, blur);
  };

  const onRefreshOrderItems = (refreshedItems: IOrderItem[]) => {
    const refreshedWorkOrders = currentShopOrder.runners.map((r: IRunner) => {
      const refreshedParts = r.parts.map((p: IOrderItem) => {
        const refreshedItem = find(refreshedItems, (i: IOrderItem) => i.Sku === p.Sku);
        if (!refreshedItem) return p;
        return {
          ...p,
          Description: refreshedItem.Description,
          unitPrice: refreshedItem.unitPrice,
          volume: refreshedItem.volume,
        };
      });
      return {
        ...r,
        parts: refreshedParts,
        value: Math.round(refreshedParts.map((p: IOrderItem) => p.quantityAssigned * p.unitPrice).reduce((a, b) => a + b, 0)),
      };
    });
    updateOrderRecord({ runners: refreshedWorkOrders });
  };

  const onReorderItems = (reorderedItems: IOrderItem[]) => {
    const runners = currentShopOrder.runners.map((r) => {
      const parts: IOrderItem[] = [];
      reorderedItems.forEach((i: IOrderItem, index) => {
        const runnerPart = find(r.parts, (p: IOrderItem) => p.id === i.id);
        if (runnerPart) parts[index] = runnerPart;
      });
      return {
        ...r,
        parts: parts.filter((p: IOrderItem) => p), // finally filter the list to remove any null indices
      };
    });
    updateOrderRecord({ runners });
  };
  const onOrderItemSave = async (orderItem: IOrderItem) => {
    if (!currentShopOrder.runners) return;

    const newWorkOrders = currentShopOrder.runners.map((r: IRunner) => {
      const parts = r.parts.map((p: IOrderItem) => {
        if (p.id !== orderItem.id) return p;
        return {
          ...p,
          houseSample: orderItem?.houseSample || false,
          // @ts-ignore
          unitPrice: orderItem.price || orderItem.unitPrice,
          description: orderItem.Description,
          notes: orderItem.notes,
        };
      });
      return ({ ...r, parts });
    });
    await updateOrderRecord({ runners: newWorkOrders });
  };

  const onChangeOutsideShip = (e: boolean) => {
    setCurrentShopOrder({ ...currentShopOrder, outsideFinishRequired: e });
  };

  const onResetAllocation = async () => {
    const updatedWorkOrders = (currentShopOrder.runners || []).map((r: IRunner) => ({
      ...r,
      parts: r.parts.map((p: IOrderItem) => ({
        ...p,
        quantityAssigned: 0,
      })),
    }));
    await updateOrderRecord({ runners: updatedWorkOrders });
    return null;
  };

  useEffect(() => {
    devLog('salesOrderRecord', 278, 'load sales order');
    if (!orderId) { // CREATE MODE - no orderId
      database.ref(nextOrderNumberPath).once('value').then((recordData) => {
        const orderNumber = recordData.val();
        onChangeSalesOrder(orderNumber.toString());
      });
    }
  }, []);

  return (
    <>
      <ShipOrderDrawer />
      <OrderItemEditDrawer
        onItemSaveCallback={onOrderItemSave}
        orderId={orderId as string}
        orderComplete={currentShopOrder.completed}
        // updateWorkOrdersCallback={onUpdateWorkOrders}
        // workOrders={currentShopOrder.runners}
      />
      <WorkOrderPDFDrawer orderId={orderId as string} />
      <InvoicePDFDrawer shipment={orderShipments[0]} />
      <PackSlipPDFDrawer shipment={orderShipments[0]} />
      <OrderConfirmationPDFDrawer />
      <ConfigurationCol>
        <RunnerHeaderRow>
          <RunnerPageTitle>{`${edit ? 'Edit' : 'Add'} Sales Order`}</RunnerPageTitle>
          <ActionRow>
            <SaveDocumentButton edit={!!edit} returnView={returnView as string} currentOrder={currentShopOrder} />
            <PrintDocumentButton orderId={orderId as string} />
            { orderId && !currentShopOrder?.completed
          && (
          <>
            <OrderHoldStatus />
            <ScopedComponent whitelist={[...superAdminUsers.emails, ...adminUsers.emails]}>
              <ConfirmButtonRow>
                <ConfirmOrderButton />
                <ConfirmMaterialsButton />
              </ConfirmButtonRow>
              <DeleteOrderButton returnView={returnView as string} />
            </ScopedComponent>
          </>
          )}
          </ActionRow>
        </RunnerHeaderRow>
        <Divider key={shortid.generate()} orientation="left">Order Details</Divider>
        <AddRunnerColumn>
          <CustomerDetailsRow>
            <CustomerOrderDetailsColumn>
              <OrderCustomerSearchSelect customerId={currentShopOrder?.customer?.id || ''} changeCallback={onCustomerChange} />
              {!!currentShopOrder?.customer?.id && (
                <ShipAddressWrapper>
                  <ShippingAddressSelector label="Shipping Address" callback={onChangeShipAddress} showEdit />
                  <OrderShippingAddress />
                </ShipAddressWrapper>
              )}
            </CustomerOrderDetailsColumn>
            <FieldWrapper>
              <CustomerOrderDetailsRow>
                <DetailInputWithCallback key="sales-order" label="Sales Order" placeholder="e.g., 1234" value={currentShopOrder.salesOrder} callback={onChangeSalesOrder} />
                <DetailInputWithCallback key="purchase-order" label="PO Number" placeholder="e.g., em 1/1/24" value={currentShopOrder.purchaseOrder} callback={onChangePO} />
                {/* <DetailInputWithCallback key="release-id" label="Release ID" placeholder="e.g., B3" value={currentShopOrder.releaseId} callback={onChangeReleaseId} /> */}
                <DetailDateWithCallback disableDates={false} value={currentShopOrder.orderDate.toDate()} key="sales-order-order-date" label="Order Date" callback={onChangeOrderDate} disabled={false} />
                <DetailDateWithCallback value={currentShopOrder.shipDate.toDate()} key="sales-order-ship-date" label="Ship Date" callback={onChangeShipDate} disabled={currentShopOrder.completed} />
                <ScopedComponent whitelist={['keithh@wildwoodmfg.com']}>
                  <DetailActiveSelectorWithCallback initialState={currentShopOrder.outsideFinishRequired || false} callback={onChangeOutsideShip} disabled={false} componentLabel="Outside finishing?" checkedLabel="Yes" uncheckedLabel="No" componentMargin={4} />
                </ScopedComponent>
              </CustomerOrderDetailsRow>
              <OrderDetailsRow>
                <DetailInputWithCallback width="500px" key="order-description" label="Description" placeholder="e.g., 4 IRW Tele necks" value={currentShopOrder.description} callback={onChangeDescription} />
                { (edit || currentShopOrder.salesOrder === 'PH') && (
                  <>
                    <OrderValue shipped={currentShopOrder.completed} orderValue={currentShopOrder.orderValue} placeholder={currentShopOrder.salesOrder === 'PH'} callback={onChangeOrderValue} />
                    <PartCount />
                  </>
                )}
              </OrderDetailsRow>
              {(currentShopOrder.salesOrder === 'PH' || !orderItems.filter((i) => i).length) && (
                <OrderJobTypePicker />
              )}
              {(orderId && !!orderItems.filter((i) => i).length) && (
              <FieldRow align="flex-start">
                <OrderJobType />
                <OrderToppedOrBound />
                <OrderFinishingRequired />
                <OrderHouseSample />
                {(orderType(orderItems) === 0 && toppedOrBound(orderItems, customerParts)) && (
                <OrderWeightReduction callback={onChangeWeightReduction} />
                )}
                {(userSettings?.boolean?.showCNCLoad && orderType(orderItems) === 1) && (
                  <CNCLoad orderItems={orderItems} />
                )}
              </FieldRow>
              )}
            </FieldWrapper>
          </CustomerDetailsRow>

          {orderId && (
          <>
            {/*
            ORDER ITEMS ----------------------------------------------------------------------------------------------->
          */}
            <OrderItemList
              key="sales-order-order-items-list"
              customerId={currentShopOrder.customer.id}
              orderCompleted={currentShopOrder.completed}
              orderId={currentShopOrder.id}
              currentOrderType={currentShopOrder.type}
              salesOrderNumber={currentShopOrder.salesOrder}
              refreshCallback={onRefreshOrderItems}
              reorderCallback={onReorderItems}
              resetAllocationCallback={onResetAllocation}
              // onOrderItemSave={onOrderItemSave}
              // onUpdateWorkOrders={onUpdateWorkOrders}
            />
            {/*
            WORK ORDERS ----------------------------------------------------------------------------------------------->
          */}
            {(currentShopOrder.releaseConfirmed && currentShopOrder.materialsConfirmed && !currentShopOrder.completed) && (
            <>
              <Divider key={shortid.generate()} orientation="left">Work orders</Divider>
              <WorkOrderWrapper>
                <WorkOrderList
                  key={`work-order-list-${currentShopOrder.id}`}
                  editWorkOrderCallback={onEditWorkOrders}
                  orderItems={orderItems}
                  orderId={currentShopOrder.id}
                  releaseConfirmed={currentShopOrder.releaseConfirmed}
                  workOrders={currentShopOrder?.runners || []}
                />
              </WorkOrderWrapper>
            </>
            )}
            {!!orderShipments.length && (
            <OrderShipmentsWrapper>
              <Divider key={shortid.generate()} orientation="left">Shipments</Divider>
              {orderShipments.map((shipment: IShipment) => (<OrderShipment shipment={shipment} orderId={currentShopOrder.id} />))}
            </OrderShipmentsWrapper>
            )}

          </>
          )}
        </AddRunnerColumn>
      </ConfigurationCol>
    </>
  );
};

export default SalesOrderRecord;
