import { atom } from 'recoil';
import { first, includes, last } from 'lodash';
import {
  IRouterStep, IStation,
} from 'pages/ProductionSchedule/types';
import {
  monthDates,
  NC_RECORD_END_KEY,
  NC_RECORD_START_KEY, ORDERS_VIEW_END_KEY, ORDERS_VIEW_START_KEY,
  SALES_ORDER_END_KEY,
  SALES_ORDER_START_KEY, SHIPMENTS_VIEW_END_KEY, SHIPMENTS_VIEW_START_KEY,
  workWeeksForMonth,
} from '../data/calendar';
import { IRunner, IShopOrder, IWorkOrder } from '../../pages/Orders/types';
import { IOrderItem } from '../types/dbRecords';

export const salesOrderEpochAtom = atom<number>({
  key: 'salesOrderEpochAtom',
  default: new Date().getTime(),
});

export const salesOrderStartDateAtom = atom<Date>({
  key: 'salesOrderStartDateAtom',
  default: new Date(2021, 0, 1),
});

export const salesOrderEndDateAtom = atom<Date>({
  key: 'salesOrderEndDateAtom',
  default: new Date(2021, 11, 31),
});

export const salesOrdersAtom = atom<IShopOrder[]>({
  key: 'salesOrdersAtom',
  default: [],
});

export const salesOrderItemsAtom = atom<IOrderItem[]>({
  key: 'salesOrderItemsAtom',
  default: [],
});
export const salesOrderCopyItemAtom = atom<any>({
  key: 'salesOrderCopyItemAtom',
  default: {},
});

export const salesOrderPasteEnabledAtom = atom<boolean>({
  key: 'salesOrderPasteEnabledAtom',
  default: false,
});

export const showAddSalesOverlayAtom = atom<boolean>({
  key: 'addSalesOverlay',
  default: false,
});

export const currentSalesOrderAtom = atom<string>({
  key: 'currentSalesOrderAtom',
  default: '',
});

export const godModeAtom = atom<boolean>({
  key: 'godModeAtom',
  default: false,
});

export const stationUserIdAtom = atom<IStation[]>({
  key: 'stationUserIdAtom',
  default: [

  ],
});
export const routerStepsAtom = atom<IRouterStep[]>({
  key: 'routerStepsAtom',
  default: [],
});

export const queueItemsAtom = atom<number>({
  key: 'queueItemsAtom',
  default: 0,
});

export const productionItemsAtom = atom<number>({
  key: 'productionItemsAtom',
  default: 0,
});

export const routerMachineTypeAtom = atom<'All'|'ANNI'|'ANDI'>({
  key: 'routerMachineType',
  default: 'All',
});

export const inProcessValueAtom = atom<number>({
  key: 'inProcessValueAtom',
  default: 0,
});

export const currentRunnerAtom = atom<IRunner>({
  key: 'currentRunnerAtom',
  default: {
    id: '',
    history: [],
    notes: '',
    runnerNumber: 0,
    partCount: 0,
    partCounts: [],
    toppedOrBound: false,
    step: '',
    completed: false,
    stepNotes: {},
    machine: 'any',
    parts: [],
  },
});

export const currentRunnerOpenAtom = atom<string[]>({
  key: 'currentRunnerOpenAtom',
  default: ['0'],
});

export const splitShopOrderAtom = atom<boolean>({
  key: 'splitShopOrderAtom',
  default: false,
});

export const runnerWorkOrdersAtom = atom<IWorkOrder[]>({
  key: 'runnerWorkOrdersAtom',
  default: [],
});

export const salesOrderEditedAtom = atom<boolean>({
  key: 'salesOrderEditedAtom',
  default: false,
});

export const searchModalAtom = atom<boolean>({
  key: 'searchModalAtom',
  default: false,
});

export const initiateScanAtom = atom<boolean>({
  key: 'initiateScanAtom',
  default: false,
});

export const messageAcknolwedgeAtom = atom<boolean>({
  key: 'messageAcknolwedgeAtom',
  default: false,
});

let storedSalesOrderStartDate = localStorage.getItem(SALES_ORDER_START_KEY);
if (window.location.pathname.match(/nc/i)) storedSalesOrderStartDate = localStorage.getItem(NC_RECORD_START_KEY);
if (window.location.pathname.match(/qa/i)) storedSalesOrderStartDate = localStorage.getItem(NC_RECORD_START_KEY);
if (window.location.pathname.match(/orders/i)) storedSalesOrderStartDate = localStorage.getItem(ORDERS_VIEW_START_KEY);
if (window.location.pathname.match(/shipments/i)) storedSalesOrderStartDate = localStorage.getItem(SHIPMENTS_VIEW_START_KEY);

const firstOfStartMonth = storedSalesOrderStartDate ? new Date(parseInt(storedSalesOrderStartDate, 10)) : new Date();
// if we are in the first week of the month
if (!storedSalesOrderStartDate && firstOfStartMonth.getDate() <= 7 && firstOfStartMonth.getMonth() > 0) {
  const lastMonthWeek = last(
    workWeeksForMonth(
      monthDates()[firstOfStartMonth.getMonth() - 1],
    ),
  );

  // firstOfStartMonth.setDate(firstOfStartMonth.getDay() - (firstOfStartMonth.getDay() - 1));
  // firstOfStartMonth.setDate(-6);
  firstOfStartMonth.setDate(first(lastMonthWeek).getDate());
  firstOfStartMonth.setMonth(first(lastMonthWeek).getMonth());
}

// export const weekLengthAtom = atom<String>({
//   key: 'weekLengthAtom',
//   default: localStorage.getItem('weekLength') || '4',
// });

export const scheduleViewStartDateAtom = atom<Date>({
  key: 'startDateAtom',
  default: firstOfStartMonth,
});

export const orderViewStartDateAtom = atom<Date>({
  key: 'orderViewStartDateAtom',
  default: firstOfStartMonth,
});

export const shipmentsViewStartDateAtom = atom<Date>({
  key: 'shipmentsViewStartDateAtom',
  default: firstOfStartMonth,
});

export const nonConformanceStartDateAtom = atom<Date>({
  key: 'nonConformanceViewStartDateAtom',
  default: firstOfStartMonth,
});

let storedSalesOrderEndDate = localStorage.getItem(SALES_ORDER_END_KEY);
if (window.location.pathname.match(/nc/i)) storedSalesOrderEndDate = localStorage.getItem(NC_RECORD_END_KEY);
if (window.location.pathname.match(/qa/i)) storedSalesOrderEndDate = localStorage.getItem(NC_RECORD_END_KEY);
if (window.location.pathname.match(/orders/i)) storedSalesOrderEndDate = localStorage.getItem(ORDERS_VIEW_END_KEY);
if (window.location.pathname.match(/shipments/i)) storedSalesOrderEndDate = localStorage.getItem(SHIPMENTS_VIEW_END_KEY);

const lastOfEndMonth = storedSalesOrderEndDate ? new Date(parseInt(storedSalesOrderEndDate, 10)) : new Date();
if (!storedSalesOrderEndDate) {
  lastOfEndMonth.setMonth(lastOfEndMonth.getMonth() + 3);
  lastOfEndMonth.setDate(1);
  lastOfEndMonth.setDate(lastOfEndMonth.getDate() - 1);
}
export const scheduleViewEndDateAtom = atom<Date>({
  key: 'endDateAtom',
  default: lastOfEndMonth,
});

export const orderViewEndDateAtom = atom<Date>({
  key: 'orderViewEndDateAtom',
  default: lastOfEndMonth,
});

export const shipmentsViewEndDateAtom = atom<Date>({
  key: 'shipmentsViewEndDateAtom',
  default: lastOfEndMonth,
});

export const nonConformanceViewEndDateAtom = atom<Date>({
  key: 'nonConformanceViewEndDateAtom',
  default: lastOfEndMonth,
});

export const orderWeeksAtom = atom<Date[][][]>({
  key: 'orderWeeksAtom',
  default: [],
});

export const showBodiesAtom = atom<boolean>({
  key: 'showBodiesAtom',
  default: includes(['all', 'body'], localStorage.getItem('partViewType')),
});
export const showNecksAtom = atom<boolean>({
  key: 'showNecksAtom',
  default: includes(['all', 'neck'], localStorage.getItem('partViewType')),
});
export const showLossesAtom = atom<boolean>({
  key: 'showLossesAtom',
  default: localStorage.getItem('ncShowLosses') === 'true',
});
export const showNCAtom = atom<boolean>({
  key: 'showNCAtom',
  default: localStorage.getItem('ncShowNC') === 'true',
});

export const timeMachineAtom = atom<Date>({
  key: 'timeMachineAtom',
  default: new Date(),
});

export const showSODownloadAtom = atom<boolean>({
  key: 'showSODownloadAtom',
  default: false,
});

export const customerFilterAtom = atom<string>({
  key: 'customerFilterAtom',
  default: localStorage.getItem('customerFilter') || '',
});

export const customerUserEmailsAtom = atom({
  key: 'customerUserEmails',
  default: {
    defaultPage: '/part-finder',
    emails: [
      'ageno@wildwoodmfg.com',
      'eggle@wildwoodmfg.com',
      'sadowsky@wildwoodmfg.com',
      'luxxtone@wildwoodmfg.com',
      'lullcustom@wildwoodmfg.com',
      'kline@wildwoodmfg.com',
      'nybw@wildwoodmfg.com',
      'friedman@wildwoodmfg.com',
      'rnrr@wildwoodmfg.com',
      'bloomdoom@wildwoodmfg.com',
      'mjt@wildwoodmfg.com',
      'shelton@wildwoodmfg.com',
      'jones@wildwoodmfg.com',
    ],
  },
});

export const officeUserEmailAtom = atom({
  key: 'officeUserEmail',
  default: {
    defaultPage: '/',
    emails: [
      'bruceh@wildwoodmfg.com',
      'cathyh@wildwoodmfg.com',
      'christineh@wildwoodmfg.com',
      'darrans@wildwoodmfg.com',
      'keithh@wildwoodmfg.com',
    ],
  },
});
export const superAdminUserEmailAtom = atom({
  key: 'superAdminUserEmail',
  default: {
    defaultPage: '/',
    emails: [
      'keithh@wildwoodmfg.com',
    ],
  },
});

export const adminUserEmailsAtom = atom({
  key: 'adminUserEmails',
  default: {
    defaultPage: '/',
    emails: [
      'bruceh@wildwoodmfg.com',
      'cathyh@wildwoodmfg.com',
      'darrans@wildwoodmfg.com',
      'christineh@wildwoodmfg.com',
      'edwardl@wildwoodmfg.com',
    ],
  },
});

export const supervisorEmailsAtom = atom({
  key: 'supervisorEmailsAtom',
  default: {
    defaultPage: '/',
    emails: [
      'stevej@wildwoodmfg.com',
      'blaker@wildwoodmfg.com',
      'brianr@wildwoodmfg.com',
    ],
  },
});
export const shopUserEmailsAtom = atom({
  key: 'shopUserEmails',
  default: {
    defaultPage: '/',
    emails: [
      'nicholas@wildwoodmfg.com',
    ],
  },
});

export const scannerUserEmailsAtom = atom({
  key: 'scannerUserEmails',
  default: {
    defaultPage: '/scanner',
    emails: [
      'station-assembly@wildwoodmfg.com',
      'station-cnc@wildwoodmfg.com',
      'station-construction-jason@wildwoodmfg.com',
      'station-construction@wildwoodmfg.com',
      'station-dryroom@wildwoodmfg.com',
      'station-finishing@wildwoodmfg.com',
      'station-qa-shipping@wildwoodmfg.com',
      'station-release@wildwoodmfg.com',
    ],
  },
});

export const currentUserEmailAtom = atom<string|null>({
  key: 'currentUserEmailAtom',
  default: null,
});
