import React, { useContext, useEffect, useState } from 'react';
import qs from 'qs';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import styled, { keyframes } from 'styled-components';
import firebase from 'firebase';
import { Button, Modal } from 'antd';
import theme from 'shared/theme';
import {
  first, omit, uniq,
} from 'lodash';
import {
  bodyTotal, formatPrice, neckTotal, sanitizePricingRecord,
} from 'shared/data';
import bodyParser from 'shared/partParser/bodyParser';
import neckParser from 'shared/partParser/neckParser';
import {
  PART_BOM_COLLECTION,
  PART_PRICING_COLLECTION,
  PART_VIEWER_COLLECTION,
  partBomItemsAtom,
} from 'shared/state/partViewState';
import QBOItem from 'shared/data/QBO/item';
import { SaveSpinner } from 'shared/styledComponents/utility';
import { currentCustomerAtom } from 'shared/state/customerState';
import { devLog } from 'shared/util/logging';
import { FlexRow } from 'shared/containers/FlexContainer';
import { IQBOItem } from 'shared/types/qbo';
import useFirebase from 'vendor/Firebase';
import { AuthContext } from 'vendor/Firebase/AuthProvider';
import { isDevSite, useTestData } from 'shared/util';
import {
  documentSavingProgressAtom,
  partEditModeAtom,
  customerPartAtom,
} from 'shared/state/pricingState';
import { updateRevisionNeeded } from 'shared/data/pricing';
import { ICustomerPart } from 'shared/types/parts';
import { ORDERS_DB_COLLECTION, ORDER_ITEMS_DB_COLLECTION } from 'shared/state/orderState';
import { resolveActive } from 'shared/partParser/helpers';
import { resolveArchetype } from 'shared/partParser/partResolver';

/**
 * Button component that is responsible for saving records, as well as copying and
 * creating new records from the item pricing level.
 */
const rotation = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;
const SaveButton = styled(Button)`
  margin-left: ${theme.spacing(3)};
  background-color: ${(props: any) => (props.testMode ? theme.palette.error.D500 : theme.palette.success[900])} !important;
  border-color: ${(props: any) => (props.testMode ? theme.palette.error.D500 : theme.palette.neutral.white)};
  border-radius: ${theme.spacing(1)};
  border: none;
  color: ${theme.palette.neutral.white};

  &:hover {
    color: ${theme.palette.neutral.white};
    background-color: ${(props: any) => (props.testMode ? theme.palette.error.hue : theme.palette.success.D100)} !important;
    border-color: transparent;
  }
`;
const SpinnerWrapper = styled(FlexRow)`
    justify-content: flex-start;
    gap: 8px;
`;
const SaveText = styled.p`
    margin: 0;
    color: ${theme.palette.neutral.white};
`;

interface IComponent {
  partType: 'body'|'neck';
  price: number;
}
export default ({ partType, price }: IComponent) => {
  const { currentUser } = useContext(AuthContext);
  const { firestore } = useFirebase();
  const currentCustomer = useRecoilValue(currentCustomerAtom);
  const partCollectionDbString = useRecoilValue(PART_VIEWER_COLLECTION);
  const bomCollectionDbString = useRecoilValue(PART_BOM_COLLECTION);
  const partPricingCollectionDbString = useRecoilValue(PART_PRICING_COLLECTION);
  const ordersDbString = useRecoilValue(ORDERS_DB_COLLECTION);
  const orderItemsDbString = useRecoilValue(ORDER_ITEMS_DB_COLLECTION);
  const bomItems = useRecoilValue(partBomItemsAtom);
  const setPartEditMode = useSetRecoilState(partEditModeAtom);
  // @ts-ignore
  // const [_partState, setPartState] = useState<any>(partState);
  const _partState = useRecoilValue<ICustomerPart>(customerPartAtom);
  const [isSaving, setIsSaving] = useRecoilState(documentSavingProgressAtom);
  const [saveText, setSaveText] = useState('Saving to QBO...');
  const totalFunction = partType.match(/body/i) ? bodyTotal : neckTotal;
  const discount = partType.match(/body/i) ? currentCustomer.bodyDiscount : currentCustomer.neckDiscount;

  const saveBomItems = async (): Promise<void> => new Promise((resolve, reject) => {
    firestore.collection(bomCollectionDbString).doc(_partState.Sku).get().then((doc) => {
      const data = doc.data();
      firestore.collection(bomCollectionDbString).doc(_partState.Sku).set({ ...data, bom: bomItems }).then(() => {
        if (!useTestData && !isDevSite()) {
          firestore.collection(`${bomCollectionDbString}-test`).doc(_partState.Sku).set({ ...data, bom: bomItems }).then(() => {
            resolve();
          });
        } else {
          resolve();
        }
      })
        .catch((saveError) => reject(saveError));
    })
      .catch((fetchError) => reject(fetchError));
  });

  const savePricing = async (pricingData: any): Promise<void> => new Promise((resolve, reject) => {
    firestore.collection(partPricingCollectionDbString).doc(_partState.Sku).set(pricingData).then(() => {
      resolve();
    })
      .catch((saveError) => reject(saveError));
  });

  // partId and copyData will serve to let the save to JB function know if we are updating a part or if one is being created.
  // if either partId or the copy data exist, it's an update and the JB function can bypass trying to save to JB.
  const { partId, paste } = qs.parse(window.location.search.replace('?', ''));

  const onSave = async (e: any) => {
    devLog('SaveRecordButton', 108, 'Saving record...');
    setIsSaving(true);
    setPartEditMode(false);
    // const price = totalFunction(_partState);
    // const adjustedPrice = Math.round((price.price * (1 + ((discount) / 100))) + price.discount);

    // Validation: part cannot be saved unless passing these checks
    if (_partState.customerId === '0') {
      Modal.error({
        title: 'No Customer!',
        content: 'Be sure to select a customer from the dropdown menu.',
        onOk: () => {
          setIsSaving(false);
        },
      });
      return;
    }
    if (_partState.Sku === '' && _partState.id === '') {
      Modal.error({
        title: 'No Part Number!',
        content: 'Please make sure to add the JB part number in the part number field.',
        onOk: () => {
          document.getElementById('part-number-input')?.focus();
          setIsSaving(false);
        },
      });
      return;
    }
    if (_partState.Description === '') {
      Modal.error({
        title: 'No Part Description!',
        content: 'Please make sure to add a part description in the part description field.',
        onOk: () => {
          document.getElementById('part-name-input')?.focus();
          setIsSaving(false);
        },
      });
      return;
    }
    if (!_partState.Description.match(/[G|B][B|N]|CP/)) {
      Modal.error({
        title: 'Part Type Required!',
        content: 'Every part must have a part type designation - GN, BN, GB, BB or CP.',
        onOk: () => {
          document.getElementById('part-name-input')?.focus();
          setIsSaving(false);
        },
      });
      return;
    }
    // END VALIDATION

    const pricingRecord = sanitizePricingRecord(_partState);

    const now = new Date();

    const writeData: any = {
      ...omit(_partState, [...Object.keys(pricingRecord), 'partName', 'description', 'Description']),
      active: resolveActive(_partState.active),
      parent: _partState.parent?.Sku || null,
      childParts: _partState.childParts || null,
      lastSold: _partState.lastSold || null,
      notes: _partState.notes || null,
      price,
      pricing: pricingRecord,
      type: resolveArchetype(_partState.config).text,
      lastModified: firebase.firestore.Timestamp.fromDate(now),
      lastModifiedBy: currentUser.email,
      revisionNeeded: _partState.revisionNeeded || false,
      showNotesOnOrder: _partState.showNotesOnOrder || false,
    };

    const pricingData = pricingRecord;

    // if the part is just being created, log the create timestamp/user
    if (!partId) {
      writeData.createdDate = firebase.firestore.Timestamp.fromDate(now);
      writeData.createdBy = currentUser.email;
    }

    // if the part is a body, assign its volume to the write data
    if (partType === 'body') {
      writeData.volume = _partState.volume;
    }

    setSaveText('Saving BOM...');
    // save the bom items to the JBK database
    await saveBomItems();

    setSaveText('Saving pricing...');
    await savePricing(pricingData);

    const updatedRecord = {
      ...writeData,
      qboId: '',
      // SyncToken: qboRes?.SyncToken || quickbooksItem?.SyncToken || '',
    };

    if (!useTestData && !isDevSite()) {
      // Next, save the item to Quickbooks Online
      setSaveText('Saving to QBO...');
      // const tokenData = await QBOAuth.refreshToken(database);
      // devLog('SaveRecordButton', 188, `QBO token data refreshed: ${tokenData}`);
      const quickbooksItem = await QBOItem.fromFinishedGood(writeData);
      const qboRes = quickbooksItem.Id ? await QBOItem.update(quickbooksItem) as IQBOItem : await QBOItem.create(quickbooksItem) as IQBOItem;
      devLog('SaveRecordButton', 193, 'Saved to QBO, updating part with QBO Id');
      devLog('SaveRecordButton', 194, `Saving part ${writeData.partNumber} to: ${partCollectionDbString}`);
      updatedRecord.qboId = qboRes?.Id || quickbooksItem.Id || '';
      updatedRecord.SyncToken = qboRes?.SyncToken || quickbooksItem.SyncToken || '';

      if (!qboRes) {
        Modal.confirm({
          title: 'Error saving to QBO',
          content: 'It looks like there was an error saving to Quickbooks Online. Click "Continue" to continue saving to HELM, or "Try again" to retry the save to Quickbooks. If the issue persists, let Keith know.',
          okText: 'Try again',
          cancelText: 'Continue',
          onOk: () => {
            onSave({});
          },
          // onCancel: () => {
          //   setIsSaving(false);
          //   setPartEditMode(false);
          // },
        });
      }
    }

    // If there is a parent part, update its childParts list with this Sku
    if (updatedRecord.parent) {
      setSaveText('Updating parent part...');
      const parentDoc = await firestore.collection(partCollectionDbString).doc(updatedRecord.parent).get();
      const parentData = parentDoc.data() as any;
      const updatedChildren = uniq([...(parentData.childParts || []), updatedRecord.Sku]);
      await firestore.collection(partCollectionDbString).doc(parentData.Sku).update({ childParts: updatedChildren });
    }

    // Finally, save the part to the HELM database
    setSaveText('Saving part to HELM...');
    await Promise.all([
      // save the part without pricing to the part collection
      firestore.collection(partCollectionDbString).doc(writeData.Sku).set(omit(updatedRecord, ['pricing'])),
      // save the pricing to the pricing collection
      firestore.collection(partPricingCollectionDbString).doc(writeData.Sku).set(updatedRecord.pricing)]);
    if (!useTestData && !isDevSite()) {
      await Promise.all([
        // save the part without pricing to the part collection
        firestore.collection(`${partCollectionDbString}-test`).doc(writeData.Sku).set(omit(updatedRecord, ['pricing'])),
        // save the pricing to the pricing collection
        firestore.collection(`${partPricingCollectionDbString}-test`).doc(writeData.Sku).set(updatedRecord.pricing)]);
    }
    devLog('SaveRecordButton', 198, 'Now in "then" clause');
    localStorage.removeItem('pricing.temp.part');

    setSaveText('');
    setIsSaving(false);
    if (window.location.href.match(/copy/)) {
      localStorage.removeItem('pricing.temp.part');
      const newHref = window.location.href.replace(/customer=[A-Z]{5}&copy=true(&paste=true)?/, `partId=${_partState.Sku}`);
      sessionStorage.removeItem('currentPricingItem');
      window.location.href = newHref;
    }

    setIsSaving(false);
    setPartEditMode(false);
  };

  // useEffect(() => {
  //   setPartState(partState);
  // }, [partState]);

  return (
    <>
      {isSaving ? (
        <SpinnerWrapper>
          <SaveSpinner />
          <SaveText>{saveText}</SaveText>
        </SpinnerWrapper>
      ) : (
        <SaveButton type="primary" onClick={onSave} test={useTestData} partState={_partState}>Save</SaveButton>
      )}
    </>
  );
};
