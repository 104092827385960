import React, { useEffect, useState } from 'react';
import { Collapse, Input, Button, Form, message, Modal, Select } from 'antd';
import 'antd/dist/antd.css';
import { useRecoilValue } from 'recoil';
import useFirebase from 'vendor/Firebase';
import { PART_CONFIG_TERMS_COLLECTION } from 'shared/state/partViewState';
import ItemListFilter from 'shared/components/Input/ItemListFilter';
import shortid from 'shortid';
import { isDevSite, useTestData } from 'shared/util';
import PartTerm from './Components/PartTerms/PartTerm';

const { Panel } = Collapse;

interface Term {
  id: string;
  term: string;
  type: string;
  english: string;
  coercion: string;
}

const TermsConfigPage: React.FC = () => {
  const { firestore } = useFirebase();
  const [termsData, setTermsData] = useState<{ [key: string]: Term[] }>({});
  const [filteredTermsData, setFilteredTermsData] = useState<{ [key: string]: Term[] }>({});
  const [termCategories, setTermCategories] = useState<string[]>([]);
  const partConfigTermsCollection = useRecoilValue(PART_CONFIG_TERMS_COLLECTION);
  const [termToDelete, setTermToDelete] = useState<Term | null>(null);
  const [configTermsSearchQuery, setConfigTermsSearchQuery] = useState<string>(sessionStorage.getItem('configTerms.searchQuery') || '');
  const [form] = Form.useForm();

  const onListFilterChange = (value: string) => {
    setConfigTermsSearchQuery(value);
    sessionStorage.setItem('configTerms.searchQuery', value);
    
    if (!value.trim()) {
      setFilteredTermsData(termsData);
      return;
    }

    const searchTerms = value.split(',').map((term) => term.trim().toLowerCase());
    const filtered: { [key: string]: Term[] } = {};

    Object.entries(termsData).forEach(([category, terms]) => {
      const matchedTerms = terms.filter((term) => 
        searchTerms.some((searchTerm) => 
          term.term.toLowerCase().includes(searchTerm)
          || term.english?.toLowerCase().includes(searchTerm)));

      if (matchedTerms.length > 0) {
        filtered[category] = matchedTerms;
      }
    });

    setFilteredTermsData(filtered);
  };

  const applyFilter = (data: { [key: string]: Term[] }, searchValue: string) => {
    if (!searchValue.trim()) {
      return data;
    }

    const searchTerms = searchValue.split(',').map((term) => term.trim().toLowerCase());
    const filtered: { [key: string]: Term[] } = {};

    Object.entries(data).forEach(([category, terms]) => {
      const matchedTerms = terms.filter((term) => 
        searchTerms.some((searchTerm) => term.term.toLowerCase().includes(searchTerm) || term.english?.toLowerCase().includes(searchTerm)));

      if (matchedTerms.length > 0) {
        filtered[category] = matchedTerms;
      }
    });

    return filtered;
  };

  const fetchTerms = async () => {
    const snapshot = await firestore.collection(partConfigTermsCollection).get();
    const data: { [key: string]: Term[] } = {};

    snapshot.forEach((doc) => {
      const termData = { ...doc.data(), id: doc.id } as Term;
      const category = termData.type;
      if (!data[category]) {
        data[category] = [];
      }
      data[category].push(termData);
    });

    // Sort terms alphabetically within each category
    Object.keys(data).forEach((category) => {
      data[category].sort((a, b) => a.term.localeCompare(b.term));
    });

    setTermsData(data);
    setFilteredTermsData(applyFilter(data, configTermsSearchQuery));
    setTermCategories(Object.keys(data).sort());
  };
  useEffect(() => {
    fetchTerms();
  }, []);

  const onFinish = async (values: any, existingTerm?: Term) => {
    try {
      const termId = existingTerm?.id || shortid.generate();
      const data = {
        term: values.term,
        type: existingTerm?.type || values.type,
        english: values.english || '',
        coercion: values.coercion || '',
      };
      await firestore.collection(partConfigTermsCollection).doc(termId).set(data);
      if (!useTestData && !isDevSite()) {
        await firestore.collection('part-config-terms-test').doc(termId).set(data);
      }
      message.success(existingTerm ? 'Term updated successfully' : 'Term added successfully');
      form.resetFields();
      fetchTerms();
    } catch (error) {
      message.error(existingTerm ? 'Failed to update term' : 'Failed to add term');
    }
  };

  const handleDelete = async (term: Term) => {
    try {
      await firestore.collection(partConfigTermsCollection).doc(term.id).delete();
      if (!useTestData && !isDevSite()) {
        await firestore.collection('part-config-terms-test').doc(term.id).delete();
      }
      message.success('Term deleted successfully');
      setTermToDelete(null);
      fetchTerms();
    } catch (error) {
      message.error('Failed to delete term');
    }
  };

  const onEditTerm = () => {
    fetchTerms();
  };

  return (
    <div style={{ padding: '120px' }}>
      <h1>Terms Configuration</h1>
      <ItemListFilter
        id="part-terms-filter"
        callback={onListFilterChange}
        currentValue={configTermsSearchQuery}
        prompt="Filter parts using comma-separated terms, e.g., IRW, 55090"
      /> 
      <Form
        form={form}
        layout="inline"
        onFinish={onFinish}
        style={{ 
          marginBottom: '20px',
          padding: '20px',
          background: '#f5f5f5',
          borderRadius: '4px',
          width: '100%',
        }}
      >
        <Form.Item
          name="type"
          label="Term Type"
          rules={[{ required: true, message: 'Type is required' }]}
          style={{ width: '20%' }}
        >
          <Select placeholder="Select term type">
            {termCategories.filter(Boolean).map((category) => (
              <Select.Option key={category} value={category}>
                {category}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="term"
          label="New Term"
          rules={[{ required: true, message: 'Term is required' }]}
        >
          <Input placeholder="Enter new term" />
        </Form.Item>
        <Form.Item 
          name="english" 
          label="English Definition"
          style={{ width: '25%' }}
        >
          <Input placeholder="Enter definition" style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item 
          name="coercion" 
          label="Coerce to"
          style={{ width: '25%' }}
        >
          <Input placeholder="Enter coercions" style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Add Term
          </Button>
        </Form.Item>
      </Form>

      <Collapse accordion>
        {Object.keys(filteredTermsData).sort().map((category) => (
          <Panel header={category || 'No Category'} key={category}>
            {filteredTermsData[category].map((term) => (
              <PartTerm key={term.id} term={term} callback={onEditTerm} termCategories={termCategories} />
            ))}
          </Panel>
        ))}
      </Collapse>
    </div>
  );
};

export default TermsConfigPage;
