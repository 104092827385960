import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ItemDetailsLabel, ItemDetailsColumnWrapper } from 'shared/styledComponents/inputs';
import { Switch } from 'antd';
import { FlexRow } from '../../containers/FlexContainer';
import Loader from '../Utility/Loader';

const ActiveSwitch = styled(Switch)`
  margin: 4px 0 6px 0;
`;
const ComponentWrapper = styled(ItemDetailsColumnWrapper)`
    margin: ${(props: { margin: string}) => props.margin};
    flex-direction: ${(props: { labelMode: 'inline' | 'block' }) => (props.labelMode === 'inline' ? 'row' : 'column')};
    gap: 4px;
`;

const LabelWrapper = styled(FlexRow)`
    justify-content: flex-start;
    gap: 4px;
`;

const ToggleWrapper = styled(LabelWrapper)`
    gap: 12px;
`;
interface IComponent {
  id: string;
  initialState: boolean;
  callback: (state: boolean) => void;
  disabled: boolean;
  componentLabel: string|null;
  checkedLabel: string;
  uncheckedLabel: string;
  componentMargin?: string;
  LabelActiveClass?: string;
  LabelExtra?: any
  async?: boolean;
  labelMode?: 'inline' | 'block';
}

const DetailActiveSelectorWithCallback = ({
  id, callback, disabled, initialState, componentLabel = '', checkedLabel = 'ACTIVE', labelMode = 'block', uncheckedLabel = 'INACTIVE', componentMargin = '0 0 0 auto', LabelExtra = <div />, LabelActiveClass = '', async = false,
}: IComponent) => {
  const [active, setActive] = useState<boolean>(initialState);
  const [_async, setAsync] = useState<boolean>(async);

  const onChange = (e: any) => {
    setActive(e);
    callback(e);
  };

  useEffect(() => {
    setActive(initialState);
  }, [initialState]);

  useEffect(() => {
    setAsync(async);
  }, [async]);

  return (
    <ComponentWrapper key="part-active-item-details-wrapper" labelMode={labelMode} margin={componentMargin}>
      <LabelWrapper>
        <ItemDetailsLabel key={`${id}-item-details-wrapper`} className={active ? LabelActiveClass : ''}>{componentLabel}</ItemDetailsLabel>
        {LabelExtra}
      </LabelWrapper>
      <ToggleWrapper>
        <ActiveSwitch
          disabled={disabled}
          checked={active}
          checkedChildren={checkedLabel}
          unCheckedChildren={uncheckedLabel}
          onChange={onChange}
        />
        {_async && (
          <Loader label="" radius={24} />
        )}
      </ToggleWrapper>
    </ComponentWrapper>
  );
};

export default DetailActiveSelectorWithCallback;
