import React, { useEffect, useState } from 'react';
import { Button, Drawer, Tooltip } from 'antd';
import firebase from 'firebase';
import styled from 'styled-components';
import shortid from 'shortid';
import {
  Calculate, AttachMoney, Edit, DragHandle,
} from '@styled-icons/material';
import { FlexColumn, FlexRow } from 'shared/containers/FlexContainer';
import theme from 'shared/theme';
import { formatPrice } from 'shared/data';
import { IBomItem, IOrderItem } from 'shared/types/dbRecords';
import {
  currentOrderItemAtom,
  currentShopOrderAtom,
  showAllOrderItemsAtom,
  showOrderItemEditDrawerAtom,
} from 'shared/state/orderState';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import UniversalDensityCalculatorByPart from '../../../Util/DensityCalculator/UniversalDensityCalculatorByPart';
import { stageRedirect } from '../../../../shared/util';
import { bomEditedAtom } from '../../../../shared/state/partViewState';
import { adminUserEmailsAtom, superAdminUserEmailAtom } from '../../../../shared/state/routingState';
import ScopedComponent from '../../../../shared/components/Utility/ScopedComponent';
import { cleanMeta } from '../../../../shared/text';

export interface IComponent {
  id: string;
  orderItem: IOrderItem
  showItem: boolean;
}

const CalculateIcon = styled(Calculate)`
  width: 24px;
  margin-bottom: 3px;
`;
const MoneyIcon = styled(AttachMoney)`
  width: 24px;
  margin-bottom: 4px;
`;
const EditIcon = styled(Edit)`
  width: 20px;
  margin-bottom: 4px;
`;

const CalcDrawer = styled(Drawer)`
  top: 0px;
`;

const OrderItemActionButton = styled(Button)`
  border: 1px solid #EEEEEE;
`;
const OrderItemEditButton = styled(OrderItemActionButton)`
  background-color: ${(props: any) => (props.confirmed === 'true' ? theme.palette.success.hue : theme.palette.primary.hue)} !important;
  &:hover {
    background-color: ${(props: any) => (props.confirmed === 'true' ? theme.palette.success.D100 : theme.palette.primary.D100)} !important;
  }
`;
const OrderItemActionRow = styled(FlexRow)`
  justify-content: space-between;
  margin: 4px 12px;
  gap: 8px;
`;

const ComponentWrapper = styled.li`
  width: 100%;
  display: ${(props: any) => (props.showOrderItem ? 'flex' : 'none')};
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 4px;
  padding-bottom: 4px;
  background-color: ${theme.palette.neutral.white};
  border-bottom: 1px solid ${theme.palette.neutral[500]};
  gap: 16px;

  & p {
    text-wrap: wrap;
  }

`;
const PartDetailsWrapper = styled(FlexColumn)`
   width: 150px;
   justify-content: flex-start;
   align-items: flex-start;
   margin-bottom: 8px;
 `;
const ClosedStatus = styled.div`
  width: 100%;
  border-radius: 8px;
  padding: 0px 6px;
  background-color: ${theme.palette.neutral[200]};
  color: ${theme.palette.neutral.black};
  font-size: 12px;
  letter-spacing: 3px;
  text-align: center;
`;

const MakeStatus = styled(ClosedStatus)`
  background-color: ${theme.palette.primary[200]};
  letter-spacing: unset;
  margin-bottom: 2px;
`;
const AssignedStatus = styled(ClosedStatus)`
  background-color: ${theme.palette.primary.D200};
  color: white;
  letter-spacing: unset;
`;
const ShippedStatus = styled(MakeStatus)`
  background-color: ${theme.palette.success[200]};
`;
const RevisionStatus = styled(MakeStatus)`
  background-color: red;
  color: ${theme.palette.neutral.white};
  font-weight: bold;
`;
const CanceledStatus = styled(MakeStatus)`
  background-color: ${theme.palette.error[200]};
`;

const PartNumberWrapper = styled(FlexRow)`
  width: 100%;
  justify-content: flex-start;
  gap: 8px;
`;

const PartNumber = styled.p`
  font-weight: bold;
  margin-bottom: 4px;
`;
const PartQuantity = styled.p`
  margin-bottom: 4px;
  letter-spacing: -1.25px
`;

const PartDescriptionWrapper = styled(FlexColumn)`
  width: 100%;
  align-items: flex-start;
  justify-content: center;
  gap: 4px;
`;

const PartPriceDataWrapper = styled(FlexColumn)`
    justify-content: flex-start;
    align-items: flex-end;
    &:nth-of-type(2) {
        align-items: flex-start;
    }
`;
const PartPriceLabel = styled.p`
    margin: 0;
    font-weight: ${(props: { bold: boolean }) => (props.bold ? 'bold' : 'normal')};
    color: ${theme.palette.neutral[400]};
    text-transform: uppercase;
`;

const PartPriceWrapper = styled(FlexRow)`
  gap: 8px;
  justify-content: flex-end;
`;
const PartPrice = styled.p`
    font-weight: ${(props: { bold: boolean }) => (props.bold ? 'bold' : 'normal')};
    margin: 0;
`;

const PartDescription = styled.p`
  margin: 0;
`;

const PartNotesWrapper = styled(PartDescriptionWrapper)`
  gap: 0;
`;

const PartJobNotes = styled.p`
  font-weight: 200;
  font-size: 14px;
  margin-left: 8px;
  margin-bottom: 0;
`;

const DragHandleItem = styled.a`
  width: 24px;
  height: 24px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-color: transparent;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 2px 4px -1px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
`;

const DragHandleIcon = styled(DragHandle)`
  width: 20px;
  margin-bottom: 2px;
  color: grey;
`;

const OrderItem = ({
  id,
  orderItem,
  showItem,
}: IComponent) => {
  const elementId = shortid.generate();
  const type = orderItem.Description.match(/[G|B]N/) ? 'neck' : 'body';
  const [showDensityCalculator, setShowDensityCalculator] = useState(false);
  const [showOrderItemEditDrawer, setShowOrderItemEditDrawer] = useRecoilState(showOrderItemEditDrawerAtom);
  const setBomEdited = useSetRecoilState(bomEditedAtom);
  const setCurrentOrderItem = useSetRecoilState(currentOrderItemAtom);
  const superAdminUsers = useRecoilValue(superAdminUserEmailAtom);
  const adminUsers = useRecoilValue(adminUserEmailsAtom);
  const bomCost = orderItem.bom ? orderItem.bom.map((i: IBomItem) => i.unitCost * i.quantity)
    .reduce((a, b) => a + b, 0) : 0;

  const openCalc = (e: any) => {
    setShowDensityCalculator(true);
  };

  const closeCalc = (e: any) => {
    setShowDensityCalculator(false);
  };
  const redirectToPricing = (e: any) => {
    stageRedirect(window.location.href);
    window.location.href = `/pricing/${type}?partId=${orderItem.Sku}&redirect=true`;
  };
  const onEditOrderPart = (e: any) => {
    setCurrentOrderItem(id);
    setBomEdited(false);
    setShowOrderItemEditDrawer(true);
  };

  return (
    <>
      <CalcDrawer
        key={`${id}-density-calc-drawer`}
        placement="right"
        width="calc(100% - 92px)"
        closable
        open={showDensityCalculator}
        title="Mini Density Calculator"
        onClose={closeCalc}
      >
        <UniversalDensityCalculatorByPart part={orderItem.Sku} />
      </CalcDrawer>
      <ComponentWrapper
        key={`${id}-order-item-component`}
        // @ts-ignore -- long live my custom props
        showOrderItem={showItem || orderItem.open}
      >
        <DragHandleItem key={`${id}-order-item-drag-handle`} href="#"><DragHandleIcon /></DragHandleItem>
        <PartDetailsWrapper key={`${id}-order-item-detail-wrapper`}>
          <PartNumberWrapper key={`${id}-order-item-number-wrapper`}>
            <PartNumber key={`${id}-order-item-part-number`}>{orderItem.Sku}</PartNumber>
          </PartNumberWrapper>
          {/* {(!open) ? ( */}
          {/*  <ClosedStatus key={`${id}-order-item-closed-status`}>CLOSED</ClosedStatus> */}
          {/* ) : ( */}
          {/*  <> */}
          {!!orderItem.revisionNeeded && (
          <RevisionStatus key={`${id}-order-item-revision-status`}>REV NEEDED</RevisionStatus>
          )}
          {!!orderItem.quantityOpen && (
          <MakeStatus key={`${id}-order-item-open-status`}>{`${orderItem.quantityOpen} OPEN`}</MakeStatus>
          )}
          {orderItem.quantityShipped > 0 && (
          <ShippedStatus key={`${id}-order-item-shipped-status`}>{`${orderItem.quantityShipped} SHIPPED`}</ShippedStatus>
          )}
          {orderItem.quantityCanceled > 0 && (
          <CanceledStatus key={`${id}-order-item-canceled-status`}>{`${orderItem.quantityCanceled} CANCELED / LOST`}</CanceledStatus>
          )}
          {/* </> */}
          {/* )} */}
        </PartDetailsWrapper>
        <PartDescriptionWrapper key={`${id}-order-item-description-wrapper`}>
          <PartDescription key={`${id}-order-item-description`}>{cleanMeta(orderItem.Description)}</PartDescription>
          {!!orderItem.notes && (
            <PartNotesWrapper key={`${id}-order-item-notes`}>
              { orderItem.notes.split('\n').map((note: string) => <PartJobNotes>{note}</PartJobNotes>)}
            </PartNotesWrapper>
          )}
        </PartDescriptionWrapper>
        <PartPriceWrapper>
          <PartPriceDataWrapper>
            <PartPrice bold key={`${id}-order-item-unit-price`}>{formatPrice(orderItem.unitPrice)}</PartPrice>
            {!!orderItem.Sku.match(/^[A-Z]{5}/) && (
            <PartPrice key={`${id}-order-item-bom-cost`}>{formatPrice(bomCost)}</PartPrice>
            )}
          </PartPriceDataWrapper>
          <PartPriceDataWrapper>
            <PartPriceLabel bold>Sell</PartPriceLabel>
            {!!orderItem.Sku.match(/^[A-Z]{5}/) && (
            <PartPriceLabel>BOM</PartPriceLabel>
            )}
          </PartPriceDataWrapper>
        </PartPriceWrapper>
        <OrderItemActionRow>
          {(type === 'body' && orderItem.Sku.match(/^[A-Z]/)) && (
          <Tooltip title="Check part density" placement="top">
            <OrderItemActionButton
              key={`${id}-order-item-open-density-calculator`}
              shape="circle"
              type="ghost"
              icon={<CalculateIcon />}
              onClick={openCalc}
            />
          </Tooltip>
          )}
          {(type === 'body' && orderItem.Sku.match(/^99/)) && (
            <OrderItemActionButton
              disabled
              style={{ opacity: 0, width: 0 }}
            />
          )}
          <Tooltip title="View/edit part" placement="top">
            <OrderItemActionButton
              key={`${id}-order-item-pricing-redirect`}
              shape="circle"
              type="ghost"
              icon={<MoneyIcon />}
              onClick={redirectToPricing}
            />
          </Tooltip>
          <ScopedComponent whitelist={[...superAdminUsers.emails, ...adminUsers.emails]}>
            <Tooltip title="View/edit order item" placement="top">
              <OrderItemEditButton
                key={`${id}-order-item-edit-part`}
                shape="circle"
                type="primary"
                confirmed={(!!orderItem.materialsConfirmed || false).toString()}
                icon={<EditIcon />}
                onClick={onEditOrderPart}
              />
            </Tooltip>
          </ScopedComponent>
        </OrderItemActionRow>
      </ComponentWrapper>
    </>
  );
};

export default OrderItem;
