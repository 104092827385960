import React, { useEffect, useState } from 'react';
import _, {
  every,
  find, findIndex, omit, sortBy,
} from 'lodash';
import shortid from 'shortid';
import styled from 'styled-components';
import {
  Button, Divider, Drawer, Modal, Space,
} from 'antd';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  ORDER_ITEMS_DB_COLLECTION,
  currentShipOrderAtom,
  showShipOrderDrawerAtom,
  ORDER_SHIPMENT_DB_COLLECTION,
  ORDERS_DB_COLLECTION, currentShopOrderAtom, orderItemsAtom, orderShipmentsAtom,
} from 'shared/state/orderState';
import { FlexColumn, FlexRow } from 'shared/containers/FlexContainer';
import { ItemDetailsDescription } from 'shared/styledComponents/inputs';
import { PartDetailColumnHeader } from 'shared/styledComponents/typographicElements';
import {
  IBomItem,
  IOrderItem, IShipment, IShipmentItem,
} from 'shared/types/dbRecords';
import { firebaseShipDate, lesserDate } from 'shared/data/calendar';
import { routerStepsAtom } from 'shared/state/routingState';
import { runnerHistory } from 'shared/scanner';
import { nullOp, redirect } from 'shared/util';
import useFirebase from 'vendor/Firebase';
import { IRouterStep } from 'pages/ProductionSchedule/types';
import QBOAuth from 'shared/data/QBO/auth';
import DetailInputWithCallback from 'shared/components/Input/DetailInputWithCallback';
import DetailNumberInputWithCallback from 'shared/components/Input/DetailNumberInputWithCallback';
import QBOInvoice from 'shared/data/QBO/invoice';
import DetailDateWithCallback from 'shared/components/Input/DetailDateWIthCallback';
import firebase from 'firebase';
import OrderShipItem from './OrderShipItem';
import OrderShipItemHeader from './OrderShipItemHeader'; import PackingSlipNotes from './PackingSlipNotes';
import { IRunner } from '../../types';

import AddShipItemButton from './Buttons/AddShipItemButton';

const ShipDrawer = styled(Drawer)`
  top: 0px;
  padding-top: 40px;
  margin-left: 72px;
`;

const ComponentDetailWrapper = styled(FlexColumn)`
  width: 80%;
  align-items: flex-start;
`;
const DetailsRow = styled(FlexRow)`
  width: 100%;
  justify-content: flex-start;
  gap: 20px;
`;

const AddShippingItemsRow = styled(DetailsRow)`
  margin-bottom: 16px;
`;
const ItemDetailHeader = styled(PartDetailColumnHeader)`
  width: 100%;
`;

const ActionButton = styled(Button)`
  border-radius: 8px;
`;

const ShipOrderDrawer = () => {
  const routerSteps = useRecoilValue(routerStepsAtom);
  const [showShipDrawer, setShowShipDrawer] = useRecoilState(showShipOrderDrawerAtom);
  const ordersDbCollectionString = useRecoilValue(ORDERS_DB_COLLECTION);
  const orderItemsDbCollectionString = useRecoilValue(ORDER_ITEMS_DB_COLLECTION);
  const orderShipmentDbCollectionString = useRecoilValue(ORDER_SHIPMENT_DB_COLLECTION);
  const currentShopOrder = useRecoilValue(currentShopOrderAtom);
  const setCurrentShopOrder = useSetRecoilState(currentShopOrderAtom);
  const _orderItems = useRecoilValue(orderItemsAtom);
  const [orderItems, setOrderItems] = useState<IOrderItem[]>([]);
  const [currentShipOrder, setCurrentShipOrder] = useRecoilState(currentShipOrderAtom);
  const setOrderShipment = useSetRecoilState(orderShipmentsAtom);
  const [description, setDescription] = useState<string>(currentShopOrder.description);
  const [shippingCost, setShippingCost] = useState<number>(0);
  // @ts-ignore
  const [recordNumber, setRecordNumber] = useState<string>(null);
  const [trackingNumber, setTrackingNumber] = useState<string>('');
  const { firestore, database } = useFirebase();

  const completeShipping = async (shippedItems: IOrderItem[], workOrders: IRunner[]) => {
    // save shipping record - order-shipping
    const recordId = shortid.generate();
    const shipmentValue = shippedItems.map((i: IOrderItem) => i.quantityShipped * i.unitPrice).reduce((a, b) => a + b, 0);
    const itemsWithoutBom = shippedItems.filter((i: IOrderItem) => i.quantityShipped > 0)
      .map((i: IOrderItem) => omit({ ...i, open: false, quantityOpen: i.quantityOpen - i.quantityShipped }, 'bom'));
    const _shipDate = lesserDate(currentShipOrder.shipDate.toDate(), new Date());

    const shipment: IShipment = {
      id: recordId,
      customer: currentShipOrder.customer,
      description: description || '',
      trackingNumber: trackingNumber || '',
      purchaseOrder: currentShopOrder.purchaseOrder || '',
      notes: currentShipOrder.notes || '',
      orderId: currentShipOrder.id,
      salesOrder: currentShipOrder.salesOrder,
      shipDate: firebase.firestore.Timestamp.fromDate(_shipDate),
      shipmentNumber: recordNumber,
      shippedItems: itemsWithoutBom as IShipmentItem[],
      shippingCost,
      value: shipmentValue,
    };

    /*
      create the invoice in Quickbooks
     */
    // await QBOAuth.refreshToken(database);
    const qboInvoiceId = await QBOInvoice.create(shipment);

    /*
      increment the number for the next invoice
     */
    const nextInvoiceNumber = await database.ref('/recordNumbers/invoice').once('value');
    // if for some reason we are creating an out-of-sync invoice (e.g., credit memo), we don't want to increment the next invoice number.
    // to that end, only increment if the value fetched from the database is equivalent to the current shipment number.
    if (nextInvoiceNumber.val().toString() === shipment.shipmentNumber) await database.ref('/recordNumbers/invoice').set(parseInt(nextInvoiceNumber.val(), 10) + 1);

    /*
      create the shipment in our database
     */
    await firestore.collection(orderShipmentDbCollectionString).doc(recordId).set({ ...shipment, qboId: qboInvoiceId });
    setOrderShipment([shipment]);

    // update the order items record with the new bom "total quantity" which is the quantity
    // of any BOM item needed per part times the number of parts still open
    const newOrderItems = shippedItems.map((o: IOrderItem) => ({
      ...o,
      bom: o.bom.map((i: IBomItem) => ({
        ...i,
        totalQuantity: o.quantityOpen * i.quantity,
      })),
    }));

    await firestore.collection(orderItemsDbCollectionString).doc(currentShopOrder.id).set({ orderItems: newOrderItems });

    /*
      close out order
     */
    const completeStep = find(routerSteps, (rs: IRouterStep) => rs.type === currentShopOrder.type && rs.name.match(/complete/i)) as IRouterStep;
    const shipDate = firebase.firestore.Timestamp.fromDate(new Date());
    const updatedRunners = workOrders.map((r: IRunner) => {
      if (r.completed) {
        return {
          ...r,
          history: runnerHistory(r, completeStep.id),
          step: completeStep.id,
        };
      }
      return r;
    });

    const orderCompleted = every(updatedRunners, (r: IRunner) => r.completed);
    const updates = {
      description,
      completed: orderCompleted,
      completedDate: orderCompleted ? shipDate : null,
      shipDate,
      runners: updatedRunners,
    };

    if (orderCompleted) {
      // @ts-ignore
      updates.partCount = shippedItems.filter((i: IOrderItem) => i.unitPrice > 0 && i.Sku.match(/^[A-Z]{5}/))
        .map((i: IOrderItem) => i.quantityShipped).reduce((a, b) => a + b, 0);
      // @ts-ignore
      updates.orderValue = shippedItems.map((i: IOrderItem) => i.quantityShipped * i.unitPrice).reduce((a, b) => a + b, 0);
    }

    firestore.collection(ordersDbCollectionString).doc(currentShopOrder.id).update(updates).then(() => {
      setCurrentShopOrder({ ...currentShopOrder, ...updates });
      Modal.confirm({
        title: 'Print documents?',
        content: 'Would you like to print any shipping documents at this time?',
        okText: 'Print',
        cancelText: 'No thanks',
        onCancel: () => { redirect(); },
        onOk: () => {
          // setShowShipDrawer(false);
          window.location.reload();
        },
      });
    });
  };
  const processBackorder = (shippedItems: IOrderItem[]) => {
    const openItems = shippedItems.filter((i: IOrderItem) => i.quantityOpen > 0).map((i: IOrderItem) => ({ ...i, quantityAssigned: 0 }));
    const partCount = openItems.map((i: IOrderItem) => i.quantityOpen).reduce((a, b) => a + b, 0);
    const orderValue = openItems.map((i: IOrderItem) => i.quantityOpen * i.unitPrice).reduce((a, b) => a + b, 0);
    const newId = shortid.generate();
    const newShipDate = firebaseShipDate();
    // @ts-ignore
    const newSalesOrder = {
      ...currentShopOrder,
      salesOrder: `${currentShopOrder.salesOrder.split('-')[0]}-BO`,
      partCount,
      orderValue,
      description: `${currentShopOrder.description.replace(/\s?[BO\\.]{2,4}\s?/, '')} - B.O.`,
      dateCreated: newShipDate,
      nonConformanceHistory: null,
      releaseConfirmed: null,
      releaseConfirmedBy: null,
      runners: null,
      shipDate: newShipDate,
      shipDateHistory: [],
      id: newId,
      recordId: newId,
      weightReduction: null,
    };

    firestore.collection(ordersDbCollectionString).doc(newId).set(newSalesOrder).then(() => {
      firestore.collection(orderItemsDbCollectionString).doc(newId).set({ orderItems: openItems }).then(() => {
        Modal.info({
          title: 'Backorder created successfully',
          content: 'Click OK to finish shipping this order.',
          onOk: () => {
            completeShipping(shippedItems, currentShopOrder.runners.map((r: IRunner) => {
              /*
              The remaining parts on this order that weren't shipped are being backordered.
              As such, we will mark all parts on any work orders on this order as having zero open instances,
              and we will mark the backordered quantities as "canceled" since they're effectively on a different order.
               */
              const parts = r.parts.map((p: IOrderItem) => {
                const shippedPart = find(shippedItems, (i: IShipmentItem) => i.Sku === p.Sku);
                if (!shippedPart) return { ...p, quantityOpen: 0 };
                return {
                  ...p,
                  quantityOpen: 0,
                  quantityShipped: shippedPart.quantityShipped,
                  quantityCanceled: shippedPart.quantityOrdered - shippedPart.quantityShipped,
                };
              });
              return {
                ...r, completed: true, open: false, parts,
              };
            }));
          },
        });
      });
    });
  };
  const onSave = (e: any) => {
    // we are going to get a list of the order items that have shipped
    // this is a copy of the orderItems list, but each item is updated with the remaining open parts
    const shippedItems = orderItems.map((o: IOrderItem) => ({
      ...o,
      quantityOpen: o.quantityOrdered - (o.quantityShipped + o.quantityCanceled),
    }));

    // the work orders are a copy of the sales order's work orders (runners), but we will find the parts on each work
    // order in the "shippedItems" list and update the parts on each work order with the quantity shipped.
    const workOrders = currentShopOrder.runners.map((r: IRunner) => {
      const parts = r.parts.map((p: IOrderItem) => {
        const shippedPart = find(shippedItems, (i: IOrderItem) => i.Sku === p.Sku);
        // if we can't find the part in the shipped parts array, just return the part
        if (!shippedPart) return p;
        /* return the part with new values for
           quantityShipped: the quantity logged on the shipped part
           quantityOpen: the bigger number between part's open quantity and the open quantity less the sum of the shipped and canceled parts
              if the quantity open on the work order is smaller than the quantity shipped + canceled,
              it's safe to assume that all open parts on the work order have been exhausted.
         */
        const remainingOpen = Math.max(0, p.quantityAssigned - ((shippedPart.quantityShipped || 0) + (shippedPart.quantityCanceled || 0)));
        return { ...p, quantityShipped: shippedPart.quantityShipped, quantityOpen: remainingOpen };
      });

      // a work order can be considered complete if the open quantity on the work order is zero
      const workOrderComplete = every(parts, (p: IOrderItem) => p.quantityOpen === 0);
      return {
        ...r, parts, completed: workOrderComplete, open: !workOrderComplete,
      };
    });

    // if a part has quantities that are still open, we will need a backorder (or to leave the order open)
    const needsBackorder = !!shippedItems.filter((i: IOrderItem) => i.open && i.quantityOpen > 0).length;

    if (needsBackorder) {
      Modal.confirm({
        title: 'Create backorder from unshipped parts?',
        content: 'Click OK to create a new backorder from remaining parts on this order, click "Leave open" to keep the remaining work orders active.',
        cancelText: 'Cancel',
        onCancel: () => { },
        onOk: () => { processBackorder(shippedItems); },
      });
    } else {
      completeShipping(shippedItems, workOrders);
    }
  };

  const onClose = (e: any) => {
    setShowShipDrawer(false);
  };
  const onCancel = (e: any) => {
    setShowShipDrawer(false);
  };
  const onShipChange = (id: string, value: number) => {
    const orderItem = find(orderItems, (o: IOrderItem) => o.id === id) as IOrderItem;
    const index = findIndex(orderItems, (o: IOrderItem) => o.id === id);
    setOrderItems([...orderItems.slice(0, index), { ...orderItem, quantityShipped: value }, ...orderItems.slice(index + 1)]);
  };
  const onCancelChange = (id: string, value: number) => {
    const orderItem = find(orderItems, (o: IOrderItem) => o.id === id) as IOrderItem;
    const index = findIndex(orderItems, (o: IOrderItem) => o.id === id);
    setOrderItems([...orderItems.slice(0, index), { ...orderItem, quantityCanceled: value }, ...orderItems.slice(index + 1)]);
  };
  const onItemNotesChange = (id: string, value: string) => {
    const orderItem = find(orderItems, (o: IOrderItem) => o.id === id) as IOrderItem;
    const index = findIndex(orderItems, (o: IOrderItem) => o.id === id);
    setOrderItems([...orderItems.slice(0, index), { ...orderItem, notes: value }, ...orderItems.slice(index + 1)]);
  };
  const onPackingSlipNotesChange = (notes: string) => {
    setCurrentShipOrder({ ...currentShipOrder, packingSlipNotes: notes });
  };
  const onShipCostChange = (shipCost: string) => {
    setShippingCost(parseFloat(shipCost ?? 0));
  };

  const onDescriptionChange = (_description: string) => {
    setDescription(_description);
  };

  const onShipDateChange = (shipDate: Date) => {
    setCurrentShipOrder({ ...currentShipOrder, shipDate });
  };

  const onTrackingNumberChange = (_trackingNumber: string) => {
    setTrackingNumber(_trackingNumber);
  };

  const onShipmentNumberChange = (shipmentNumber: string) => {
    setRecordNumber(shipmentNumber);
  };

  useEffect(() => {
    const controller = new AbortController();
    if (showShipDrawer) {
      database.ref('/recordNumbers/invoice').once('value').then((val) => {
        setRecordNumber(val.val().toString());
        const customerParts = sortBy(_orderItems.filter((o: IOrderItem) => o.Sku.match(/[A-Z]{5}_[0-9]{5}/)), (i: IOrderItem) => i.Sku);
        const nonCustomerParts = sortBy(_orderItems.filter((o: IOrderItem) => !o.Sku.match(/[A-Z]{5}_[0-9]{5}/)), (i: IOrderItem) => i.Sku);
        setOrderItems([...customerParts, ...nonCustomerParts].map((o: IOrderItem) => ({
          ...o,
          quantityShipped: o.quantityOpen,
        })));
        setCurrentShipOrder({ ...currentShipOrder, packingSlipNotes: currentShipOrder.description });
      });
    }
    return () => {
      controller.abort();
    };
  }, [currentShipOrder.id]);

  return (
    <>
      <ShipDrawer
        title={`Ship Order: ${currentShipOrder.salesOrder}`}
        width="calc(100% - 72px)"
        height="100vh"
        placement="top"
        open={showShipDrawer}
        onClose={onClose}
        closable={false}
        extra={(
          <Space>
            <ActionButton onClick={onCancel}>Cancel</ActionButton>
            <ActionButton type="primary" onClick={onSave}>
              Create Shipment
            </ActionButton>
          </Space>
      )}
      >
        { (currentShipOrder && recordNumber) && (
        <ComponentDetailWrapper>
          <ItemDetailHeader key="part-details-column-header" marginTop="0px">Shipping Details</ItemDetailHeader>
          <Divider orientation="left">Order Details</Divider>
          <DetailsRow>
            <DetailInputWithCallback
              id="shipment-number"
              label="Ship Number"
              placeholder=""
              value={recordNumber}
              callback={onShipmentNumberChange}
              extend={false}
              isLabel={false}
            />
            <DetailInputWithCallback
              id="order-number"
              label="Order Number"
              placeholder=""
              value={currentShipOrder.salesOrder}
              callback={nullOp}
              disabled
              extend={false}
              isLabel={false}
            />
            <DetailInputWithCallback
              id="purchase-order-number"
              label="PO Number"
              placeholder=""
              value={currentShipOrder.purchaseOrder}
              callback={nullOp}
              disabled
              extend={false}
              isLabel={false}
            />
            <DetailInputWithCallback
              id="order-description"
              label="Description"
              placeholder=""
              value={currentShipOrder.description}
              callback={onDescriptionChange}
              extend={false}
              isLabel={false}
            />
          </DetailsRow>
          <Divider orientation="left">Shipment Details</Divider>
          <DetailsRow>
            <DetailDateWithCallback value={new Date()} key="ship-order-ship-date" label="Ship Date" callback={onShipDateChange} disabled={false} />
            <DetailInputWithCallback
              id="order-tracking-number"
              label="Tracking Number"
              placeholder="e.g., 1Z43F7Y4..."
              value={currentShipOrder.trackingNumber}
              callback={onTrackingNumberChange}
              extend={false}
              isLabel={false}
            />
            <DetailNumberInputWithCallback key="shipment-cost" label="Shipping Cost" placeholder="" value={shippingCost} callback={onShipCostChange} />
          </DetailsRow>
          <PackingSlipNotes packingSlipNotes={currentShipOrder.packingSlipNotes?.length > 0 ? currentShipOrder.packingSlipNotes : currentShipOrder.description} callback={onPackingSlipNotesChange} />
          <>
            <Divider orientation="left">Shipping Items</Divider>
            <AddShippingItemsRow>
              <AddShipItemButton />
              <ItemDetailsDescription key="ship-items-detail-description">Use the fields below to indicate how many units of each part are being shipped.</ItemDetailsDescription>
            </AddShippingItemsRow>
            {!!orderItems.length && (
              <>
                <OrderShipItemHeader />
                {orderItems.filter((o: IOrderItem) => o.quantityOpen > 0).map((o: IOrderItem) => (
                  <OrderShipItem key={`ship-item-${o.id}`} orderItem={o} shipCallback={onShipChange} cancelCallback={onCancelChange} notesCallback={onItemNotesChange} />
                ))}
              </>
            )}
          </>
        </ComponentDetailWrapper>
        )}
        {/* </ComponentWrapper> */}
      </ShipDrawer>
    </>
  );
};

export default ShipOrderDrawer;
