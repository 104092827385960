import React, { useState } from 'react';
import styled from 'styled-components';
import { Input } from 'antd';
import { ItemDetailsColumnWrapper, ItemDetailsLabel } from 'shared/styledComponents/inputs';

const OrderShipItemNotesInput = styled.textarea`
  min-width: 500px;
  height: 32px;
  line-height: 16px;
  margin-left: 8px;
  border-color: rgba(0, 0, 0, 0.1);
`;

interface IComponent {
  orderShipItemNotes: string;
  callback: (notes: string) => void;
}
const OrderShipItemNotes = ({ orderShipItemNotes, callback }: IComponent) => {
  const [notes, setNotes] = useState<string>(orderShipItemNotes);
  const onChange = (e: any) => {
    setNotes(e.target.value);
    if (callback) callback(e.target.value);
  };
  return (
    <OrderShipItemNotesInput
      key="part-Notes-input"
      id="part-Notes-input"
      placeholder="Part Notes"
      value={orderShipItemNotes}
      onChange={onChange}
    />
  );
};

export default OrderShipItemNotes;
