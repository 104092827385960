import React, { useState, useEffect, useContext } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { findIndex, noop, omit } from 'lodash';
import { LeftOutlined } from '@ant-design/icons';
import { Button, Divider, Modal } from 'antd';
import styled from 'styled-components';
import firebase from 'firebase';
import qs from 'qs';
import {
  IInventoryPart, IShipment, IShipmentItem,
} from 'shared/types/dbRecords';
import { FlexColumn, FlexRow } from 'shared/containers/FlexContainer';
import {
  currentShopOrderAtom,
  ORDER_ITEMS_DB_COLLECTION,
  ORDERS_DB_COLLECTION,
  workOrderUpdateLabelAtom,
} from 'shared/state/orderState';
import { PartDetailColumnHeader } from 'shared/styledComponents/typographicElements';
import DetailInputWithCallback from 'shared/components/Input/DetailInputWithCallback';
import { nullOp, redirect, stageRedirect, useTestData } from 'shared/util';
import DetailDateWithCallback from 'shared/components/Input/DetailDateWIthCallback';
import DetailNumberInputWithCallback from 'shared/components/Input/DetailNumberInputWithCallback';
import { SHIPMENTS_DB_COLLECTION } from 'shared/state/shipmentState';
import DetailTextAreaWithCallback from 'shared/components/Input/DetailTextAreaWithCallback';
import { PART_VIEWER_COLLECTION } from 'shared/state/partViewState';
import { INVENTORY_ITEMS_COLLECTION } from 'shared/state/inventoryState';
import { SaveSpinner, SaveText, SpinnerWrapper } from 'shared/styledComponents/utility';
import { rgba } from 'shared/palette/utils';
import theme from 'shared/theme';
import useFirebase from 'vendor/Firebase';
import { IShipOrder, IShopOrder } from '../Orders/types';
import { NewItemHeader } from '../PartDetail/Components/styledComponents';
import {
  createOrUpdateQboInvoice,
  processBackorder,
  updateHelmShipment, updateOrderItems, updateSalesOrder, updatePartLastSold,
} from './Components/ShipmentDetail/Util/shipmentFunctions';
import ShipmentItem from './Components/ShipmentDetail/ShipmentItem';
import AddShipmentItemButton from './Components/ShipmentDetail/Buttons/AddShipmentItemButton';
import PrintDocumentButton from './Components/ShipmentDetail/Buttons/PrintDocumentButton';
import InvoicePDFDrawer from '../PDF/Display/InvoicePDFDrawer';
import PackSlipPDFDrawer from '../PDF/Display/PackSlipPDFDrawer';
import { ItemDetailBackButton } from '../../shared/styledComponents/inputs';
import OrderJumpButton from './Components/ShipmentDetail/Buttons/OrderJumpButton';
import BackorderConfirmationModal from './Components/ShipmentDetail/BackorderConfirmationModal';
import { MESSAGE_DB_PATH_ATOM, reorderPointNotificationUsersAtom } from '../../shared/state/messageState';
import { AuthContext } from '../../vendor/Firebase/AuthProvider';

const ComponentWrapper = styled(FlexColumn)`
  width: calc(100vw - 200px);
  margin-left: 100px;
  padding-top: 16px;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 12px;
`;

const ActionRow = styled(NewItemHeader)`
  width: 100%;
  margin-left: 0;
  justify-content: flex-start;
  gap: 12px;
`;
const ActionButton = styled(Button)`
  border-radius: 8px;
  transition: background-color 0.3s ease-in-out;
  &:disabled {
    background-color: ${rgba(theme.palette.neutral.white, 0.3)};
      border: none;
      &:hover {
        background-color: ${rgba(theme.palette.neutral.white, 0.3)};
        border: none;
      }
  }
`;

const BackorderButton = styled(Button)`
    border-radius: 8px;
    margin-top: 12px;
`;

const ShipmentHeader = styled.h2`
    margin: 0 24px 0 auto;
    color: white;
`;
const ActionRowDivider = styled(Divider)`
  margin: 8px 0;
`;

const ComponentDetailWrapper = styled(FlexColumn)`
    width: 100%;
  align-items: flex-start;
`;

const ShippingDetailsRow = styled(FlexRow)`
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    gap: 20px;
`;

const ShippingDetailsWrapper = styled(FlexColumn)`
    width: calc(50% - 10px);
    align-items: flex-start;
    justify-content: flex-start;
`;
const DetailsRow = styled(FlexRow)`
  width: 100%;
  justify-content: flex-start;
  gap: 20px;
`;
const ItemDetailHeader = styled(PartDetailColumnHeader)`
  width: 100%;
`;

const ItemsWrapper = styled(FlexColumn)`
  align-items: flex-start;
  width: calc(100% - 16px);
`;

const ShipmentDetail = () => {
  const { currentUser } = useContext(AuthContext);
  const { firestore, database } = useFirebase();
  const ordersDbCollection = useRecoilValue(ORDERS_DB_COLLECTION);
  const partViewerDbCollection = useRecoilValue(PART_VIEWER_COLLECTION);
  const orderItemsDbCollection = useRecoilValue(ORDER_ITEMS_DB_COLLECTION);
  const inventoryDbCollection = useRecoilValue(INVENTORY_ITEMS_COLLECTION);
  const shipmentsDbCollection = useRecoilValue(SHIPMENTS_DB_COLLECTION);
  const reorderItemNotificationUsers = useRecoilValue(reorderPointNotificationUsersAtom);
  const messageDbString = useRecoilValue(MESSAGE_DB_PATH_ATOM);
  const setCurrentShopOrder = useSetRecoilState(currentShopOrderAtom);
  const [workOrderUpdateLabel, setWorkOrderUpdateLabel] = useRecoilState(workOrderUpdateLabelAtom);
  const { shipmentId, orderId, edit } = qs.parse(window.location.search.replace('?', '')) as { shipmentId: string, orderId: string, edit: string };
  const [shipOrder, setShipOrder] = useState<IShipment|IShipOrder>({} as IShipOrder);
  const [backorder, setBackorder] = useState<IShopOrder>({} as IShopOrder);
  const [shipmentItems, setShipmentItems] = useState<IShipmentItem[]>([]);
  const [customerParts, setCustomerParts] = useState<any[]>([]);
  const [inventoryItems, setInventoryItems] = useState<IInventoryPart[]>([]);
  const [recordNumber, setRecordNumber] = useState<string>('');
  const [shipDate, setShipDate] = useState<Date>();
  const [shippingCost, setShippingCost] = useState<number>(0);
  const [packingSlipNotes, setPackingSlipNotes] = useState<string>('');
  const [internalShipNotes, setInternalShipNotes] = useState<string>('');
  const [trackingNumber, setTrackingNumber] = useState<string|null>(null);
  const [edited, setEdited] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [saveText, setSaveText] = useState<string>('');
  const [showBackorderModal, setShowBackorderModal] = useState<boolean>(false);
  const [backorderModalTitle, setBackorderModalTitle] = useState<string>('Create backorder from unshipped parts?');
  const [backorderModalContent, setBackorderModalContent] = useState<string>('Should HELM create a backorder for the parts on this order that haven\'t shipped?');
  const [backorderModalCancelLabel, setBackorderModalCancelLabel] = useState<string>('Cancel unshipped items');

  const onCancel = (e: any) => {
    e.preventDefault();
    redirect();
  };

  const completeShipping = async (_shipItems: IShipmentItem[], backorderId: string|null = null) => {
    setSaveText('Saving to QBO...');
    const { shipment, qboId } = await createOrUpdateQboInvoice(
      database,
      orderId,
      shipmentId,
      shipDate || new Date(),
      recordNumber,
      packingSlipNotes,
      shippingCost,
      shipOrder as IShipOrder,
      _shipItems,
      trackingNumber,
    );

    const _backorderId = shipOrder.backorder?.id || backorderId;
    const _shipOrderRecord = { ...shipment, internalShipNotes, backorder: _backorderId ? { id: _backorderId, created: firebase.firestore.Timestamp.fromDate(new Date()) } : null };
    setShipOrder(_shipOrderRecord);
    setSaveText('Updating HELM data...');
    await updateHelmShipment(firestore, qboId, _shipOrderRecord, shipmentsDbCollection);
    await updateOrderItems(firestore, orderId, _shipOrderRecord, orderItemsDbCollection);
    await updateSalesOrder(firestore, database, messageDbString, reorderItemNotificationUsers, orderId, _shipOrderRecord, inventoryItems, setWorkOrderUpdateLabel, ordersDbCollection);
    setSaveText('Updating shipped part records...');
    await updatePartLastSold(firestore, shipment, partViewerDbCollection);

    setIsSaving(false);
    Modal.success({
      title: 'Shipment successful',
      content: 'The shipment has been created in Quickbooks. You can now print shipping and invoice documents here.',
      onOk: () => {
        window.location.href = `/shipments/edit?shipmentId=${shipment.id}&orderId=${orderId}`;
      },
    });
  };

  const onSave = async (e: any) => {
    e.preventDefault();
    setIsSaving(true);
    setSaveText('Checking for backorders...');
    const explicitBackorder = shipmentItems.filter((i: IShipmentItem) => i.quantityBackordered > 0).length > 0;
    if (explicitBackorder) {
      setBackorderModalTitle('Create backorder?');
      setBackorderModalContent('Should HELM create a backorder from unshipped parts? Click "Skip" if the backorder has already been created.');
      setBackorderModalCancelLabel('Skip backorder');
      setSaveText('Creating backorder...');
      setShowBackorderModal(true);
    } else {
      const needsBackorder = shipmentItems.filter((i: IShipmentItem) => i.quantityOpen > 0).length > 0;
      if (needsBackorder) {
        setShowBackorderModal(true);
      } else {
        completeShipping(shipmentItems, null);
      }
    }
  };

  const onCreateBackorder = async () => {
    setShowBackorderModal(false);
    setSaveText('Processing backorder...');
    const [backorderId, updatedShipmentItems] = await processBackorder(firestore, orderId, shipmentItems, shipOrder.type, currentUser.email);
    completeShipping(updatedShipmentItems, backorderId);
  };
  const onCancelBackorder = () => {
    setShowBackorderModal(false);
    setIsSaving(false);
  };
  const onSkipBackorder = () => {
    setShowBackorderModal(false);
    // skipping backorder effectively means canceling the other parts that may have been backordered
    const updatedShipItems = shipmentItems.map((s: IShipmentItem) => ({
      ...s,
      quantityCanceled: Math.max(s.quantityOrdered - (s.quantityShipped + (s.quantityBackordered || 0)), 0),
      quantityOpen: 0,
    }));
    completeShipping(updatedShipItems);
  };
  const onShipmentNumberChange = (shipmentNumber: string) => {
    if (!edited) setEdited(true);
    setRecordNumber(shipmentNumber);
  };

  const onShipDateChange = (_shipDate: Date) => {
    if (!edited) setEdited(true);
    setShipDate(_shipDate);
  };

  const onTrackingNumberChange = (_trackingNumber: string) => {
    if (!edited) setEdited(true);
    setTrackingNumber(_trackingNumber);
  };

  const onShipCostChange = (shipCost: number) => {
    if (!edited) setEdited(true);
    setShippingCost(shipCost);
  };

  const onPackingSlipNotesChange = (_notes: string) => {
    if (!edited) setEdited(true);
    setPackingSlipNotes(_notes);
  };

  const onInternalNotesChange = (_notes: string) => {
    if (!edited) setEdited(true);
    setInternalShipNotes(_notes);
  };

  const onCancelChange = (itemId: string, value: number) => {
    if (!edited) setEdited(true);
    const itemIndex = findIndex(shipmentItems, (i: IShipmentItem) => i.id === itemId);
    if (itemIndex === -1) return;
    const updatedItems = [...shipmentItems];
    updatedItems[itemIndex] = {
      ...updatedItems[itemIndex],
      quantityCanceled: value,
      quantityOpen: Math.max(0, updatedItems[itemIndex].quantityOrdered - (updatedItems[itemIndex].quantityShipped + value)),
    };
    setShipmentItems(updatedItems);
  };

  const onBackorderChange = (itemId: string, value: number) => {
    if (!edited) setEdited(true);
    const itemIndex = findIndex(shipmentItems, (i: IShipmentItem) => i.id === itemId);
    if (itemIndex === -1) return;
    const updatedItems = [...shipmentItems];
    updatedItems[itemIndex] = {
      ...updatedItems[itemIndex],
      quantityBackordered: value,
      quantityOpen: Math.max(0, updatedItems[itemIndex].quantityOrdered - (updatedItems[itemIndex].quantityShipped + value)),
    };
    setShipmentItems(updatedItems);
  };

  const onShipChange = (itemId: string, value: number) => {
    if (!edited) setEdited(true);
    const itemIndex = findIndex(shipmentItems, (i: IShipmentItem) => i.id === itemId);
    if (itemIndex === -1) return;
    const updatedItems = [...shipmentItems];
    updatedItems[itemIndex] = {
      ...updatedItems[itemIndex],
      quantityShipped: value,
      quantityOpen: Math.max(0, updatedItems[itemIndex].quantityOrdered - (updatedItems[itemIndex].quantityCanceled + value)),
    };
    setShipmentItems(updatedItems);
  };

  const onItemNotesChange = (itemId: string, value: string) => {
    if (!edited) setEdited(true);
    const itemIndex = findIndex(shipmentItems, (i: IShipmentItem) => i.id === itemId);
    if (itemIndex === -1) return;
    const updatedItems = [...shipmentItems];
    updatedItems[itemIndex] = { ...updatedItems[itemIndex], notes: value };
    setShipmentItems(updatedItems);
  };

  const onAddShipmentItem = (newItem: IShipmentItem) => {
    if (!edited) setEdited(true);
    setShipmentItems([...shipmentItems.filter((i) => i), newItem]);
  };

  const onUpdateShipmentItem = (updateItem: IShipmentItem) => {
    if (!edited) setEdited(true);
    const index = findIndex(shipmentItems, (i: IShipmentItem) => i.id === updateItem.id);
    const updatedItems = [...shipmentItems];
    updatedItems[index] = updateItem;
    setShipmentItems(updatedItems);
  };

  const onClearBackorder = async (e: any) => {
    if (!backorder.completed && !backorder.runners?.length) {
      Modal.confirm({
        title: 'Remove backorder from schedule?',
        content: 'Click "Remove" to delete the backorder from the schedule, along with any of its related records',
        okText: 'Remove',
        onOk: () => {
          firestore.collection('orders').doc(backorder.id).delete().then(() => {
            firestore.collection('order-items').doc(backorder.id).delete().then(() => {
              const updatedShipOrder = omit(shipOrder, ['backorder']);
              firestore.collection('order-shipments').doc(shipOrder.id).set(updatedShipOrder).then(() => {
                setShipOrder(updatedShipOrder);
                setBackorder({});
              });
            });
          });
        },
      });
    } else {
      Modal.info({
        title: 'Backorder cannot be removed',
        content: 'This order is either in process or has shipped, and cannot be removed.',
      });
    }
  };

  const onJumpToBackorder = async (e: any) => {
    stageRedirect();
    window.location.href = `/orders/create?edit=true&orderId=${backorder.id}&customer=${backorder.customer.DisplayName}&salesOrder=${backorder.salesOrder}`;
  };

  useEffect(() => {
    // orderId will be defined when we are creating a new shipment
    if (orderId && !shipmentId) {
      const invoiceNumberPath = useTestData ? '/recordNumbersDev/invoice' : '/recordNumbers/invoice';
      database.ref(invoiceNumberPath).once('value').then((val) => {
        setRecordNumber(val.val().toString());
        firestore.collection('orders').doc(orderId).get().then((doc) => {
          const data = doc.data() as IShipOrder;
          setCurrentShopOrder(data);
          setShipOrder(data);
          // setDescription(data.description);
          setPackingSlipNotes((data.notes || data.description).replace('\\n', '\n'));
          setInternalShipNotes((data.internalShipNotes || '').replace('\\n', '\n'));
          setShipDate(new Date());
          firestore.collection(orderItemsDbCollection).doc(orderId).get().then((itemsDoc) => {
            const itemsData = itemsDoc.data() as { id: string, orderItems: IShipmentItem[] };
            setShipmentItems(itemsData.orderItems.map((i: IShipmentItem) => ({
              ...i,
              quantityBackordered: 0,
              quantityOpen: 0,
              quantityShipped: i.quantityOpen,
            })));
          });
        });
      });
      // shipmentId will be defined when we are editing a shipment
    } else if (orderId && shipmentId) {
      firestore.collection(shipmentsDbCollection).doc(shipmentId).get().then((doc) => {
        const _shipOrder = doc.data() as IShipment;
        setShipOrder(_shipOrder);
        setShipmentItems(_shipOrder.shippedItems);
        setRecordNumber(_shipOrder.shipmentNumber);
        setPackingSlipNotes(_shipOrder.notes.replace('\\n', '\n'));
        setInternalShipNotes((_shipOrder.internalShipNotes || '').replace('\\n', '\n'));
        setShipDate(_shipOrder.shipDate.toDate());
        // setDescription(_shipOrder.description);
        setTrackingNumber(_shipOrder.trackingNumber || '');
        setShippingCost(_shipOrder.shippingCost);
        firestore.collection('orders').doc(orderId).get().then((orderDoc) => {
          const data = orderDoc.data() as IShopOrder;
          setCurrentShopOrder(data);
          if (_shipOrder.backorder) {
            firestore.collection('orders').doc(_shipOrder.backorder.id).get().then((boDoc) => {
              const boData = boDoc.data() as IShopOrder;
              setBackorder(boData);
            });
          }
        });
      });
    }
  }, []);

  useEffect(() => {
    firestore.collection(partViewerDbCollection).where('customer', '==', (shipOrder.customer?.DisplayName || '')).get().then((itemDocs) => {
      setCustomerParts(itemDocs.docs.map((d) => d.data()));
    });
  }, [shipOrder.id]);

  useEffect(() => {
    firestore.collection(inventoryDbCollection).get().then((inventoryDocs) => {
      setInventoryItems(inventoryDocs.docs.map((i) => i.data() as IInventoryPart));
    });
  }, []);

  return (
    <>
      <InvoicePDFDrawer shipment={shipOrder} />
      <PackSlipPDFDrawer shipment={shipOrder} />
      <BackorderConfirmationModal
        title={backorderModalTitle}
        content={backorderModalContent}
        cancelLabel={backorderModalCancelLabel}
        showModal={showBackorderModal}
        okCallback={onCreateBackorder}
        cancelCallback={onCancelBackorder}
        skipCallback={onSkipBackorder}
      />
      <ComponentWrapper>
        <ActionRow>
          <ItemDetailBackButton
            shape="circle"
            type="primary"
            // @ts-ignore
            icon={<LeftOutlined />}
            onClick={onCancel}
            disabled={isSaving}
          />
          {isSaving ? (
            <SpinnerWrapper>
              <SaveSpinner />
              <SaveText>{saveText}</SaveText>
            </SpinnerWrapper>
          ) : (
            <ActionButton onClick={onSave} type="primary" disabled={shipmentId && !edited}>{`${shipmentId ? 'Update' : 'Create'} Shipment`}</ActionButton>
          )}
          {shipmentId && (
          <PrintDocumentButton orderId={orderId} shipmentId={shipmentId} />
          )}
          <ShipmentHeader>{`Shipment #${recordNumber}`}</ShipmentHeader>
        </ActionRow>
        <ActionRowDivider />
        <ComponentDetailWrapper>
          <DetailsRow align="flex-start">
            <ShippingDetailsWrapper>
              <ItemDetailHeader key="part-details-column-header" marginTop="0px">Shipping Details</ItemDetailHeader>
              <DetailsRow>
                <DetailInputWithCallback
                  id="shipment-number"
                  label="Ship Number"
                  placeholder=""
                  value={recordNumber}
                  callback={onShipmentNumberChange}
                  extend={false}
                  isLabel={false}
                />
                <DetailDateWithCallback
                  value={shipDate}
                  id="ship-order-ship-date"
                  label="Ship Date"
                  callback={onShipDateChange}
                  disabled={false}
                />
                <DetailInputWithCallback
                  id="order-number"
                  label="Order Number"
                  LabelExtra={<OrderJumpButton orderId={orderId} border={false} />}
                  placeholder=""
                  value={shipOrder.salesOrder}
                  callback={nullOp}
                  disabled
                  extend={false}
                  isLabel={false}
                />
                <DetailInputWithCallback
                  id="purchase-order-number"
                  label="PO Number"
                  placeholder=""
                  value={shipOrder.purchaseOrder}
                  callback={nullOp}
                  disabled
                  extend={false}
                  isLabel={false}
                />
              </DetailsRow>
              <ActionRowDivider />
              <DetailsRow>
                <DetailInputWithCallback
                  width="200px"
                  id="shipment-tracking-number"
                  label="Tracking Number"
                  placeholder="e.g., 1Z43F7Y4..."
                  value={trackingNumber}
                  callback={onTrackingNumberChange}
                  extend={false}
                  isLabel={false}
                />
                <DetailNumberInputWithCallback
                  id="shipment-cost"
                  label="Shipping Cost"
                  placeholder=""
                  value={shippingCost}
                  callback={onShipCostChange}
                />
              </DetailsRow>
              <DetailTextAreaWithCallback
                width="624px"
                id="shipment-packing-slip-notes"
                label="Packing Slip Notes"
                placeholder={shipOrder.description}
                value={packingSlipNotes}
                changeCallback={onPackingSlipNotesChange}
                blurCallback={onPackingSlipNotesChange}
              />
              <DetailTextAreaWithCallback
                width="624px"
                id="shipment-internal-notes"
                label="Internal Notes"
                placeholder="Add internal notes here to communicate with Wildwood"
                value={internalShipNotes}
                changeCallback={onInternalNotesChange}
                blurCallback={onInternalNotesChange}
              />
            </ShippingDetailsWrapper>
            {backorder.id && (
              <ShippingDetailsWrapper>
                <ItemDetailHeader key="ship-items-column-header" marginTop="0px">Backorder</ItemDetailHeader>
                <DetailsRow>
                  <DetailInputWithCallback
                    // width="px"
                    id="shipment-backorder-created-date"
                    label="Date created"
                    placeholder=""
                    value={backorder.dateCreated.toDate().toLocaleDateString()}
                    callback={noop}
                    extend={false}
                    // isLabel
                    disabled
                  />
                  <BackorderButton type="default" onClick={onClearBackorder}>Clear backorder</BackorderButton>
                  <BackorderButton type="primary" onClick={onJumpToBackorder}>View order</BackorderButton>
                </DetailsRow>
                <DetailsRow>
                  <DetailInputWithCallback
                    // width="px"
                    id="shipment-backorder-sales-order"
                    label="Sales Order"
                    placeholder=""
                    value={backorder.salesOrder}
                    callback={noop}
                    extend={false}
                    // isLabel
                    disabled
                  />
                  <DetailInputWithCallback
                    id="shipment-backorder-description"
                    label="Description"
                    placeholder=""
                    value={backorder.description}
                    callback={noop}
                    extend
                    // isLabel
                    disabled
                  />
                </DetailsRow>
                <DetailsRow>
                  <DetailInputWithCallback
                    // width="px"
                    id="shipment-backorder-ship-date"
                    label="Ship Date"
                    placeholder=""
                    value={backorder.shipDate.toDate().toLocaleDateString()}
                    callback={noop}
                    extend={false}
                    // isLabel
                    disabled
                  />
                  <DetailInputWithCallback
                    // width="px"
                    id="shipment-backorder-unit-count"
                    label="Units"
                    placeholder=""
                    value={backorder.partCount}
                    callback={noop}
                    extend={false}
                    // isLabel
                    disabled
                  />
                  <DetailInputWithCallback
                    // width="px"
                    id="shipment-backorder-value"
                    label="Order Value"
                    placeholder=""
                    value={backorder.orderValue}
                    callback={noop}
                    extend={false}
                    // isLabel
                    disabled
                  />
                </DetailsRow>
              </ShippingDetailsWrapper>
            )}
          </DetailsRow>
          <ItemDetailHeader key="ship-items-column-header" marginTop="0px">Shipment Items</ItemDetailHeader>
          <ItemsWrapper>
            { (!!shipmentItems.filter((i: IShipmentItem) => i.open) && (
            <>
              <AddShipmentItemButton onAddCallback={onAddShipmentItem} orderId={shipOrder.id} salesOrder={shipOrder.salesOrder} disabled={false} />
              {(shipmentItems.filter((i: IShipmentItem) => i && i.quantityShipped >= 0).map((i: IShipmentItem) => (
                <ShipmentItem
                  id={`ship-item-${i.id}`}
                  hasShipped={!!shipmentId}
                  shipmentItem={i}
                  inventoryItems={inventoryItems}
                  customerParts={customerParts}
                  shipCallback={onShipChange}
                  cancelCallback={onCancelChange}
                  backorderCallback={onBackorderChange}
                  notesCallback={onItemNotesChange}
                  updateCallback={onUpdateShipmentItem}
                />
              )))}
            </>
            ))}
          </ItemsWrapper>
        </ComponentDetailWrapper>
      </ComponentWrapper>
    </>
  );
};

export default ShipmentDetail;
