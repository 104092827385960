import { every, find, first, sortBy } from 'lodash';
import { IConfigEntry, IConfigTerm, ICustomerPart } from 'shared/types/parts';
import { devLog } from 'shared/util/logging';
import { resolveInlay, resolvePickups, resolveModel, resolveBodyConstructionModifiers, resolveBodyFeatures } from './partResolver';
import { updateConfig } from './util';

/*
  Universal comparators - these are used for all parts
*/
export const compareArchetype = (a: IConfigEntry[], b: IConfigEntry[], configTerms: IConfigTerm[]) => {
  const archetypeA = find(a, (t) => t.type === 'Archetype');
  const archetypeB = find(b, (t) => t.type === 'Archetype');
  return {
    geometricMatch: archetypeA?.terms[0] === archetypeB?.terms[0],
  };
};

export const compareModel = (a: IConfigEntry[], b: IConfigEntry[], configTerms: IConfigTerm[]) => {
  const modelA = resolveModel(a);
  const modelB = resolveModel(b);
  const superModelMatch = modelA?.superModel === modelB?.superModel;
  const modelMatch = modelA?.model === modelB?.model;
  const subModelMatch = modelA?.subModel === modelB?.subModel;
  const modifiersMatch = every(modelA?.modifiers, (m) => modelB?.modifiers.includes(m)) && every(modelB?.modifiers, (m) => modelA?.modifiers.includes(m));
  const scaleMatch = modelA?.scale === modelB?.scale;
  if (!modelA && !modelB) return { geometricMatch: false };
  return {
    superModel: superModelMatch,
    model: modelMatch,
    subModel: subModelMatch,
    modifiers: modifiersMatch,
    scale: scaleMatch,
    geometricMatch: superModelMatch && modelMatch && subModelMatch && modifiersMatch && scaleMatch,
  };
};

/*
  Body comparators - these are used for all bodies
*/
export const compareBodyThickness = (a: IConfigEntry[], b: IConfigEntry[], configTerms: IConfigTerm[]) => {
  const thicknessA = find(a, (t) => t.type === 'Body_Thickness');
  const thicknessB = find(b, (t) => t.type === 'Body_Thickness');
  return {
    geometricMatch: thicknessA?.terms[0] === thicknessB?.terms[0],
  };
};
export const compareTop = (a: IConfigEntry[], b: IConfigEntry[], configTerms: IConfigTerm[]) => {
  const topA = find(a, (t) => t.type === 'Construction_Top');
  const topB = find(b, (t) => t.type === 'Construction_Top');
  if (!topA && !topB) return { geometricMatch: true };
  return {
    geometricMatch: topA?.terms[0] === topB?.terms[0],
  };
};
export const compareBodyConstructionModifier = (a: IConfigEntry[], b: IConfigEntry[], configTerms: IConfigTerm[]) => {
  const constructionModifierA = resolveBodyConstructionModifiers(a);
  const constructionModifierB = resolveBodyConstructionModifiers(b);
  if (constructionModifierA.terms.length === 0 && constructionModifierB.terms.length === 0) return { geometricMatch: true, difference: [] };
  return {
    geometricMatch: every(constructionModifierA?.terms, (t) => constructionModifierB?.terms.includes(t)) && every(constructionModifierB?.terms, (t) => constructionModifierA?.terms.includes(t)),
    difference: [
      ...constructionModifierA?.terms.filter((t) => !constructionModifierB?.terms.includes(t)),
      ...constructionModifierB?.terms.filter((t) => !constructionModifierA?.terms.includes(t)),
    ],
  };
};
export const compareBodyFeatures = (a: IConfigEntry[], b: IConfigEntry[], configTerms: IConfigTerm[]) => {
  const bodyFeaturesA = resolveBodyFeatures(a);
  const bodyFeaturesB = resolveBodyFeatures(b);
  if ((!bodyFeaturesA && !bodyFeaturesB) || (bodyFeaturesA?.terms.length === 0 && bodyFeaturesB?.terms.length === 0)) return { geometricMatch: true };
  return {
    geometricMatch: every(bodyFeaturesA?.terms, (t) => bodyFeaturesB?.terms.includes(t)) && every(bodyFeaturesB?.terms, (t) => bodyFeaturesA?.terms.includes(t)),
    difference: bodyFeaturesA?.terms.filter((t) => !bodyFeaturesB?.terms.includes(t)),
  };
};
export const compareBridge = (a: IConfigEntry[], b: IConfigEntry[], configTerms: IConfigTerm[]) => {
  const bridgeTypeA = find(a, (t) => t.type === 'Bridge_Type');
  const bridgeTypeB = find(b, (t) => t.type === 'Bridge_Type');
  const bridgeModifierA = find(a, (t) => t.type === 'Bridge_Modifier');
  const bridgeModifierB = find(b, (t) => t.type === 'Bridge_Modifier');

  const bridgeModifiersMatch = every(bridgeModifierA?.terms, (t) => bridgeModifierB?.terms.includes(t)) && every(bridgeModifierB?.terms, (t) => bridgeModifierA?.terms.includes(t));

  return {
    bridge: bridgeTypeA?.terms[0] === bridgeTypeB?.terms[0],
    modifiers: bridgeModifiersMatch,
    geometricMatch: bridgeTypeA?.terms[0] === bridgeTypeB?.terms[0] && bridgeModifiersMatch,
  };
};
export const comparePickups = (a: IConfigEntry[], b: IConfigEntry[], configTerms: IConfigTerm[]) => {
  const pickupsA = resolvePickups(a);
  const pickupsB = resolvePickups(b);

  return {
    neck: pickupsA.neck === pickupsB.neck,
    middle: pickupsA.middle === pickupsB.middle,
    bridge: pickupsA.bridge === pickupsB.bridge,
    geometricMatch: pickupsA.neck === pickupsB.neck && pickupsA.middle === pickupsB.middle && pickupsA.bridge === pickupsB.bridge,
  };
};
/*
  Neck comparators - these are used for all necks
*/
export const compareInlay = (a: IConfigEntry[], b: IConfigEntry[], configTerms: IConfigTerm[]) => {
  const inlayA = resolveInlay(a);
  const inlayB = resolveInlay(b);

  const frontMaterialMatch = inlayA.front.material === inlayB.front.material;
  const noFrontMaterialA = inlayA.front.material === 'No';
  const noFrontMaterialB = inlayB.front.material === 'No';
  const frontShapeMatch = (noFrontMaterialA && noFrontMaterialB) || (!noFrontMaterialA && !noFrontMaterialB && inlayA.front.shape === inlayB.front.shape);
  const sideMaterialMatch = inlayA.side.material === inlayB.side.material;
  const sideShapeMatch = inlayA.side.shape === inlayB.side.shape;

  return {
    geometricMatch: frontShapeMatch && sideShapeMatch,
    materialMatch: frontMaterialMatch && sideMaterialMatch,
    front: {
      material: frontMaterialMatch,
      shape: frontShapeMatch,
    },
    side: {
      material: sideMaterialMatch,
      shape: sideShapeMatch,
    },
  };
};

export const compareBinding = (a: IConfigEntry[], b: IConfigEntry[], configTerms: IConfigTerm[]) => {
  const bindingA = find(a, (t) => t.type === 'Binding');
  const bindingB = find(b, (t) => t.type === 'Binding');
  if (!bindingA && !bindingB) return { geometricMatch: true };
  return {
    material: bindingA?.terms[0] === bindingB?.terms[0],
    type: bindingA?.terms[1] === bindingB?.terms[1],
    geometricMatch: bindingA?.terms[0] === bindingB?.terms[0] && bindingA?.terms[1] === bindingB?.terms[1],
  };
};

export const compareNeckComposition = (a: IConfigEntry[], b: IConfigEntry[], configTerms: IConfigTerm[]) => {
  const neckCompositionA = find(a, (t) => t.type === 'Neck_Composition');
  const neckCompositionB = find(b, (t) => t.type === 'Neck_Composition');
  if (!neckCompositionA && !neckCompositionB) return { geometricMatch: true};
  return {
    geometricMatch: every(neckCompositionA?.terms, (t) => neckCompositionB?.terms.includes(t)) && every(neckCompositionB?.terms, (t) => neckCompositionA?.terms.includes(t)),
    difference: neckCompositionA?.terms.filter((t) => !neckCompositionB?.terms.includes(t)),
  };
};

export const compareNeckShape = (a: IConfigEntry[], b: IConfigEntry[], configTerms: IConfigTerm[]) => {
  const nutA = find(a, (t) => t.type === 'Neck_Shape_Nut');
  const nutB = find(b, (t) => t.type === 'Neck_Shape_Nut');
  const carveA = find(a, (t) => t.type === 'Neck_Shape_Carve');
  const carveB = find(b, (t) => t.type === 'Neck_Shape_Carve');
  const radiusA = find(a, (t) => t.type === 'Neck_Shape_Radius');
  const radiusB = find(b, (t) => t.type === 'Neck_Shape_Radius');
  return {
    geometricMatch: nutA?.terms[0] === nutB?.terms[0] && carveA?.terms[0] === carveB?.terms[0] && radiusA?.terms[0] === radiusB?.terms[0],
    nut: nutA?.terms[0] === nutB?.terms[0],
    carve: carveA?.terms[0] === carveB?.terms[0],
    radius: radiusA?.terms[0] === radiusB?.terms[0],
  };
};

export const compareFretwire = (a: IConfigEntry[], b: IConfigEntry[], configTerms: IConfigTerm[]) => {
  const prefixA = find(a, (t) => t.type === 'Fretwire_Prefix');
  const prefixB = find(b, (t) => t.type === 'Fretwire_Prefix');
  const wireA = find(a, (t) => t.type === 'Fretwire');
  const wireB = find(b, (t) => t.type === 'Fretwire');
  const suffixA = find(a, (t) => t.type === 'Fretwire_Suffix');
  const suffixB = find(b, (t) => t.type === 'Fretwire_Suffix');
  return {
    prefix: prefixA?.terms[0] === prefixB?.terms[0],
    wire: wireA?.terms[0].replace(/[SEGVO\*]/ig, '') === wireB?.terms[0].replace(/[SEGVO\*]/ig, ''),
    suffix: suffixA?.terms[0] === suffixB?.terms[0],
    geometricMatch: prefixA?.terms[0] === prefixB?.terms[0] && wireA?.terms[0].replace(/[SEGVO\*]/ig, '') === wireB?.terms[0].replace(/[SEGVO\*]/ig, '') && suffixA?.terms[0] === suffixB?.terms[0],
  };
};

export const compareTrussRod = (a: IConfigEntry[], b: IConfigEntry[], configTerms: IConfigTerm[]) => {
  const trussRodA = find(a, (t) => t.type === 'Truss_Rod');
  const trussRodB = find(b, (t) => t.type === 'Truss_Rod');
  if (!trussRodA && !trussRodB) return { geometricMatch: false };
  return {
    geometricMatch: trussRodA?.terms[0] === trussRodB?.terms[0],
  };
};

export const compareNeckFeatures = (a: IConfigEntry[], b: IConfigEntry[], configTerms: IConfigTerm[]) => {
  const neckFeaturesA = find(a, (t) => t.type === 'Neck_Features');
  const neckFeaturesB = find(b, (t) => t.type === 'Neck_Features');
  if (!neckFeaturesA && !neckFeaturesB) return { geometricMatch: true };
  return {
    geometricMatch: neckFeaturesA?.terms.every((t) => neckFeaturesB?.terms.includes(t)) && neckFeaturesB?.terms.every((t) => neckFeaturesA?.terms.includes(t)),
    difference: neckFeaturesA?.terms.filter((t) => !neckFeaturesB?.terms.includes(t)),
  };
};

interface GroupedParts {
  [key: string]: any[];
}

export const groupSimilarParts = (partsList: any[]): GroupedParts => {
  if (partsList.length === 0) {
    return {};
  }

  const result: GroupedParts = {};
  const [comparePart, ...remainingParts] = partsList;
  const similarParts = remainingParts.filter((part) => compareParts(comparePart, part));
  const nonSimilarParts = remainingParts.filter((part) => !compareParts(comparePart, part));

  result[comparePart.Sku] = [comparePart, ...similarParts];

  return {
    ...result,
    ...groupSimilarParts(nonSimilarParts),
  };
};

interface ParentUpdateResult {
  parent?: ICustomerPart;
  partsToUpdate: {
    part: ICustomerPart;
    updates: {
      childParts?: string[];
      parent?: string;
    };
  }[];
}

export const findParent = (part: ICustomerPart, candidateParts: ICustomerPart[]): ICustomerPart[] | null => {
  const geometricMatches = candidateParts.filter((p) => {
    const sameSku = p.Sku === part.Sku;
    const geometricMatch = compareParts(part, p);
    return !sameSku && geometricMatch;
  });

  if (geometricMatches.length === 0) {
    return null;
  }

  const sortedMatches = sortBy(geometricMatches, (p) => parseInt(p.Sku.split('_')[1], 10));
  const partNumber = parseInt(part.Sku.split('_')[1], 10);

  const trueParent = sortedMatches.find((p) => {
    const matchNumber = parseInt(p.Sku.split('_')[1], 10);
    return matchNumber < partNumber;
  });

  if (!trueParent) {
    // We're the parent - need to update our childParts and update all matches' parent field
    const updates: ICustomerPart[] = [];

    // Collect all child SKUs
    const childSkus = new Set<string>();
    sortedMatches.forEach((match) => {
      childSkus.add(match.Sku);
      match.childParts?.forEach((childSku) => childSkus.add(childSku));
    });

    // Update our part with all children
    updates.push({ ...part, childParts: Array.from(childSkus), parent: null });

    // Update all matches to point to us as parent
    sortedMatches.forEach((match) => {
      updates.push({ ...match, parent: part.Sku, childParts: null });
    });

    return updates;
  }

  // We found a true parent - update both our parent field and the parent's childParts
  const existingChildren = new Set(trueParent.childParts || []);
  existingChildren.add(part.Sku);
  // Add any children from our part
  part.childParts?.forEach((childSku) => existingChildren.add(childSku));

  return [
    { ...trueParent, childParts: Array.from(existingChildren) },
    { ...part, parent: trueParent.Sku, childParts: null },
  ];
};

export const establishPartLineages = (parts: ICustomerPart[], configTerms: IConfigTerm[]): {
  [key: string]: {
    parent: string | null;
    childParts: string[] | null;
  };
} => {
  const lineages: {
    [key: string]: {
      parent: string | null;
      childParts: string[] | null;
    };
  } = {};
  
  // Sort parts once at the start
  const sortedParts = [...parts].sort((a, b) => {
    const aNum = parseInt(a.Sku.split('_')[1], 10);
    const bNum = parseInt(b.Sku.split('_')[1], 10);
    return aNum - bNum;
  }).map((p) => ({ ...p, config: updateConfig(p.config, configTerms) }));

  const processFamily = (remainingParts: ICustomerPart[]): void => {
    if (remainingParts.length === 0) return;

    const currentPart = remainingParts[0];
    devLog('partComparator', 328, `Finding relatives of: ${currentPart.Sku}`);
    const similarParts = remainingParts.slice(1).filter((p) => 
      compareParts(currentPart, p, configTerms));

    // Record relationships
    lineages[currentPart.Sku] = {
      parent: null,
      childParts: similarParts.length > 0 ? similarParts.map((c) => c.Sku) : null,
    };

    similarParts.forEach((child) => {
      lineages[child.Sku] = {
        parent: currentPart.Sku,
        childParts: null,
      };
    });

    // Continue with remaining unprocessed parts
    const nextParts = remainingParts.slice(1).filter((p) => 
      !similarParts.some((s) => s.Sku === p.Sku));
    devLog('partComparator', 347, `Processing remaining parts: ${nextParts.length}`);
    processFamily(nextParts);
  };

  processFamily(sortedParts);
  return lineages;
};

const bodyComparators = [
  compareArchetype,
  compareModel,
  compareBodyThickness,
  compareTop,
  compareBodyConstructionModifier,
  compareBodyFeatures,
  compareBridge,
  comparePickups,
];
const neckComparators = [
  compareArchetype,
  compareModel,
  compareInlay,
  compareBinding,
  compareNeckComposition,
  compareNeckShape,
  compareFretwire,
  compareTrussRod,
  compareNeckFeatures,
];

export const compareParts = (a: ICustomerPart, b: ICustomerPart, configTerms: IConfigTerm[]) => {
  const comparators = ['GN', 'BN'].includes(a.type) ? neckComparators : bodyComparators;
  const aConfig = configTerms?.length > 0 ? updateConfig(a.config, configTerms) : a.config;
  const bConfig = configTerms?.length > 0 ? updateConfig(b.config, configTerms) : b.config;
  const results = comparators.map((c) => c(aConfig, bConfig, configTerms));
  return results.every((r) => r.geometricMatch);
};

export default compareParts;
