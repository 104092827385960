import React, { useContext, useEffect, useState } from 'react';
import {
  find, findIndex, includes,
} from 'lodash';
import firebase from 'firebase';
import styled from 'styled-components';
import { ChangeHistory } from '@styled-icons/material-twotone';
import shortid from 'shortid';
import {
  Button, Drawer, Modal,
} from 'antd';
import { useLongPress } from 'use-long-press';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  currentOrderItemAtom, currentShopOrderAtom, ORDER_ITEMS_DB_COLLECTION,
  orderItemsAtom, ORDERS_DB_COLLECTION,
  showAllOrderItemsAtom,
  showOrderItemEditDrawerAtom,
} from 'shared/state/orderState';
import { IBomItem, IOrderItem } from 'shared/types/dbRecords';
import { PartDetailColumnHeader } from 'shared/styledComponents/typographicElements';
import { inventoryItemsAtom } from 'shared/state/inventoryState';
import {
  bomEditedAtom, PART_BOM_COLLECTION, PART_VIEWER_COLLECTION, partBomItemsAtom,
} from 'shared/state/partViewState';
import { currentPartAtom, customerPartsAtom, partEditModeAtom } from 'shared/state/pricingState';
import theme from 'shared/theme';
import { SaveSpinner } from 'shared/styledComponents/utility';
import { FlexRow } from 'shared/containers/FlexContainer';
import { runnerWorkOrdersAtom } from 'shared/state/routingState';
import DetailInputWithCallback from 'shared/components/Input/DetailInputWithCallback';
import DetailNumberInputWithCallback from 'shared/components/Input/DetailNumberInputWithCallback';
import { stripPartNotes } from 'shared/text';
import { resolveBinding, resolveWeightReductionType, resolveWood } from 'shared/partParser/partResolver';
import DetailActiveSelectorWithCallback from 'shared/components/Input/DetailActiveSelectorWithCallback';
import { devLog } from 'shared/util/logging';
import { AuthContext } from 'vendor/Firebase/AuthProvider';
import useFirebase from 'vendor/Firebase';
import PartNotes from './OrderItemDetail/PartNotes';
import PartHouseSample from './OrderItemDetail/PartHouseSample';
import PartBomList from '../../../PartDetail/Components/PartBom/PartBomList';
import PartNumberSearchSelect from './OrderItemDetail/PartNumberSearchSelect';
import { IRunner } from '../../types';

const EditDrawer = styled(Drawer)`
  & .ant-drawer-header-title {
    width: 232px;
  }
  & .ant-drawer-body {
    background-color: ${(props: any) => (props.itemClosed ? theme.palette.neutral[100] : theme.palette.neutral.white)};
  }
`;

const PartQuantityRow = styled(FlexRow)`
  height: 32px;
  justify-content: flex-start;
  margin: 32px 0 16px 0;
  gap: 16px;
`;

const ActionRow = styled(FlexRow)`
  flex-grow: 2;
  justify-content: flex-start;
  gap: 4px;
`;
const ActionButton = styled(Button)`
  border-radius: 8px;
`;

const DeleteButton = styled(ActionButton)`
  margin-left: auto;
`;

const UpIcon = styled(ChangeHistory)`
  width: 32px;
  color: ${theme.palette.neutral[600]};
  transition: color 0.2s linear;
`;
const DownIcon = styled(UpIcon)`
  transform: rotate(180deg);
`;

const NextPartWrapper = styled(FlexRow)`
  width: 88px;
  height: 40px;
  gap: 4px;
`;
const NextPartButton = styled(Button)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background-color: transparent;
  border: none;
  &:hover {
    background-color: transparent;
    border: none;
    & ${UpIcon} {
      color: ${theme.palette.primary.hue};
    }
    & ${DownIcon} {
      color: ${theme.palette.primary.hue};
    }
  }
`;

interface IComponent {
  onItemSaveCallback: (orderItem: IOrderItem) => void;
  orderId: string;
  orderComplete: boolean;
}

const OrderItemEditDrawer = ({ onItemSaveCallback, orderId, orderComplete }: IComponent) => {
  const { currentUser } = useContext(AuthContext);
  const { firestore } = useFirebase();
  const [showOrderItemEditDrawer, setShowOrderItemEditDrawer] = useRecoilState(showOrderItemEditDrawerAtom);
  const [orderItems, setOrderItems] = useRecoilState(orderItemsAtom);
  const [currentOrderItem, setCurrentOrderItem] = useRecoilState(currentOrderItemAtom);
  const inventoryItems = useRecoilValue(inventoryItemsAtom);
  const [bomItems, setBomItems] = useRecoilState(partBomItemsAtom);
  const [bomEdited, setBomEdited] = useRecoilState(bomEditedAtom);
  const setPartEditMode = useSetRecoilState(partEditModeAtom);
  const setCurrentPart = useSetRecoilState(currentPartAtom);
  const setWorkOrders = useSetRecoilState(runnerWorkOrdersAtom);
  const customerParts = useRecoilValue(customerPartsAtom);
  const orderItemsDbString = useRecoilValue(ORDER_ITEMS_DB_COLLECTION);
  const ordersDbString = useRecoilValue(ORDERS_DB_COLLECTION);
  const partBomDbString = useRecoilValue(PART_BOM_COLLECTION);
  const partViewerDbString = useRecoilValue(PART_VIEWER_COLLECTION);
  const showClosedOrderItems = useRecoilValue(showAllOrderItemsAtom);
  const [orderItem, setOrderItem] = useState<IOrderItem>(find(orderItems.filter((o: IOrderItem) => o), (o: IOrderItem) => o.Sku === currentOrderItem) as IOrderItem);
  const [partType, setPartType] = useState<'body'|'neck'>('body');
  const [partNumber, setPartNumber] = useState<string>('');
  const [longClick, setLongClick] = useState<boolean>(false);
  const [currentShopOrder, setCurrentShopOrder] = useRecoilState(currentShopOrderAtom);
  const [updating, setUpdating] = useState<boolean>(false);

  const updatePartBom = (o: IOrderItem) => {
    firestore.collection(partBomDbString).doc(o.Sku).set({ bom: o.bom }).then(() => {
      setBomEdited(false);
    });
  };

  const onClose = async (e: any) => {
    const index = findIndex(orderItems, (o: IOrderItem) => o.id === orderItem.id);
    const updatedOrderItems = [...orderItems.slice(0, index), { ...orderItem, top: orderItem.top || 'None', bom: bomItems }, ...orderItems.slice(index + 1)];
    await firestore.collection(orderItemsDbString).doc(orderId).set({ orderId, orderItems: updatedOrderItems });

    if (bomEdited) {
      Modal.confirm({
        title: 'Apply BOM update to part?',
        content: 'Would you like to update the part BOM with the changes made here?',
        okText: 'Yes',
        cancelText: 'No',
        onOk: () => {
          updatePartBom({ ...orderItem, bom: bomItems });
        },
        onCancel: () => {
          setShowOrderItemEditDrawer(false);
          setOrderItems([...orderItems.slice(0, index), { ...orderItem, bom: bomItems }, ...orderItems.slice(index + 1)]);
        },
      });
    } else {
      setShowOrderItemEditDrawer(false);
      setOrderItems([...orderItems.slice(0, index), { ...orderItem, bom: bomItems }, ...orderItems.slice(index + 1)]);
    }
    onItemSaveCallback({ ...orderItem, bom: bomItems });
  };

  const onAdd = async (e: any) => {
    await firestore.collection(orderItemsDbString).doc(orderId).update({ orderItems });
    const newItemId = shortid.generate();
    const newItem = {
      id: newItemId,
      orderNumber: '',
      partNumber: '',
      description: 'Select a part from the dropdown',
      open: true,
      lastSold: null,
      unitPrice: 0,
      notes: '',
      volume: '',
      quantityAssigned: 0,
      quantityOrdered: 1,
      quantityCanceled: 0,
      quantityShipped: 0,
      quantityOpen: 1,
      bom: [],
    } as unknown as IOrderItem;
    const newItems = orderItems[0] === null ? [newItem] : [...orderItems, newItem];
    // @ts-ignore
    setOrderItems(newItems);
    setOrderItem(newItem);
    setCurrentOrderItem(newItem.id);
    const input = document.getElementById('order-item-search-dropdown');
    if (input) input.focus();
  };

  const onDelete = (isCancel: boolean) => async (e: any) => {
    const index = findIndex(orderItems, (o: IOrderItem) => o.id === currentOrderItem);
    const newOrderItems = orderItems.filter((o: IOrderItem) => o.id !== currentOrderItem);
    if (newOrderItems.length === 0) {
      await firestore.collection(orderItemsDbString).doc(orderId).update({ orderItems: [] });
      setOrderItems([null]);
      setShowOrderItemEditDrawer(false);
    } else {
      setOrderItems(newOrderItems);
      await firestore.collection(orderItemsDbString).doc(orderId).update({ orderItems: newOrderItems });
      const newIndex = Math.max(index - 1, 0);
      setCurrentOrderItem(newOrderItems[newIndex].id);
      if (isCancel) setShowOrderItemEditDrawer(false);
    }
  };

  const updateWorkOrders = (newPart: IOrderItem, key: string) => {
    if (!currentShopOrder.runners) return;
    const workOrders = currentShopOrder.runners.map((r: IRunner) => ({
      ...r,
      parts: r.parts.map((p: IOrderItem) => {
        if (p.Sku === newPart.Sku) {
          const _p = { ...p };
          // @ts-ignore
          _p[key] = newPart[key];
          return _p;
        }
        return p;
      }),
    }));
    setCurrentShopOrder({ ...currentShopOrder, runners: workOrders });
    setWorkOrders(workOrders);
    firestore.collection(ordersDbString).doc(orderId).update({ runners: workOrders }).then(() => {
      devLog('OrderItemEditDrawer', 240, 'work orders updated');
    });
  };

  const updateOrderItems = (): Promise<IOrderItem[]> => {
    const index = findIndex(orderItems, (o: IOrderItem) => o.id === currentOrderItem);
    const updatedOrderItems = [...orderItems.slice(0, index), { ...orderItem, bom: bomItems }, ...orderItems.slice(index + 1)];
    return new Promise((resolve, reject) => {
      firestore.collection(orderItemsDbString).doc(orderId).update({ orderItems: updatedOrderItems }).then(() => {
        resolve(updatedOrderItems);
      })
        .catch(() => {
          resolve(orderItems);
        });
    });
  };
  const onNotesChange = (notes: string) => {
    setOrderItem({ ...orderItem, notes });
    updateWorkOrders({ ...orderItem, notes }, 'notes');
  };

  const onDescriptionChange = (Description: string) => {
    setOrderItem({ ...orderItem, Description });
    updateWorkOrders({ ...orderItem, Description }, 'Description');
  };
  const onQuantityChange = (newQuantity: number) => {
    const bom = orderItem.bom.map((b: IBomItem) => ({ ...b, totalQuantity: newQuantity * b.quantity }));
    const newOrderItem = {
      ...orderItem,
      bom,
      quantityOrdered: newQuantity,
      quantityOpen: newQuantity - (orderItem.quantityShipped || 0 + orderItem.quantityCanceled || 0),
    };
    setOrderItem(newOrderItem);
  };
  const onPriceChange = (price: number) => {
    setOrderItem({ ...orderItem, unitPrice: price });
    updateWorkOrders({ ...orderItem, unitPrice: price }, 'unitPrice');
  };

  const onChangeMaterialsConfirmed = (materialsConfirmed: boolean) => {
    const materialsConfirmedTimestamp = materialsConfirmed ? firebase.firestore.Timestamp.now() : null;
    const materialsConfirmedBy = materialsConfirmed ? currentUser.email : null;
    firestore.collection(partViewerDbString).doc(orderItem.Sku).update({ materialsConfirmed: materialsConfirmedTimestamp, materialsConfirmedBy }).then(() => {
      setOrderItem({ ...orderItem, materialsConfirmed: materialsConfirmedTimestamp, materialsConfirmedBy });
      updateWorkOrders({ ...orderItem, materialsConfirmed: materialsConfirmedTimestamp, materialsConfirmedBy }, 'materialsConfirmed');
      setCurrentShopOrder({ ...currentShopOrder, materialsConfirmed: materialsConfirmedTimestamp, materialsConfirmedBy });
    });
  };

  const onIhsChange = (ihs: boolean) => {
    setOrderItem({ ...orderItem, houseSample: ihs });
    updateWorkOrders({ ...orderItem, houseSample: ihs }, 'houseSample');
  };

  const onPartNumberChange = async (_partNumber: string) => {
    const isCustomerPart = !!_partNumber.match(/[A-Z]{5}_[0-9]{5}/);
    const oldOrderItem = orderItems.find((o: IOrderItem) => o.id === currentOrderItem);
    const oldPart = find((isCustomerPart ? customerParts : inventoryItems), (o) => o.Sku === oldOrderItem?.Sku);
    const itemList = isCustomerPart ? customerParts : inventoryItems;
    const part: any = find(itemList, (p: any) => _partNumber === p.Sku);

    if (part) {
      const doc = await firestore.collection(partBomDbString).doc(part.Sku).get();
      const data = doc.data() as any;

      const partNotes = part.showNotesOnOrder && part.notes ? part.notes : '';
      const newOrderItemNotes = `${partNotes}${part.oneTimeNote || ''}`.trim();
      const existingCustomNotes = stripPartNotes(orderItem.notes, oldPart?.notes || '');

      const notes = `${newOrderItemNotes}${existingCustomNotes ? `\n${existingCustomNotes}` : ''}`.trim();

      const isCustPart = !!part.Sku.match(/^[A-Z]{5}/);
      const item = {
        // @ts-ignore
        ...orderItem,
        notes,
        Sku: _partNumber,
        Description: part.Description,
        unitPrice: isCustPart ? part.price : part.UnitPrice,
        materialsConfirmed: part.materialsConfirmed ?? null,
        materialsConfirmedBy: part.materialsConfirmedBy ?? '',
        // if the part is a misc part, artificially set the last sold date to the beginning of the epoch
        lastSold: isCustPart ? part.lastSold || null : firebase.firestore.Timestamp.fromDate(new Date(1970, 0, 1, 0, 0, 0)),
        houseSample: !isCustPart ? false : (!part.lastSold || false),
      };
      if (data) {
        item.bom = (data.bom || []) as IBomItem[];
        setBomItems((data?.bom || []) as IBomItem[]);
      }
      const partWood = resolveWood(part.config);
      const weightReductionType = resolveWeightReductionType(part.config);
      const binding = resolveBinding(part.config);
      // @ts-ignore
      if (includes(['GB', 'BB'], part.type)) {
        // @ts-ignore
        item.volume = part.volume || '';
        // @ts-ignore
        item.weightReductionType = weightReductionType?.text || '';
        // @ts-ignore
        item.top = partWood.top || 'None';
      }
      // @ts-ignore
      if (includes(['GN', 'BN'], part.type)) {
        // @ts-ignore
        item.bound = binding.text;
      }
      setOrderItem(item);
      const itemIndex = findIndex(orderItems, (o) => o.id === item.id);
      setOrderItems([...orderItems.slice(0, itemIndex), item, ...orderItems.slice(itemIndex + 1)]);

      // if the part has a OneTime™ note, delete it at the part level -- it has served its purpose.
      if (part.oneTimeNote) {
        firestore.collection(partViewerDbString).doc(part.Sku).update({ oneTimeNote: null }).then(() => {
          devLog('OrderItemEditDrawer', 314, 'OneTime™ note deleted');
        });
      }
    }
  };

  const setOrderItemDetails = (_item: IOrderItem) => {
    const _type = (_item?.Description || '').match(/^[G|B]N/) ? 'neck' : 'body';
    setCurrentOrderItem(_item.id);
    setCurrentPart(_item);
    setOrderItem(_item);
    setPartType(_type);
    setBomItems(_item?.bom || []);
    setPartEditMode(true);
    setPartNumber(_item.Sku || _item.Sku);
  };

  const onPrevPart = (e: any) => {
    if (longClick) {
      setLongClick(false);
      return;
    }
    setUpdating(true);
    updateOrderItems().then((updatedOrderItems) => {
      setUpdating(false);
      setOrderItems(updatedOrderItems);
      const _orderItems = orderItems.filter((o: IOrderItem) => o && (orderComplete || showClosedOrderItems || o.open));
      const newIndex = findIndex(_orderItems.filter((o: IOrderItem) => o && (orderComplete || showClosedOrderItems || o.open)), (o: IOrderItem) => o.id === orderItem.id);
      setOrderItemDetails(_orderItems[newIndex - 1]);
    });
  };
  const onLongPrev = useLongPress(() => {
    setLongClick(true);
    // filter orderItems to remove closed items if we are only looking at open items
    const _orderItems = orderItems.filter((o: IOrderItem) => o && (orderComplete || showClosedOrderItems || o.open));
    setOrderItemDetails(_orderItems[0]);
  });
  const onNextPart = async (e: any) => {
    if (longClick) {
      setLongClick(false);
      return;
    }
    // filter orderItems to remove closed items if we are only looking at open items
    setUpdating(true);
    updateOrderItems().then((updatedOrderItems) => {
      setUpdating(false);
      setOrderItems(updatedOrderItems);
      const _orderItems = orderItems.filter((o: IOrderItem) => o && (orderComplete || showClosedOrderItems || o.open));
      const newIndex = findIndex(_orderItems.filter((o: IOrderItem) => o && (orderComplete || showClosedOrderItems || o.open)), (o: IOrderItem) => o.id === orderItem.id);
      setOrderItemDetails(_orderItems[newIndex + 1]);
    });
  };
  const onLongNext = useLongPress(() => {
    setLongClick(true);
    // filter orderItems to remove closed items if we are only looking at open items
    const _orderItems = orderItems.filter((o: IOrderItem) => o && (orderComplete || showClosedOrderItems || o.open));
    setOrderItemDetails(_orderItems[_orderItems.length - 1]);
  });

  useEffect(() => {
    // filter orderItems to remove closed items if we are only looking at open items
    const _orderItems = orderItems.filter((o: IOrderItem) => o && (orderComplete || showClosedOrderItems || o.open));
    const _item = find(_orderItems.filter((o: IOrderItem) => o && (orderComplete || showClosedOrderItems || o.open)), (o: IOrderItem) => o.id === currentOrderItem);
    if (!_item) return;
    setOrderItemDetails(_item);
  }, [currentOrderItem]);
  
  return (
    <EditDrawer
      title={(orderItem?.Sku || '').length > 0 ? `Edit order item: ${orderItem.Sku}` : 'Add part to order'}
      width="50%"
      placement="right"
      open={showOrderItemEditDrawer}
      itemClosed={!orderItem?.open}
      closable={false}
      onClose={onClose}
      extra={(
        <ActionRow>
          <ActionButton type="primary" onClick={onClose} disabled={updating}>Save</ActionButton>
          <ActionButton type="default" onClick={onAdd} disabled={updating}>Add</ActionButton>
          {updating ? (
            <SaveSpinner />
          ) : (
            <NextPartWrapper>
              <NextPartButton
                onClick={onPrevPart}
                {...onLongPrev()}
                disabled={findIndex(orderItems.filter((o: IOrderItem) => o), (o: IOrderItem) => o.id === orderItem?.id) === 0}
                icon={<UpIcon />}
              />
              <NextPartButton
                onClick={onNextPart}
                {...onLongNext()}
                disabled={findIndex(orderItems.filter((o: IOrderItem) => o), (o: IOrderItem) => o.id === orderItem?.id) === orderItems.length - 1}
                icon={<DownIcon />}
              />
            </NextPartWrapper>
          )}
          <DeleteButton type={(orderItem?.Sku || '').length > 0 ? 'danger' : 'default'} onClick={onDelete((orderItem?.Sku || '').length === 0)}>
            {(orderItem?.Sku || '').length > 0 ? 'Delete' : 'Cancel'}
          </DeleteButton>
        </ActionRow>
      )}
    >
      { orderItem && (
        <>
          <PartDetailColumnHeader key="part-details-column-header">{`Part Details ${orderItem.open ? '' : '(CLOSED)'}`}</PartDetailColumnHeader>
          <PartNumberSearchSelect open={orderItem.open} partNumber={partNumber} changeCallback={onPartNumberChange} />
          <DetailInputWithCallback id="part-details-description" label="Description" placeholder="e.g., GB_Tele..." value={orderItem.Description || orderItem.Description || ''} callback={onDescriptionChange} width="100%" />
          <PartNotes key="part-notes-text-area" orderItem={orderItem} callback={onNotesChange} />
          <PartQuantityRow>
            <DetailNumberInputWithCallback key="part-details-quantity" label="Quantity" placeholder="1" value={orderItem.quantityOpen} callback={onQuantityChange} />
            {/* <PartHouseSample ihs={orderItem.houseSample} callback={onIhsChange} /> */}
            <DetailActiveSelectorWithCallback
              id="part-details-house-sample"
              initialState={orderItem.houseSample}
              checkedLabel="YES"
              uncheckedLabel="NO"
              callback={onIhsChange}
              componentLabel="House sample?"
              componentMargin="0px 8px 10px 8px"
            />
            <DetailActiveSelectorWithCallback
              id="part-details-materials-confirmed"
              initialState={!!orderItem.materialsConfirmed}
              checkedLabel="YES"
              uncheckedLabel="NO"
              callback={onChangeMaterialsConfirmed}
              componentLabel="Materials confirmed?"
              componentMargin="0px 8px 10px 8px"
            />

          </PartQuantityRow>
          <DetailNumberInputWithCallback key="part-details-unit-price" label="Unit Price" placeholder="" value={orderItem.unitPrice || 0} callback={onPriceChange} disabled={!orderItem.open} />
          <PartBomList open={orderItem.open} partType={partType} partId={orderItem.Sku} partDescription={orderItem.Description} partPrice={orderItem.unitPrice} />
        </>
      )}
      {/* </ComponentWrapper> */}
    </EditDrawer>
  );
};

export default OrderItemEditDrawer;
