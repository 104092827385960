import React from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import styled from 'styled-components';
import { Button, Modal } from 'antd';
import { Add } from '@styled-icons/material';
import { currentShipOrderAtom, showShipOrderDrawerAtom } from 'shared/state/orderState';

const ComponentButton = styled(Button)`
  border-radius: 8px;
`;

const AddIcon = styled(Add)`
  width: 24px;
  bottom: 1px;
  position: relative;
  left: -4px;
`;

const AddShipItemButton = () => {
  const currentShipOrder = useRecoilValue(currentShipOrderAtom);
  const setShowShipDrawer = useSetRecoilState(showShipOrderDrawerAtom);

  const onAdd = (e: any) => {
    setShowShipDrawer(false);
  };

  return (
    <ComponentButton key="add-ship-order-item" type="primary" disabled={!currentShipOrder.customer} icon={<AddIcon />} onClick={onAdd}>Add part</ComponentButton>
  );
};

export default AddShipItemButton;
