import React from 'react';
import styled from 'styled-components';
import { FlexRow } from '../../../../shared/containers/FlexContainer';

const HeaderWrapper = styled(FlexRow)`
  width: 100%;
  height: 36px;
  justify-content: space-between;
`;

const PartDescriptionWrapper = styled(FlexRow)`
  width: 60%;
  justify-content: flex-start;
  gap: 12px;
`;

const PartDescriptionHeader = styled.h3`
  width: 108px;
  font-size: 16px;
`;

const ShipQuantityWrapper = styled(FlexRow)`
  justify-content: space-between;
  gap: 24px;
`;

const ShipQuantityHeader = styled.h3`
  margin: 0;
  font-size: 16px;
  width: 90px;
`;

const OrderShipItemHeader = () => {
  const foo = 'bar';

  return (
    <HeaderWrapper>
      <PartDescriptionWrapper>
        <PartDescriptionHeader>Part #</PartDescriptionHeader>
        <PartDescriptionHeader>Description</PartDescriptionHeader>
      </PartDescriptionWrapper>
      <ShipQuantityWrapper>
        <ShipQuantityHeader>Ship</ShipQuantityHeader>
        <ShipQuantityHeader>Cancel</ShipQuantityHeader>
      </ShipQuantityWrapper>
    </HeaderWrapper>
  );
};

export default OrderShipItemHeader;
