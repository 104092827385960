import React, { useContext } from 'react';
import { Button } from 'antd';
import useFirebase from 'vendor/Firebase';
import styled from 'styled-components';
// import firebase, { firestore } from 'firebase/app';
import 'firebase/firestore';
import _, { find, includes, pad, update } from 'lodash';
import QBOItem from 'shared/data/QBO/item';
import {
  IBomItem, ICustomerRecord,
  IInventoryPart, IOrderItem, IProductCode, IQATicket, IShipment, IShipmentItem,
} from 'shared/types/dbRecords';
import axios from 'axios';
// import firebase from 'firebase';
import shortid from 'shortid';
import { aggregateTermsByType, isSamePart, orderTermsByRank, sanitizePartDescription, configToDescription, descriptionToConfig, updateConfig } from 'shared/partParser/util';
import neckParser from 'shared/partParser/neckParser';
import { partBom } from 'shared/data/jb_api';
import { IRunner, IShopOrder } from 'pages/Orders/types';
import bodyParser, { bodyWood, parseBodiesFromPartData, pickups } from 'shared/partParser/bodyParser';
import { resolveDescription, resolveArchetype, resolveModel, resolveWeight, resolvePickups } from 'shared/partParser/partResolver';
import { updateOrderItemBomConsumption } from 'shared/data/order';
import models from 'shared/partParser/models';
import { termTypes, terms } from 'shared/partParser/terms';
import { extractSpecies } from 'shared/partParser/helpers';
import { AuthContext } from 'vendor/Firebase/AuthProvider';
import compareParts, { establishPartLineages, findParent } from 'shared/partParser/partComparator';
import { partConfigTermsAtom, partConfigTermTypesAtom } from 'shared/state/partViewState';
import { useRecoilValue } from 'recoil';
import { ICustomerPart } from 'shared/types/parts';
import { P } from '@styled-icons/fa-solid';

const AdHocButton = styled(Button)`
  border-radius: 8px;
  min-width: 240px;
  text-align: center;
  margin-bottom: unset;
  margin-right: 8px;
`;

interface ParsedTerms {
  [key: string]: string[];
}

export default () => {
  const { functions, firestore, database } = useFirebase();
  const configTerms = useRecoilValue(partConfigTermsAtom);
  const configTermTypes = useRecoilValue(partConfigTermTypesAtom);
  const { currentUser } = useContext(AuthContext);
  const main = async () => {
    const orderDocs = await firestore.collection('orders')
      .where('completed', '!=', true)
      .get();
    const orders = orderDocs.docs.map((d) => d.data() as IShopOrder)
      .filter((o) => o.customer.DisplayName === 'NASHG');

    const orderItemsPromises = orders.map((order) => 
      firestore.collection('order-items')
        .doc(order.id)
        .get());
    const orderItemsDocs = await Promise.all(orderItemsPromises);
    
    const openItems = [];
    orders.forEach((order) => {
      const orderItems = orderItemsDocs
        .find((doc) => doc.id === order.id)
        ?.data()?.orderItems || [];

      const status = order.runners.length > 0 ? 'In Process' : 'Not Launched';

      orderItems.forEach((item) => {
        if (item.quantityOpen > 0) {
          openItems.push(
            `${order.purchaseOrder},${order.salesOrder},${item.Sku},${item.Description},${item.quantityOpen},${order.shipDate.toDate().toLocaleDateString()},${status}`,
          );
        }
      });
    });

    console.log(openItems.join('\n'));
  };

  const onClick = (e: any) => {
    e.preventDefault();
    if (process.env.NODE_ENV !== 'development') return;
    console.log('Beginning ad hoc routine');

    main().then(() => {
      console.log('Ad hoc routine complete');
    });
  };

  return (
    // @ts-ignore
    <AdHocButton disabled={window.location.pathname.match('wildwood')} type="default" onClick={onClick}>Execute Ad-hoc function</AdHocButton>
  );
};
