import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Input } from 'antd';
import { ItemDetailsColumnWrapper, ItemDetailsLabel } from 'shared/styledComponents/inputs';

const PackingSlipNotesInput = styled.textarea`
  width: 100%; 
  height: 64px;
  line-height: 16px;
  border-color: lightgrey;
`;

interface IComponent {
  packingSlipNotes: string;
  callback: (notes: string) => void;
}
const PackingSlipNotes = ({ packingSlipNotes, callback }: IComponent) => {
  const [notes, setNotes] = useState<string>(packingSlipNotes);
  const onChange = (e: any) => {
    setNotes(e.target.value);
    if (callback) callback(e.target.value);
  };
  useEffect(() => {
    setNotes(packingSlipNotes);
  }, [packingSlipNotes]);

  return (
    <ItemDetailsColumnWrapper key="part-notes-item-details-wrapper" width="50%">
      <ItemDetailsLabel key="part-notes-item-details-label">Packing Slip Notes</ItemDetailsLabel>
      <PackingSlipNotesInput
        key="part-Notes-input"
        id="part-Notes-input"
        placeholder="Packing Slip Notes"
        value={notes}
        onChange={onChange}
      />
    </ItemDetailsColumnWrapper>
  );
};

export default PackingSlipNotes;
