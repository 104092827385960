import React, { useState, useEffect } from 'react';
import {
  Document, Font, Page, PDFViewer, StyleSheet, View, Text,
} from '@react-pdf/renderer';
import { useRecoilValue } from 'recoil';
import { ICustomerRecord } from 'shared/types/dbRecords';
import { ITermsCode } from 'shared/types/jb';
import useFirebase from 'vendor/Firebase';
import {
  currentShopOrderAtom,
  orderItemsAtom,
} from 'shared/state/orderState';
import OrderConfirmationHeader from './Components/OrderConfirmation/OrderConfirmationHeader';
import { OrderConfirmationItem, OrderConfirmationItemHeader } from './Components/OrderConfirmation/OrderConfirmationItem';
import BillingShippingInfo from './Components/OrderConfirmation/BillingShippingInfo';
import OrderLogisticsDetail from './Components/OrderConfirmation/OrderLogisticsDetail';
import OrderConfirmationFooter from './Components/OrderConfirmation/OrderConfirmationFooter';
import { IItemData } from '../Orders/Components/OrderItems/OrderItem';
import OrderConfirmationContinuation from './Components/OrderConfirmation/OrderConfirmationContinuation';

Font.register({
  family: 'Red Hat Display-300',
  src: 'https://fonts.gstatic.com/s/redhatdisplay/v14/8vIf7wUr0m80wwYf0QCXZzYzUoTK8RZQvRd-D1NYbjKWckg.ttf',
});

Font.register({
  family: 'Red Hat Display',
  src: 'https://fonts.gstatic.com/s/redhatdisplay/v14/8vIf7wUr0m80wwYf0QCXZzYzUoTK8RZQvRd-D1NYbrKRckg.ttf',
});

Font.register({
  family: 'Red Hat Display-700',
  src: 'https://fonts.gstatic.com/s/redhatdisplay/v14/8vIf7wUr0m80wwYf0QCXZzYzUoTK8RZQvRd-D1NYbouRckg.ttf',
});

Font.register({
  family: 'Red Hat Display-600',
  src: 'https://fonts.gstatic.com/s/redhatdisplay/v14/8vIf7wUr0m80wwYf0QCXZzYzUoTK8RZQvRd-D1NYbrKRckg.ttf',
});

const HORIZONTAL_PADDING = 18;
const VERTICAL_PADDING = 36;

const style = StyleSheet.create({
  viewer: {
    width: '75vw',
    height: '100vh',
  },
  page: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    paddingTop: VERTICAL_PADDING * 1.5,
    paddingLeft: HORIZONTAL_PADDING,
    paddingRight: HORIZONTAL_PADDING * 2,
    paddingBottom: VERTICAL_PADDING,
  },
  confirmationBody: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    marginTop: -4,
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 7,
    bottom: 24,
    left: 0,
    right: 24,
    textAlign: 'right',
    color: 'grey',
  },
});

const OrderConfirmationPDF = () => {
  const currentShopOrder = useRecoilValue(currentShopOrderAtom);
  const jbOrderItems = useRecoilValue(orderItemsAtom);
  // const orderBillingInfo = useRecoilValue(orderBillingDataAtom);
  // const orderShippingInfo = useRecoilValue(orderShippingDataAtom);
  const [terms, setTerms] = useState<string>('');
  const { firestore } = useFirebase();

  const total = jbOrderItems
    .map((orderItem: IItemData) => (orderItem.quantityOrdered + orderItem.quantityCanceled) * orderItem.unitPrice)
    .reduce((a, b) => a + b, 0);

  useEffect(() => {
    firestore.collection('customers').doc(currentShopOrder.customer.id).get().then((doc) => {
      const data = doc.data() as ICustomerRecord;
      firestore.collection('terms').doc(data.SalesTermRef.value).get().then((termsDoc) => {
        setTerms(termsDoc.data().Name);
      });
    });
  }, []);

  return (
    <>
      {(currentShopOrder && jbOrderItems) && (
        <>
          <PDFViewer showToolbar style={style.viewer}>
            <Document title={`Order Confirmation - ${currentShopOrder.customer.id} 1${currentShopOrder.salesOrder.split('-')[0]}`}>
              <Page size="LETTER" style={style.page}>
                <OrderConfirmationHeader order={currentShopOrder} items={jbOrderItems} />
                <View
                  fixed
                  render={({ pageNumber, subPageNumber }) => (
                    pageNumber > 1 && (
                    <OrderConfirmationContinuation pageNumber={pageNumber} />
                    ))}
                />
                <View style={style.confirmationBody}>
                  <BillingShippingInfo order={currentShopOrder} billingInfo={currentShopOrder.customer.BillAddr} shippingInfo={currentShopOrder.customer.ShipAddr} />
                  <OrderLogisticsDetail poNumber={currentShopOrder.purchaseOrder} shipsVia={currentShopOrder.customer.shipsWith} terms={terms} />
                  <OrderConfirmationItemHeader />
                  {jbOrderItems.filter((orderItem) => orderItem.quantityOrdered > 0)
                    .map((orderItem) => (
                      <OrderConfirmationItem
                        quantity={orderItem.quantityOrdered + orderItem.quantityCanceled}
                        partNumber={orderItem.Sku}
                        description={orderItem.Description}
                        notes={orderItem.notes}
                        unitPrice={orderItem.unitPrice}
                      />
                    ))}
                  <OrderConfirmationFooter total={total} />
                </View>
                <Text
                  style={style.pageNumber}
                  render={({ pageNumber, totalPages }) => (
                    totalPages > 1 ? `Page ${pageNumber} of ${totalPages}` : ''
                  )}
                  fixed
                />
              </Page>
            </Document>
          </PDFViewer>
        </>
      )}
    </>
  );
};

export default OrderConfirmationPDF;
