import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Button, message, Modal } from 'antd';
import { FlexRow } from 'shared/containers/FlexContainer';
import DetailInputWithCallback from 'shared/components/Input/DetailInputWithCallback';
import theme from 'shared/theme';
import { IConfigTerm } from 'shared/types/parts';
import { useTestData, isDevSite } from 'shared/util';
import useFirebase from 'vendor/Firebase';
import { useRecoilValue } from 'recoil';
import { PART_CONFIG_TERMS_COLLECTION } from 'shared/state/partViewState';
import DetailDropdownWithCallback from 'shared/components/Input/DetailDropdownWithCallback';

const SaveButton = styled(Button)`
  border-radius: 8px;
`;

const DeleteButton = styled(Button)`
  background-color: ${theme.palette.error.hue};
  color: ${theme.palette.neutral.white};
  border-radius: 8px;
`;

const PartTermRow = styled(FlexRow)`
  justify-content: flex-start;
  margin-bottom: 8px;
  gap: 12px;
`;

const ActionButtons = styled(FlexRow)`
  gap: 8px;
  margin: 0;
  margin-left: auto;
`;

interface IComponent {
  term: IConfigTerm;
  termCategories: string[];
  callback: () => void;
}

const PartTerm = ({ term, termCategories, callback }: IComponent) => {
  const { firestore } = useFirebase();
  const partConfigTermsCollection = useRecoilValue(PART_CONFIG_TERMS_COLLECTION);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [showMoveModal, setShowMoveModal] = useState<boolean>(false);
  const [termText, setTermText] = useState<string>(term.term);
  const [termEnglish, setTermEnglish] = useState<string>(term.english || '');
  const [termCoercion, setTermCoercion] = useState<string>(term.coercion || '');
  const [termType, setTermType] = useState<string>(term.type);

  const onUpdateTerm = (field: string) => (value: string, blur: boolean) => {
    if (blur) {
      if (field === 'term') setTermText(value);
      if (field === 'english') setTermEnglish(value);
      if (field === 'coercion') setTermCoercion(value);
    }
  };

  const onUpdateTermType = (value: string) => {
    setTermType(value);
    // console.log(`moving to ${value}`);
    // try {
    //   await firestore.collection(partConfigTermsCollection).doc(term.id).update({ type: value});
    //   message.success('Term moved successfully');
    //   setShowMoveModal(false);
    //   callback();
    // } catch (error) {
    //   message.error('Failed to move term');
    // }
  };

  const onSave = async (e: any) => {
    try {
      const data = {
        term: termText,
        type: term.type,
        english: termEnglish,
        coercion: termCoercion,
      };  
      await firestore.collection(partConfigTermsCollection).doc(term.id).set(data);
      if (!useTestData && !isDevSite()) {
        await firestore.collection('part-config-terms-test').doc(term.id).set(data);
      }  
      message.success('Term saved successfully');
      callback();
    } catch (error) {
      message.error('Failed to save term');
    }
  }; 

  const onDelete = async () => {
    try {
      await firestore.collection(partConfigTermsCollection).doc(term.id).delete();
      if (!useTestData && !isDevSite()) {
        await firestore.collection('part-config-terms-test').doc(term.id).delete();
      }
      message.success('Term deleted successfully');
      setShowDeleteModal(false);
      callback();
    } catch (error) {
      message.error('Failed to delete term');
    }
  };
  
  const onMove = async () => {
    try {
      await firestore.collection(partConfigTermsCollection).doc(term.id).update({ type: termType});
      message.success('Term moved successfully');
      setShowMoveModal(false);
      callback();
    } catch (error) {
      message.error('Failed to move term');
    }
  };

  const onClickDelete = (e: any) => {
    setShowDeleteModal(true);
  };

  const onShowMoveModal = (e: any) => {
    setShowMoveModal(true);
  };

  useEffect(() => {
    setTermText(term.term);
    setTermEnglish(term.english || '');
    setTermCoercion(term.coercion || '');
  }, [term]);

  return (
    <>
      <Modal
        title="Confirm Delete"
        open={showDeleteModal}
        onOk={() => onDelete()}
        onCancel={() => setShowDeleteModal(false)}
        okText="Yes, Delete"
        cancelText="Cancel"
        okButtonProps={{ danger: true }}
      >
        <p>{`Are you sure you want to delete the term "${term?.term}"?`}</p>
        <p>
          This action cannot be undone.
        </p>
      </Modal>
      <Modal
        title="Move Term"
        open={showMoveModal}
        onOk={() => onMove()}
        onCancel={() => setShowMoveModal(false)}
        okText="Yes, Move"
        cancelText="Cancel"
      >
        <p>{`Move term ${term.term} to:`}</p>
        <DetailDropdownWithCallback
          id={`${term.id}-move`}
          label="Move to"
          selectedOption={term.type}
          changeCallback={onUpdateTermType}
          optionsList={termCategories.map((category) => ({ label: category, value: category }))}
        />
      </Modal>
      <PartTermRow>
        <DetailInputWithCallback 
          id={`${term.id}-term`}
          label="Term"
          placeholder={term.term}
          value={termText}
          callback={onUpdateTerm('term')}
          extend={false}
          isLabel={false}
          width="250px"
        />
        <DetailInputWithCallback 
          id={`${term.id}-english`}
          label="English Definition"
          placeholder="How you'd describe this term in English"
          value={termEnglish}
          callback={onUpdateTerm('english')}
          extend
          isLabel={false}
        />
        <DetailInputWithCallback 
          id={`${term.id}-coercion`}
          label="Coerce to"
          placeholder="What this term should be coerced to"
          value={termCoercion}
          callback={onUpdateTerm('coercion')}
          extend
          isLabel={false}
        />
        <ActionButtons>
          <SaveButton type="primary" onClick={onSave}>Save</SaveButton>
          <SaveButton type="default" onClick={onShowMoveModal}>Move</SaveButton>
          <DeleteButton onClick={onClickDelete}>Delete</DeleteButton>
        </ActionButtons>
      </PartTermRow>
    </>
  );
};

export default PartTerm;
