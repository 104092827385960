export const termTypes = {
  // general
  Archetype: 0.01,
  Model_Supermodel: 0.05,
  Model: 0.1,
  Model_Submodel: 0.11,
  Model_Modifier: 0.12,
  Finish_Option: 0.27,
  Wood_Feature: 20,
  Wood: 20.1,

  // body
  Body_Thickness: 0.5,
  Body_Construction_Modifier: 1,
  Construction_Top: 1.1,
  Bridge_Modifier: 2,
  Bridge_Type: 2.1,
  Pickups: 3,
  Body_Feature: 4,
  Weight: 100,

  // neck
  Construction_Modifier: 1,
  Neck_Composition: 3,
  Model_Scale: 3.1,
  Truss_Rod: 3.2,
  Neck_Shape_Nut: 4,
  Neck_Shape_Carve: 4.1,
  Neck_Shape_Radius: 4.2,
  Fretwire_Prefix: 5,
  Fretwire: 5.1,
  Fretwire_Suffix: 5.2,
  Inlay: 6,
  Inlay_Suffix: 6.1,
  Binding: 7,
  Neck_Feature: 8,
  Wood_Peghead: 20.2,
};

export const terms = {
  "'49SH": 'Model',
  "'53": 'Model',
  "50's": 'Model',
  "60'S JJ": 'Pickups',
  "60's J/J": 'Pickups',
  "60's P/J": 'Pickups',
  "60's": 'Model',
  "60'sP/J": 'Pickups',
  "70's": 'Model',
  "NO PU's": 'Pickups',
  "No PU's": 'Pickups',
  '"12""""R"': 'Neck_Shape_Radius',
  '(Gibson)SDMH/S': 'Pickups',
  '*LooseLinedFretless*': 'Fretwire_Suffix',
  '.125 LinedFretless': 'Fretwire',
  '.140': 'Body_Feature',
  '.160': 'Body_Feature',
  '.175RO': 'Body_Feature',
  '.187': 'Body_Feature',
  '.1875 Top': 'Body_Feature',
  '.187RO': 'Body_Feature',
  '.375Top': 'Body_Feature',
  '.500RO': 'Body_Feature',
  '.625NP': 'Body_Feature',
  '.625StratNP': 'Body_Feature',
  '/Ash Lite/Ven/5AFlameMap': 'Wood',
  '/CS': 'Wood',
  '0.125RO': 'Body_Feature',
  '0.125Special': 'Body_Feature',
  '0.1875GndW': 'Body_Feature',
  '0.1875RO': 'Body_Feature',
  '0.375BMH': 'Body_Feature',
  '0.625 StratNP': 'Body_Feature',
  '0.625NP': 'Body_Feature',
  '0.625NP-Tight': 'Body_Feature',
  '0.795NP': 'Body_Feature',
  '00.125RO': 'Body_Feature',
  '1 BMH': 'Bridge_Modifier',
  '1 Pot': 'Body_Feature',
  '1-5/8': 'Body_Thickness',
  '1.40': 'Body_Thickness',
  '1.450N': 'Neck_Shape_Nut',
  '1.45N': 'Neck_Shape_Nut',
  '1.485N': 'Neck_Shape_Nut',
  '1.50': 'Body_Thickness',
  '1.500N': 'Neck_Shape_Nut',
  '1.50N': 'Neck_Shape_Nut',
  '1.520N': 'Neck_Shape_Nut',
  '1.526N': 'Neck_Shape_Nut',
  '1.53N': 'Neck_Shape_Nut',
  '1.540N': 'Neck_Shape_Nut',
  '1.545N': 'Neck_Shape_Nut',
  '1.54N': 'Neck_Shape_Nut',
  '1.550N': 'Neck_Shape_Nut',
  '1.600N': 'Neck_Shape_Nut',
  '1.60N': 'Neck_Shape_Nut',
  '1.625N': 'Neck_Shape_Nut',
  '1.635N': 'Neck_Shape_Nut',
  '1.650N': 'Neck_Shape_Nut',
  '1.65N': 'Neck_Shape_Nut',
  '1.67N': 'Neck_Shape_Nut',
  '1.687N': 'Neck_Shape_Nut',
  '1.68N': 'Neck_Shape_Nut',
  '1.70': 'Body_Thickness',
  '1.70N': 'Neck_Shape_Nut',
  '1.715N': 'Neck_Shape_Nut',
  '1.750': 'Body_Thickness',
  '1.750N': 'Neck_Shape_Nut',
  '1.75N': 'Neck_Shape_Nut',
  '1.765N': 'Neck_Shape_Nut',
  '1.766N': 'Neck_Shape_Nut',
  '1.771N': 'Neck_Shape_Nut',
  '1.783N': 'Neck_Shape_Nut',
  '1.785N': 'Neck_Shape_Nut',
  '1.810N': 'Neck_Shape_Nut',
  '1.817N': 'Neck_Shape_Nut',
  '1.825N': 'Neck_Shape_Nut',
  '1.85N': 'Neck_Shape_Nut',
  '1.8750N': 'Neck_Shape_Nut',
  '1.875N': 'Neck_Shape_Nut',
  '1/4RO': 'Body_Feature',
  '1/4TopRO': 'Body_Feature',
  '1/8RO': 'Body_Feature',
  '10 -12CR': 'Neck_Shape_Radius',
  '10-12CR': 'Neck_Shape_Radius',
  '10-14CR': 'Neck_Shape_Radius',
  '10-15CR': 'Neck_Shape_Radius',
  '10-16CR': 'Neck_Shape_Radius',
  '10R': 'Neck_Shape_Radius',
  '10ST': 'Model',
  '12-14CR': 'Neck_Shape_Radius',
  '12-16CR': 'Neck_Shape_Radius',
  '12/14CR': 'Neck_Shape_Radius',
  '12/16CR': 'Neck_Shape_Radius',
  '12R': 'Neck_Shape_Radius',
  '12ST': 'Model',
  '14R': 'Neck_Shape_Radius',
  '1650N': 'Neck_Shape_Nut',
  '1687N': 'Neck_Shape_Nut',
  '16R': 'Neck_Shape_Radius',
  '17.5mmBMH': 'Bridge_Modifier',
  '19F': 'Fretwire_Prefix',
  '19mmNP': 'Body_Feature',
  '1BMH': 'Bridge_Modifier',
  '1DeepPU': 'Pickups',
  '1Graph': 'Neck_Feature',
  '1PC': 'Neck_Composition',
  '1Pc 3ARstFlameMap/IRW/EBY PH': 'Wood',
  '1Pc 3ARstFlameMap/IRW/EBY PH-NoLogo': 'Wood',
  '1Pc 5ARstFlameMap/IRW/EBY PH': 'Wood',
  '1Pc QSGenMah/CS/CS': 'Wood',
  '1Pc': 'Neck_Composition',
  '1Pc2ARstFlameMap/IRW/CS PH': 'Wood',
  '1Pc2ARstFlameMap/IRW/EBY PH': 'Wood',
  '1Pc5ARstFlameMap/IRW/CS PH': 'Wood',
  '1Pc5ARstFlameMap/IRW/IRW PH': 'Wood',
  '1PcGenMah': 'Wood',
  '1PcGenMah/IRW/EBY PH': 'Wood',
  '1PcGenMah/IRW/IRW PH': 'Wood',
  '1PcGenMahQSMap/EBY': 'Wood',
  '1PcKhyMah': 'Wood',
  '1Vol & 1Tone': 'Body_Feature',
  '1WH': 'Truss_Rod',
  '1WP': 'Truss_Rod',
  '1WS': 'Truss_Rod',
  '2 Graph': 'Neck_Feature',
  '2 Pc Off': 'Wood_Feature',
  '2 Pc': 'Wood_Feature',
  '2 Post Trem': 'Bridge_Type',
  '2 Pots': 'Body_Feature',
  '2.125N': 'Neck_Shape_Nut',
  '2.168 NP': 'Body_Feature',
  '2.25 NP': 'Body_Feature',
  '2.25 Tele NP': 'Body_Feature',
  '2.250N': 'Neck_Shape_Nut',
  '2.25AngledNP': 'Body_Feature',
  '2.25NP': 'Body_Feature',
  '2.25STH': 'Bridge_Modifier',
  '2.438NP': 'Body_Feature',
  '2020 WIDE 34': 'Model',
  '20F': 'Fretwire_Prefix',
  '20R': 'Neck_Shape_Radius',
  '21 Scale': 'Model',
  '21F': 'Fretwire_Prefix',
  '21F+3 Slots': 'Model',
  '21FS': 'Fretwire_Prefix',
  '22F': 'Fretwire_Prefix',
  '22S': 'Fretwire_Prefix',
  '22Slots': 'Fretwire_Prefix',
  '23F': 'Fretwire_Prefix',
  '24.75Scale': 'Model',
  '24F': 'Fretwire_Prefix',
  '24Scale': 'Model',
  '27.78 Scale': 'Model',
  '2ARstFlameMap/2ARstFlameMap': 'Wood',
  '2ARstFlameMap/3ARstFlameMap': 'Wood',
  '2ARstFlameMap/5ARstFlameMap': 'Wood',
  '2ARstFlameMap/EBY': 'Wood',
  '2ARstFlameMap/IRW': 'Wood',
  '2ARstFlameMap/Self': 'Wood',
  '2Lb 14': 'Weight',
  '2Lb 14..': 'Weight',
  '2Nord': 'Pickups',
  '2P Bridge': 'Bridge_Type',
  '2POTS': 'Body_Feature',
  '2PT Ash Lite': 'Wood_Feature',
  '2PT': 'Wood_Feature',
  '2PTrem': 'Bridge_Type',
  '2PTrem-Small': 'Bridge_Type',
  '2Parts': 'Wood_Feature',
  '2Pc Off': 'Wood_Feature',
  '2Pc': 'Wood_Feature',
  '2Post Trem': 'Bridge_Type',
  '2Post': 'Bridge_Type',
  '2Pots': 'Body_Feature',
  '2WH': 'Truss_Rod',
  '2WP': 'Truss_Rod',
  '2lb 14': 'Weight',
  '3 Pots': 'Body_Feature',
  '3/16RO': 'Body_Feature',
  '3/8RO': 'Body_Feature',
  '3/8Thimble': 'Body_Feature',
  '30Scale': 'Model_Submodel',
  '34 Scale': 'Model_Submodel',
  '34/20': 'Model_Submodel',
  '35 Scale': 'Model_Submodel',
  '375BMH': 'Bridge_Modifier',
  '3A FLM': 'Wood',
  '3A FLM/3A FLM': 'Wood',
  '3A FLM/IRW': 'Wood',
  '3AFlameMap': 'Wood',
  '3AFlmMap/Self': 'Wood',
  '3ANatBseMap Map/EBY': 'Wood',
  '3ANatBseMap/3ANatBseMap': 'Wood',
  '3ANatBseMap/5ANatBseMap': 'Wood',
  '3ANatBseMap/EBY': 'Wood',
  '3ANatBseMap/IRW': 'Wood',
  '3ANatFlameMap': 'Wood',
  '3ANatFlameMap/3ANatFlameMap': 'Wood',
  '3ANatFlameMap/EBY': 'Wood',
  '3ANatFlameMap/IRW': 'Wood',
  '3ANatFlmMap/EBY': 'Wood',
  '3ANatFlmMap/IRW': 'Wood',
  '3ANatMap/EBY': 'Wood',
  '3ARstBseMap/IRW': 'Wood',
  '3ARstBseMap/Rich Lite': 'Wood',
  '3ARstBseMap/Self': 'Wood',
  '3ARstFlameMap/3ARstFlameMap': 'Wood',
  '3ARstFlameMap/EBY': 'Wood',
  '3ARstFlameMap/EBY/EBY PH': 'Wood',
  '3ARstFlameMap/IRW': 'Wood',
  '3ARstFlameMap/IRW/EBY PH': 'Wood',
  '3ARstFlameMap/Self': 'Wood',
  '3BMH': 'Bridge_Modifier',
  '3Lb  4': 'Weight',
  '3Lb  8': 'Weight',
  '3Lb 0': 'Weight',
  '3Lb 10': 'Weight',
  '3Lb 12': 'Weight',
  '3Lb 14': 'Weight',
  '3Lb 15': 'Weight',
  '3Lb 2': 'Weight',
  '3Lb 3': 'Weight',
  '3Lb 4': 'Weight',
  '3Lb 5': 'Weight',
  '3Lb 6': 'Weight',
  '3Lb 8': 'Weight',
  '3Lb 9': 'Weight',
  '3Lb14': 'Weight',
  '3Lb6': 'Weight',
  '3Lb8': 'Weight',
  '3Lbs 14': 'Weight',
  '3POT': 'Body_Feature',
  '3POTS': 'Body_Feature',
  '3PcTILT': 'Neck_Composition',
  '3PcTilt': 'Neck_Composition',
  '3Pots': 'Body_Feature',
  '3Way Switch': 'Body_Feature',
  '4 Pack': 'Model_Submodel',
  '4/19/30': 'Model_Scale',
  '4/20': 'Model_Scale',
  '4/20-WLE': 'Model',
  '4/20/30': 'Model_Scale',
  '4/20/34': 'Model_Scale',
  '4/21': 'Model_Scale',
  '4/21/30': 'Model_Scale',
  '4/21/32': 'Model_Scale',
  '4/21/34': 'Model_Scale',
  '4/21/34-A': 'Model',
  '4/21/34-M': 'Model',
  '4/21/34-SA': 'Model',
  '4/21/40': 'Model_Scale',
  '4/22': 'Model_Scale',
  '4/22/34': 'Model_Scale',
  '4/24': 'Model_Scale',
  '4/24/21': 'Model_Scale',
  '4/24/30.5': 'Model',
  '4/24/34': 'Model_Scale',
  '4/24/34-SAMT': 'Model',
  '4/30.5': 'Model',
  '43080EVO': 'Fretwire',
  '43MM': 'Body_Thickness',
  '45mmN': 'Neck_Shape_Nut',
  '47095SS': 'Fretwire',
  '47104EG': 'Fretwire',
  '47104EVO': 'Fretwire',
  '47104Evo': 'Fretwire',
  '47104SS': 'Fretwire',
  '4AFlameMapBind': 'Binding',
  '4AFlmMapBind': 'Binding',
  '4Lb 0': 'Weight',
  '4Lb 10': 'Weight',
  '4Lb 12': 'Weight',
  '4Lb 13': 'Weight',
  '4Lb 14': 'Weight',
  '4Lb 15': 'Weight',
  '4Lb 2': 'Weight',
  '4Lb 4': 'Weight',
  '4Lb 6': 'Weight',
  '4Lb 8': 'Weight',
  '4Lb 9': 'Weight',
  '4Lb ??': 'Weight',
  '4Lb': 'Weight',
  '4Lb12': 'Weight',
  '4Lb14': 'Weight',
  '4Lb4': 'Weight',
  '4Pots': 'Body_Feature',
  '4ST': 'Model_Submodel',
  '4St': 'Model_Submodel',
  '4lbs 10': 'Weight',
  '5/20': 'Model_Scale',
  '5/20/34': 'Model_Scale',
  '5/20/35': 'Model_Scale',
  '5/21': 'Model_Scale',
  '5/21-WLE': 'Model',
  '5/21/34': 'Model_Scale',
  '5/21/35': 'Model_Scale',
  '5/21/35-SA': 'Model',
  '5/22': 'Model_Scale',
  '5/22/34': 'Model_Scale',
  '5/22/34.5': 'Model',
  '5/22/35': 'Model_Scale',
  '5/24 SingleCut': 'Model',
  '5/24': 'Model_Scale',
  '5/24/23.5': 'Model',
  '5/24/34': 'Model_Scale',
  '5/24/34-35': 'Model',
  '5/24/34-SA': 'Model',
  '5/24/34.5': 'Model',
  '5/24/35': 'Model_Scale',
  '50s': 'Model',
  '51 Narrow 2.438NP': 'Body_Feature',
  '51108SS': 'Fretwire',
  '51BMH': 'Bridge_Modifier',
  '51Ctrl': 'Body_Feature',
  '51NP': 'Body_Feature',
  '51P FC': 'Body_Feature',
  '51P NP': 'Body_Feature',
  '51PCtrl': 'Body_Feature',
  '52C': 'Neck_Shape_Carve',
  '52V': 'Model_Submodel',
  '535i': 'Model',
  '54P': 'Model',
  '54mmNP': 'Body_Feature',
  '55090EG': 'Fretwire',
  '55090EVO': 'Fretwire',
  '55090NoFrets': 'Fretwire',
  '55090NoFretsSS': 'Fretwire',
  '55090SS': 'Fretwire',
  '57110EG': 'Fretwire',
  '57110NoFrets': 'Fretwire',
  '57110SS': 'Fretwire',
  '5A Flm/EBY': 'Wood',
  '5AFlameMap': 'Wood',
  '5AFlmMap': 'Wood',
  '5ANatBseMap/5ANatBseMap': 'Wood',
  '5ANatBseMap/EBY': 'Wood',
  '5ANatBseMap/IRW': 'Wood',
  '5ANatFlameMap': 'Wood',
  '5ANatFlameMap/EBY': 'Wood',
  '5ANatFlameMap/IRW': 'Wood',
  '5ANatFlameMap/Self': 'Wood',
  '5ANatFlmMap/EBY': 'Wood',
  '5ANatFlmMap/IRW': 'Wood',
  '5ANatFlmMap/Rich Lite': 'Wood',
  '5ARstFlame/5ARstFlame': 'Wood',
  '5ARstFlameMap/5ARstFlameMap': 'Wood',
  '5ARstFlameMap/EBY': 'Wood',
  '5ARstFlameMap/EBY/CS PH': 'Wood',
  '5ARstFlameMap/EBY/EBY PH': 'Wood',
  '5ARstFlameMap/EBY/EBY': 'Wood',
  '5ARstFlameMap/IRW': 'Wood',
  '5ARstFlameMap/IRW/EBY': 'Wood',
  '5ARstFlameMap/Self': 'Wood',
  '5Lb 0': 'Weight',
  '5Lb 2': 'Weight',
  '5Lb 4': 'Weight',
  '5Lb 8': 'Weight',
  '5Lb': 'Weight',
  '5ST': 'Model',
  '5St': 'Model',
  '5StAbaloneBlock/AbaloneS': 'Inlay',
  '5StAbaloneBlocks/AbaloneS': 'Inlay',
  '5W Switch': 'Body_Feature',
  '5Way': 'Body_Feature',
  '5WaySw': 'Body_Feature',
  '6.25-20CR': 'Neck_Shape_Radius',
  '6/21/34': 'Model_Scale',
  '6/24/34': 'Model_Scale',
  '6/24/34.5': 'Model',
  '60JJ': 'Pickups',
  '60s J/J': 'Pickups',
  '60s NO PU': 'Pickups',
  '60s P/J': 'Pickups',
  '60s': 'Model_Submodel',
  '60sJ/J': 'Pickups',
  '60sP/J': 'Pickups',
  '61VinNP': 'Body_Feature',
  '64NMH': 'Body_Feature',
  '69TLF Tele': 'Model',
  '6ARstFlameMap/Self': 'Wood',
  '6ST': 'Model',
  '6STrem': 'Model',
  '6St': 'Model',
  '7.25 -9.5CR': 'Neck_Shape_Radius',
  '7.25-10CR': 'Neck_Shape_Radius',
  '7.25-9.5CR': 'Neck_Shape_Radius',
  '7.25R': 'Neck_Shape_Radius',
  '7.5R': 'Neck_Shape_Radius',
  '70s J/J': 'Pickups',
  '70s P/J': 'Pickups',
  '70s': 'Model_Submodel',
  '70sJ/J': 'Pickups',
  '72T Custom': 'Model',
  '72T DLX': 'Model',
  '72T': 'Model',
  '760C': 'Neck_Shape_Carve',
  '770C': 'Neck_Shape_Carve',
  '785C': 'Neck_Shape_Carve',
  '790C': 'Neck_Shape_Carve',
  '795-940C': 'Neck_Shape_Carve',
  '795C': 'Neck_Shape_Carve',
  '79OC': 'Neck_Shape_Carve',
  '8/6ST': 'Model',
  '800-860C': 'Neck_Shape_Carve',
  '800C': 'Neck_Shape_Carve',
  '800D': 'Neck_Shape_Carve',
  '800SV': 'Neck_Shape_Carve',
  '805C': 'Neck_Shape_Carve',
  '810C': 'Neck_Shape_Carve',
  '810D': 'Neck_Shape_Carve',
  '810SV': 'Neck_Shape_Carve',
  '812C': 'Neck_Shape_Carve',
  '815C': 'Neck_Shape_Carve',
  '820C': 'Neck_Shape_Carve',
  '820SRV': 'Neck_Shape_Carve',
  '820U': 'Neck_Shape_Carve',
  '825C': 'Neck_Shape_Carve',
  '830-900C': 'Neck_Shape_Carve',
  '830-970C': 'Neck_Shape_Carve',
  '830/960C': 'Neck_Shape_Carve',
  '830C': 'Neck_Shape_Carve',
  '830C-970': 'Neck_Shape_Carve',
  '830SRV': 'Neck_Shape_Carve',
  '833C': 'Neck_Shape_Carve',
  '835C': 'Neck_Shape_Carve',
  '835D': 'Neck_Shape_Carve',
  '83SF': 'Neck_Shape_Carve',
  '840C': 'Neck_Shape_Carve',
  '840SRV': 'Neck_Shape_Carve',
  '845C': 'Neck_Shape_Carve',
  '845SV': 'Neck_Shape_Carve',
  '850C': 'Neck_Shape_Carve',
  '850SV': 'Neck_Shape_Carve',
  '855C': 'Neck_Shape_Carve',
  '855V': 'Neck_Shape_Carve',
  '860C': 'Neck_Shape_Carve',
  '860U': 'Neck_Shape_Carve',
  '865C': 'Neck_Shape_Carve',
  '870C': 'Neck_Shape_Carve',
  '870SV': 'Neck_Shape_Carve',
  '875C': 'Neck_Shape_Carve',
  '880-920C': 'Neck_Shape_Carve',
  '880-940C': 'Neck_Shape_Carve',
  '880C': 'Neck_Shape_Carve',
  '880D': 'Neck_Shape_Carve',
  '880SV': 'Neck_Shape_Carve',
  '885C': 'Neck_Shape_Carve',
  '890SV': 'Neck_Shape_Carve',
  '8St': 'Neck_Shape_Carve',
  '9.5 - 12R': 'Neck_Shape_Radius',
  '9.5-12C': 'Neck_Shape_Radius',
  '9.5-12CR': 'Neck_Shape_Radius',
  '9.5-12R': 'Neck_Shape_Radius',
  '9.5R': 'Neck_Shape_Radius',
  '900C': 'Neck_Shape_Carve',
  '900D': 'Neck_Shape_Carve',
  '900SV': 'Neck_Shape_Carve',
  '920C': 'Neck_Shape_Carve',
  '920SV': 'Neck_Shape_Carve',
  '930C': 'Neck_Shape_Carve',
  '930U': 'Neck_Shape_Carve',
  '940C': 'Neck_Shape_Carve',
  '950C': 'Neck_Shape_Carve',
  '960BT': 'Neck_Shape_Carve',
  '970C': 'Neck_Shape_Carve',
  '970D': 'Neck_Shape_Carve',
  '970SV': 'Neck_Shape_Carve',
  '980BT': 'Neck_Shape_Carve',
  '990BT': 'Neck_Shape_Carve',
  '9R': 'Neck_Shape_Radius',
  '??? F&S': 'Inlay',
  'ACT/BAT': 'Body_Feature',
  'ACT/BATT': 'Body_Feature',
  'ALL Tele Routs&Layout': 'Body_Feature',
  'AMSTD TeleBridge': 'Bridge_Modifier',
  'AbaloneBlock/AbaloneS': 'Inlay',
  'AbaloneBlock/BlackS': 'Inlay',
  'AbaloneBlock/WhiteS': 'Inlay',
  'AbaloneBlocks/AbaloneS': 'Inlay',
  'AbaloneF/2mmBlueLumiS': 'Inlay',
  'AbaloneF/AbaloneS': 'Inlay',
  'AbaloneF/BlackS': 'Inlay',
  'AbaloneF/WhiteS': 'Inlay',
  'Alder  XLite': 'Wood',
  'Alder -PG': 'Wood',
  'Alder 4Lb 10': 'Wood',
  'Alder 4Lb 12': 'Wood',
  'Alder 4Lb 14': 'Wood',
  'Alder Lite 3Lb 15': 'Wood',
  'Alder Lite 3Lb 4': 'Wood',
  'Alder Lite 4Lb 12': 'Wood',
  'Alder Lite 4Lb 4': 'Wood',
  'Alder Lite': 'Wood',
  'Alder Lite/3AFlameMap': 'Wood',
  'Alder Lite/3AQuiltMap': 'Wood',
  'Alder Lite/5AFlameMap': 'Wood',
  'Alder Lite/5AQuiltMap': 'Wood',
  'Alder Lite/5aSPLT': 'Wood',
  'Alder Lite/6AQuiltMap': 'Wood',
  'Alder Lite/Alder': 'Wood',
  'Alder Lite/BlackVen': 'Wood',
  'Alder Lite/BlackVen/Alder': 'Wood',
  'Alder Lite/BlackVen/CS THICK': 'Wood',
  'Alder Lite/BlackVen/CS': 'Wood',
  'Alder Lite/Burl Map': 'Wood',
  'Alder Lite/CS 3Lb 4': 'Wood',
  'Alder Lite/CS THICK': 'Wood',
  'Alder Lite/CS THIN': 'Wood',
  'Alder Lite/CS': 'Wood',
  'Alder Lite/CS/CS': 'Wood',
  'Alder Lite/Ven/5AFlameMap': 'Wood',
  'Alder Lite/Ven/5AQuiltMap': 'Wood',
  'Alder Lite/Ven/CS': 'Wood',
  'Alder XLite 4Lb 2': 'Wood',
  'Alder XLite': 'Wood',
  'Alder XLite/3AFlameMap': 'Wood',
  'Alder XLite/3AQuiltMap': 'Wood',
  'Alder XLite/5AFlameMap': 'Wood',
  'Alder XLite/5AQuiltMap': 'Wood',
  'Alder XLite/5ASpaltedMap': 'Wood',
  'Alder XLite/6AFlameMap': 'Wood',
  'Alder XLite/Alder XLite': 'Wood',
  'Alder XLite/Alder': 'Wood',
  'Alder XLite/CS': 'Wood',
  'Alder XLite/Flat Map': 'Wood',
  'Alder XLite/SpaltMap': 'Wood',
  'Alder XLite/Ven/5AFlameMap': 'Wood',
  'Alder XXLite': 'Wood',
  'Alder/3AFlameMap 0.125': 'Wood',
  'Alder/3AFlameMap': 'Wood',
  'Alder/3AQuiltMap': 'Wood',
  'Alder/5AFlameMap .375': 'Wood',
  'Alder/5AFlameMap': 'Wood',
  'Alder/5AMyrtlewood': 'Wood',
  'Alder/5AQuiltMap': 'Wood',
  'Alder/5ASpaltedMapedMap': 'Wood',
  'Alder/6AFlameMap': 'Wood',
  'Alder/Alder': 'Wood',
  'Alder/Ash': 'Wood',
  'Alder/BlackVen/Alder': 'Wood',
  'Alder/BlackVen/Ash': 'Wood',
  'Alder/BlackVen/CS': 'Wood',
  'Alder/CS': 'Wood',
  'Alder/Map A': 'Wood',
  'Alder/Map': 'Wood',
  'Alder/Ven/CS': 'Wood',
  'AlderXLite/3AQuiltMap': 'Wood',
  'AlderXLite/5AFlameMap': 'Wood',
  'AlderXLite/Alder Lite': 'Wood',
  'AlderXLite/Alder': 'Wood',
  'AlderXLite/BlackVen': 'Wood',
  'AlderXLite/BlackVen/CS': 'Wood',
  'AlderXLite/CS': 'Wood',
  'Ang-NP': 'Body_Feature',
  'Ash  Lite': 'Wood',
  'Ash  XLite/Ven/5AFlameMap': 'Wood',
  'Ash 3Lb 7': 'Wood',
  'Ash 4Lb 14': 'Wood',
  'Ash 4Lb 4': 'Wood',
  'Ash Lite 4Lb 10': 'Wood',
  'Ash Lite 4Lb 8': 'Wood',
  'Ash Lite': 'Wood',
  'Ash Lite/3AFlameMap': 'Wood',
  'Ash Lite/5AFlameMap': 'Wood',
  'Ash Lite/5AQuiltMap': 'Wood',
  'Ash Lite/Ash Lite': 'Wood',
  'Ash Lite/Ash': 'Wood',
  'Ash Lite/BlackVen/CS': 'Wood',
  'Ash Lite/CS': 'Wood',
  'Ash Lite/HVen/CS': 'Wood',
  'Ash Lite/SpaltMap': 'Wood',
  'Ash Lite/Ven/3AQuiltMap': 'Wood',
  'Ash Lite/Ven/5AFlameMap': 'Wood',
  'Ash Lite/Ven/5AQuiltMap': 'Wood',
  'Ash Lt': 'Wood',
  'Ash XLite /5AFlameMap': 'Wood',
  'Ash XLite 3Lb 12': 'Wood',
  'Ash XLite': 'Wood',
  'Ash XLite/3AFlameMap': 'Wood',
  'Ash XLite/3AQuiltMap': 'Wood',
  'Ash XLite/5AFlameMap': 'Wood',
  'Ash XLite/5AQuiltMap': 'Wood',
  'Ash XLite/Ash Lite': 'Wood',
  'Ash XLite/Ash XLite': 'Wood',
  'Ash XLite/Ash': 'Wood',
  'Ash XLite/Ash3Lb 2': 'Wood',
  'Ash XLite/Ash3Lb 4': 'Wood',
  'Ash XLite/BlackVen/Ash': 'Wood',
  'Ash XLite/BlackVen/CS': 'Wood',
  'Ash XLite/CS THICK': 'Wood',
  'Ash XLite/CS': 'Wood',
  'Ash XLite/CS/CS': 'Wood',
  'Ash XLite/HVen/CS': 'Wood',
  'Ash XLite/Map Quilt 5A': 'Wood',
  'Ash XLite/Spalted Map': 'Wood',
  'Ash XLite/Ven/5AFlameMap': 'Wood',
  'Ash XLite/Ven/5AQuiltMap 4Lb 8': 'Wood',
  'Ash XLite/Ven/5ASpaltedMap': 'Wood',
  'Ash XLite/Ven/CS': 'Wood',
  'Ash XLite/Ven/MG BseMap': 'Wood',
  'Ash XLite/Ven/Spalt': 'Wood',
  'Ash XXLite': 'Wood',
  'Ash Xlite': 'Wood',
  'Ash Xtra Lite': 'Wood',
  'Ash/ 5AQuiltMap': 'Wood',
  'Ash/3AFlameMap': 'Wood',
  'Ash/5AFlameMap': 'Wood',
  'Ash/5AQuiltMap - 4lb 0': 'Wood',
  'Ash/5AQuiltMap': 'Wood',
  'Ash/5ASpaltedMap': 'Wood',
  'Ash/5ASpaltedMapedMap': 'Wood',
  'Ash/6AFlameMap': 'Wood',
  'Ash/Ash': 'Wood',
  'Ash/Ash4Lb 0': 'Wood',
  'Ash/BlackVen/Ash': 'Wood',
  'Ash/BlackVen/CS': 'Wood',
  'Ash/CS': 'Wood',
  'Ash/Map': 'Wood',
  'Ash/Myrtlewood 5A': 'Wood',
  'Ash/SpltMap': 'Wood',
  'Ash2Pc Off': 'Wood',
  'Ash3Lb 12': 'Wood',
  'AshXLite/5AFlameMap': 'Wood',
  'AshXLite/Ash Lite': 'Wood',
  'AshXLite/Ash3Lb 0': 'Wood',
  'AshXLite/Ash3Lb 2': 'Wood',
  'AshXLite/BlackVen/CS': 'Wood',
  'AshXLite/CS': 'Wood',
  'AshXLite/MapQuilt MG': 'Wood',
  'B Tele': 'Model',
  'B.N. Spec': 'Model_Modifier',
  'B90/Dum': 'Pickups',
  'BA BMH': 'Bridge_Modifier',
  'Battery Covers': 'Body_Feature',
  'BASS-VI': 'Model',
  'BIGSBY B5': 'Bridge_Type',
  'Baretta H': 'Pickups',
  'Bass VI': 'Model',
  'Basswood XLite': 'Wood',
  'Basswood/6AQuiltMap': 'Wood',
  'Basswood/CS': 'Wood',
  'BiSonic/B90': 'Pickups',
  'Bigsby B5': 'Bridge_Type',
  'Bigsby B5/B16': 'Bridge_Type',
  'Bind Shelf': 'Binding',
  'Bindshelf F&B': 'Body_Feature',
  'Bindshelf F/B': 'Body_Feature',
  'Black/Black': 'Inlay',
  'BlackBind ': 'Binding',
  'BlackBlock& S': 'Inlay',
  'BlackBlock/2mmBlueLumiS': 'Inlay',
  'BlackBlock/3mmGreenLumiS': 'Inlay',
  'BlackBlock/BlackS': 'Inlay',
  'BlackBlock/IvoryS': 'Inlay',
  'BlackBlock/WhiteS': 'Inlay',
  'BlackBlocks/2mmBlueLumiS': 'Inlay',
  'BlackBlocks/3mmGreenLumiS': 'Inlay',
  'BlackBlocks/BlackS': 'Inlay',
  'BlackBlocks/IvoryS': 'Inlay',
  'BlackBlocks/WhiteS': 'Inlay',
  'BlackF/2mmBlueLumiS': 'Inlay',
  'BlackF/3mmBlueBlackLumiS': 'Inlay',
  'BlackF/3mmGreenBlackLumiS ': 'Inlay',
  'BlackF/3mmGreenBlackLumiS': 'Inlay',
  'BlackF/3mmGreenWhiteLumiS': 'Inlay',
  'BlackF/BlackS': 'Inlay',
  'BlackF/BlackWhiteLumiS': 'Inlay',
  'BlackF/GreenBlackS': 'Inlay',
  'BlackF/IvoryS': 'Inlay',
  'BlackF/NoS': 'Inlay',
  'BlackF/WhiteS': 'Inlay',
  'BlackLimba/3AFlmMap': 'Inlay',
  'BlackLimba/EBY': 'Inlay',
  'BlackLimba/IRW': 'Inlay',
  'BlackMopF/BlackMopS': 'Inlay',
  'BlackP S Only': 'Inlay',
  'BlackPl F/WhiteS': 'Inlay',
  'Blind Faith': 'Model_Submodel',
  'BlueLumiF/3mmBlueBlackLumiS': 'Inlay',
  'Bridge Wire Only': 'Body_Feature',
  'CALI-ST': 'Model',
  'CCH/CCH': 'Pickups',
  'CCH/CCH/S': 'Pickups',
  'CCH/S': 'Pickups',
  'CCHII/S': 'Pickups',
  'CCHII/TVJ': 'Pickups',
  'CE RC': 'Body_Feature',
  'CE RC/TopPots': 'Body_Feature',
  'CE-RC': 'Body_Feature',
  'CHATHAM P': 'Model',
  'CNC only': 'Finish_Option',
  'CS 1WH': 'Truss_Rod',
  'CS Blocks F/3mmBlueBlackLumiS': 'Inlay',
  'CS Blocks/3mmBlueWhiteLumiS': 'Inlay',
  'CS F Not @ 12/BlackS': 'Inlay',
  'CS Magnets': 'Body_Feature',
  'CS Oval F/3mmBlueBlackLumiS': 'Inlay',
  'CS Oval F/3mmBlueWhiteLumiS': 'Inlay',
  'CS Paulownia Core': 'Wood',
  'CS Roasted Pine ': 'Wood',
  'CS Roasted Pine': 'Wood',
  'CS/1A Map': 'Wood',
  'CS/1AMap': 'Wood',
  'CS/2AFlameMap': 'Wood',
  'CS/2ARstFlameMap/2ARstFlameMapPH': 'Wood',
  'CS/3AFlameMap': 'Wood',
  'CS/3ASpaltMap': 'Wood',
  'CS/5AFlameMap': 'Wood',
  'CS/6AFlameMap': 'Wood',
  'CS/BlackVen/CS': 'Inlay',
  'CS/BlackVen/CS/BlackVen/CS': 'Inlay',
  'CS/CS THICK': 'Wood',
  'CS/CS THIN': 'Wood',
  'CS/CS': 'Wood',
  'CS/CS/2ARstFlameMapPH': 'Wood',
  'CS/CS/CSPH': 'Wood',
  'CS/CS/EBY PH': 'Wood',
  'CS/CS/RstEWPine': 'Wood',
  'CS/EBY': 'Wood',
  'CS/EBY..': 'Wood',
  'CS/EBY/EBY PH': 'Wood',
  'CS/FSMap': 'Wood',
  'CS/FlatMapWhite': 'Wood',
  'CS/IRW': 'Wood',
  'CS/IRW/CS PH': 'Wood',
  'CS/IRW/EBY PH': 'Wood',
  'CS/IRW/IRW PH': 'Wood',
  'CS/MGQuiltMap': 'Wood',
  'CS/MGZircote/CS': 'Wood',
  'CS/Map': 'Wood',
  'CS/NatQSMap': 'Wood',
  'CS/RstQSMap': 'Wood',
  'CS/Self': 'Wood',
  'CS/Spalt/Flm Map': 'Wood',
  'CS/Spalt/FlameMap': 'Wood',
  'CS/Spalt&Flm Map': 'Wood',
  'CS/Spalted Map': 'Wood',
  'CSBlock F/3mmBlueBlackLumiS': 'Inlay',
  'CSBlock-No12th/3mmBlueBlackLumiS': 'Inlay',
  'CSBlock/3mmBlueBlackLumiS ': 'Inlay',
  'CSBlock/3mmBlueBlackLumiS': 'Inlay',
  'CSBlock/3mmBlueWhiteLumiS': 'Inlay',
  'CSBlock/BlackS': 'Inlay',
  'CSBlock/MopS': 'Inlay',
  'CSBlock/NeedDefS': 'Inlay',
  'CSBlocks F/3mmBlueBlackLumiS': 'Inlay',
  'CSBlocks/3mmBlueBlackLumiS': 'Inlay',
  'CSBlocks/3mmBlueWhiteLumiS': 'Inlay',
  'CSBlocks/BlackS': 'Inlay',
  'CSBlocks/MopS': 'Inlay',
  'CSBlocks/NeedDefS': 'Inlay',
  'CSF/BlackS': 'Inlay',
  'CSF/CSS': 'Inlay',
  'CSF/IvoryS': 'Inlay',
  'CSOvalF/3mmBlueBlackLumiS': 'Inlay',
  'CSOvals/3mmBlueBlackLumiS': 'Inlay',
  'CSWhiteKor/CSWhiteKor': 'Wood',
  'CUST/CUST': 'Wood',
  'CUSTOM MOD 2.062': 'Bridge_Type',
  'Chop T-Luxx': 'Model',
  'Clear Flat Map/ClearMap': 'Wood',
  'Coda 4 DLX J': 'Model',
  'Coda 4 P': 'Model',
  'Coda 5 DLX J': 'Model',
  'Coda 5': 'Model',
  'Coda 5XLite DLX J': 'Model',
  'Contour Heel': 'Body_Feature',
  'CreamF/CreamS': 'Inlay',
  'CreamF/WhiteS': 'Inlay',
  'Cust CtrlRout': 'Body_Feature',
  'Custom S/S/S': 'Pickups',
  'Custom3/8STH': 'Bridge_Modifier',
  'DAngelico SC': 'Model',
  'DBL CUT': 'Body_Feature',
  'DC-1': 'Model',
  'DLX J': 'Model',
  'DMH/DMH': 'Pickups',
  'DMH/S': 'Pickups',
  'DarkStar/DarkStar': 'Pickups',
  'Darkstar/Darkstar': 'Pickups',
  'DeArmTVJ/DeArmTVJ': 'Pickups',
  'DeArmTVJ/S': 'Pickups',
  'DeVilleCaster 24 Tele': 'Model',
  'DeVilleCaster Tele': 'Model',
  'Deep PUs': 'Pickups',
  'Del Mar': 'Model',
  'Deluxe JR': 'Model',
  'Deluxe/LP': 'Model',
  'DenseSwAsh/IRW': 'Wood',
  'DkStar/DkStar': 'Pickups',
  'DmS/S': 'Pickups',
  'Drop Wing': 'Model',
  'EMG-35/EMG-35': 'Pickups',
  'EMG-40/EMG-40': 'Pickups',
  'EMG-40/EMG-40-SST': 'Pickups',
  'EMG-40/EMG-MM5': 'Pickups',
  'EMG-40s': 'Pickups',
  'EMG-45/EMG-45': 'Pickups',
  'EMG-E5/EMG-E5/EMG-5': 'Pickups',
  'EMG-LJV/EMG-SJV': 'Pickups',
  'EMG-MM': 'Pickups',
  'EMG-SJV/EMG-MM': 'Pickups',
  'EMG35/EMG35': 'Pickups',
  'EMG40/EMG40': 'Pickups',
  'EMGS/EMGS/EMGS': 'Pickups',
  'EWPine Lite 4Lb 6': 'Wood',
  'EWPine Lite': 'Wood',
  'EWPine/EWPine': 'Wood',
  'EWPine/Map': 'Wood',
  'EWhitePine/EWhitePine': 'Wood',
  'F-Hole': 'Body_Construction_Modifier',
  FHole: 'Body_Construction_Modifier',
  'F-Jack': 'Body_Feature',
  'FAS HT': 'Bridge_Type',
  'FAmPro Bridge': 'Bridge_Type',
  'FB/FB': 'Pickups',
  'FC TL': 'Body_Feature',
  'FC&RC': 'Body_Feature',
  'FC/RC': 'Body_Feature',
  'FLM 3A/FLM 3A': 'Wood',
  'FLM/EBY': 'Wood',
  'FMH/H': 'Pickups',
  'FMH/S': 'Pickups',
  'FMH/S/S': 'Pickups',
  'FWD.20BMH/STH': 'Bridge_Modifier',
  'FWRH/S': 'Pickups',
  'Fender HT': 'Bridge_Type',
  'Flame 3A/Flame 3A': 'Wood',
  'Flat NP': 'Body_Feature',
  'Flat Neck Pocket': 'Body_Feature',
  'Flat Top': 'Construction_Top',
  'FlatRstMap/Self': 'Wood',
  'FlatS RstMap/CS': 'Wood',
  'Floyd Nut': 'Neck_Shape_Nut',
  'Fret Board - 24.75 - 15R - Slotted & Fiber OptiCS - CS': 'Neck_Composition',
  'Fret Board': 'Neck_Composition',
  'GB DoubleCut': 'Model',
  'GB SingleCut': 'Model',
  'GB-TFV-TAL': 'Model',
  'GF/GF': 'Pickups',
  'GF/GF/GF': 'Pickups',
  'GF/GF/S': 'Pickups',
  'GF/H': 'Pickups',
  'GF/S': 'Pickups',
  'GRESHAM J': 'Model',
  'GRESHAM J5': 'Model',
  'GTX-TAS-TL': 'Model',
  'Gen-5': 'Model',
  'Gen-5 RevC': 'Model',
  'GenMah Lite': 'Wood',
  'GenMah/3AFlameMap': 'Wood',
  'GenMah/3ANatBseMap': 'Wood',
  'GenMah/5AFlameMap .375': 'Wood',
  'GenMah/5AFlameMap': 'Wood',
  'GenMah/5AQuiltMap': 'Wood',
  'GenMah/CS': 'Wood',
  'GenMah/CS/CS PH': 'Wood',
  'GenMah/CS/CS': 'Wood',
  'GenMah/CS/EBY PH': 'Wood',
  'GenMah/EBY': 'Wood',
  'GenMah/EBY/EBY PH': 'Wood',
  'GenMah/GenMah': 'Wood',
  'GenMah/Hon RW': 'Wood',
  'GenMah/IRW': 'Wood',
  'GenMah/IRW/CS PH': 'Wood',
  'GenMah/IRW/CS PH-NoLogo': 'Wood',
  'GenMah/IRW/EBY PH': 'Wood',
  'GenMah/IRW/IRW PH': 'Wood',
  'GenMah/IRW/IRWPH': 'Wood',
  'GenMah/IRW/NoPHCapLogo': 'Wood',
  'GenMah/PauFerro': 'Wood',
  'GenMah/PlainMap': 'Wood',
  'GenMah/VinFlame': 'Wood',
  'GoldMopCrown/BlackS': 'Inlay',
  'GoldMopCrown/GoldMopS': 'Inlay',
  'Gotoh HT': 'Bridge_Type',
  'Graphite Rods': 'Neck_Feature',
  'GreenLumiF/3mmGreenBlackLumiS': 'Inlay',
  'GreenLumiF/3mmGreenWhiteLumiS': 'Inlay',
  'Grn Mah/CS': 'Wood',
  'Grove ST': 'Model',
  'H/DMH': 'Pickups',
  'H/DeepS': 'Pickups',
  'H/H Direct': 'Pickups',
  'H/H': 'Pickups',
  'H/H/H': 'Pickups',
  'H/H/S': 'Pickups',
  'H/P90/H': 'Pickups',
  'H/RevS': 'Pickups',
  'H/S': 'Pickups',
  'H/S/H': 'Pickups',
  'H/S/HCrvHorn': 'Pickups',
  'H/S/S': 'Pickups',
  'H/SS/S': 'Pickups',
  'H/SS/TS': 'Pickups',
  'H/TVJ': 'Pickups',
  'HB Bridge': 'Bridge_Type',
  'HDm/HDm': 'Pickups',
  'HDm/S': 'Pickups',
  'HDm/S/HDm': 'Pickups',
  'HS A Bridge': 'Bridge_Type',
  'HS Bridge': 'Bridge_Type',
  'HS TREM': 'Bridge_Type',
  'HS-A': 'Bridge_Type',
  'HS-ABMH': 'Bridge_Modifier',
  'HS-B': 'Bridge_Type',
  'HSH/UNI': 'Pickups',
  'HT CalVint': 'Bridge_Type',
  'HT F': 'Bridge_Type',
  'HT FAS': 'Bridge_Type',
  'HT FAS??': 'Bridge_Type',
  'HT GoToh': 'Bridge_Type',
  'HT Gotoh 5S/Mod2.062': 'Bridge_Type',
  'HT Gotoh': 'Bridge_Type',
  'HT Gotoh5S': 'Bridge_Type',
  'HT Gotoh5S/Mod2.062': 'Bridge_Type',
  'HT Gotoh5S??': 'Bridge_Type',
  'HT HS': 'Bridge_Type',
  'HT MJT2.167': 'Bridge_Type',
  'HT MJT2.187': 'Bridge_Type',
  'HT Mod2.062': 'Bridge_Type',
  'HT Schaller': 'Bridge_Type',
  'HT-FAS': 'Bridge_Type',
  'HT-HS': 'Bridge_Type',
  'Hd/Hd': 'Pickups',
  'Hf/Hf': 'Pickups',
  'Hf/S/Hf': 'Pickups',
  'HipShot 2P Trem': 'Bridge_Type',
  'HotRod Tele': 'Model',
  'IRW PHC': 'Wood',
  'IRW/IRW': 'Wood',
  'Ivory F/NO S': 'Inlay',
  'Ivory F/NoS': 'Inlay',
  'Ivory S': 'Inlay',
  'IvoryF/3mmBlueBlackLumiS': 'Inlay',
  'IvoryF/3mmBlueLumiS': 'Inlay',
  'IvoryF/3mmGreenBlachLumiS': 'Inlay',
  'IvoryF/3mmGreenBlackLumiS ': 'Inlay',
  'IvoryF/3mmGreenBlackLumiS': 'Inlay',
  'IvoryF/3mmGreenWhiteLumiS': 'Inlay',
  'IvoryF/BlackS': 'Inlay',
  'IvoryF/GreenBlackS': 'Inlay',
  'IvoryF/IvoryS': 'Inlay',
  'IvoryF/NoS': 'Inlay',
  'IvoryF/WhiteS': 'Inlay',
  'J 60s': 'Model',
  'J 70s': 'Model',
  'J AMENT': 'Model',
  'J NP': 'Body_Feature',
  'J-4 WLE': 'Model',
  'J-4': 'Model',
  'J-421': 'Model',
  'J-5 WLE': 'Model',
  'J-5': 'Model',
  'J-5/24/34-35': 'Model',
  'J-Bass': 'Model',
  'J/J': 'Pickups',
  'J/MM': 'Pickups',
  'J/MM/J': 'Pickups',
  'J/MM5': 'Pickups',
  'J/P/J': 'Pickups',
  'J5-20-35': 'Model',
  'JM BMH': 'Bridge_Modifier',
  'JM Bridge': 'Bridge_Type',
  'JM Bridge/Trem': 'Bridge_Type',
  'JM TREM': 'Bridge_Type',
  'JM Trem': 'Bridge_Type',
  'JM Trem/BMH': 'Bridge_Modifier',
  'JM-BMH': 'Bridge_Modifier',
  'JM/JM': 'Pickups',
  'JM/S': 'Pickups',
  'JM/S/S': 'Pickups',
  'JM/WRH': 'Pickups',
  'JMBMH.375/NoTrem': 'Bridge_Modifier',
  'JMBMH/NoTrem': 'Bridge_Modifier',
  'JMBMH/Trem': 'Bridge_Modifier',
  'JMTrem/JMBMH': 'Bridge_Modifier',
  'Jaguar 62': 'Model',
  'Jim D': 'Bridge_Type',
  'JimD 0.125': 'Bridge_Type',
  'JimD TOM': 'Bridge_Type',
  JoaquinD6: 'Model',
  'JoaquinD8/6': 'Model',
  'Junction J': 'Model',
  'K/S UNI Tuners': 'Neck_Feature',
  'K/S UNI': 'Neck_Feature',
  'KM6 NP': 'Body_Feature',
  'KhyMah Lite 4Lb 8': 'Wood',
  'KhyMah Lite': 'Wood',
  'KhyMah Lite/3AFlameMap': 'Wood',
  'KhyMah Lite/3AQuiltMap': 'Wood',
  'KhyMah Lite/3ASpaltMap': 'Wood',
  'KhyMah Lite/5AFlameMap': 'Wood',
  'KhyMah Lite/5AQuiltMap': 'Wood',
  'KhyMah Lite/6AQuiltMap': 'Wood',
  'KhyMah Lite/Ash': 'Wood',
  'KhyMah Lite/CS': 'Wood',
  'KhyMah Lite/KhyMah': 'Wood',
  'KhyMah Lite/PlainMap': 'Wood',
  'KhyMah Lite/SpaltMap': 'Wood',
  'KhyMah Lite/Ven/5AFlameMap +PH Cap': 'Wood',
  'KhyMah Lite/Ven/5AFlameMap': 'Wood',
  'KhyMah Lite/Ven/5AQuiltMap': 'Wood',
  'KhyMah XLite': 'Wood',
  'KhyMah/3AFlameMap': 'Wood',
  'KhyMah/3AQuiltMap': 'Wood',
  'KhyMah/5ABSEMap': 'Wood',
  'KhyMah/5AFlameMap': 'Wood',
  'KhyMah/5ANatFlameMap': 'Wood',
  'KhyMah/5AQuiltMap': 'Wood',
  'KhyMah/6AFlameMap': 'Wood',
  'KhyMah/Alder': 'Wood',
  'KhyMah/Ash': 'Wood',
  'KhyMah/BlackVen/CS': 'Wood',
  'KhyMah/CS': 'Wood',
  'KhyMah/EBY': 'Wood',
  'KhyMah/EBY/EBY  PH': 'Wood',
  'KhyMah/EBY/EBY PH': 'Wood',
  'KhyMah/IRW': 'Wood',
  'KhyMah/IRW/EBY PH': 'Wood',
  'KhyMah/IRW/IRW PH': 'Wood',
  'KhyMah/KhyMah 1Pc': 'Wood',
  'KhyMah/KhyMah': 'Wood',
  'KhyMah/MacEBY': 'Wood',
  'KhyMah/Map': 'Wood',
  'KhyMah/Need FB Definition/EBY': 'Wood',
  'KhyMah/PauFerro': 'Wood',
  'L-Bolt': 'Bridge_Type',
  'LB-Pots': 'Bridge_Type',
  'LCC/LCCS': 'Pickups',
  'LCC/P90': 'Pickups',
  'LEFTY 3PcTILT 2WH': 'Neck_Composition',
  'LEFTY PHCap': 'Body_Feature',
  'LEFTY WoodCover': 'Body_Feature',
  'LEO INLAY/BlackP S': 'Inlay',
  'LEO INLAY/BlackS': 'Inlay',
  'LF S': 'Pickups',
  'LG REV PH': 'Neck_Composition',
  'LINED FRETLESS': 'Fretwire',
  'LP Jr': 'Model',
  'LP/Jr': 'Model',
  'LP Jr. DC': 'Model',
  'LP Tele': 'Model',
  'LPJr DC': 'Model',
  'LS-13 LR': 'Model',
  'LS-13': 'Model',
  'Lap Steel': 'Model',
  'Large P90/S': 'Pickups',
  'Lined Fretless': 'Fretwire',
  'LollarGF/S': 'Pickups',
  'LollarH/S': 'Pickups',
  'Loose Frets&D': 'Fretwire',
  'Luxx Custom': 'Model',
  'Lydia Custom': 'Model',
  'Lynx DZ': 'Model',
  'M/J': 'Pickups',
  'MBrdge/NoTrem': 'Bridge_Type',
  'MGRstFlameMap/EBY/EBY PH': 'Wood',
  'MGRstFlameMap/Self': 'Wood',
  'MGRstFlm/CS/EBY PH': 'Wood',
  'MH/H': 'Pickups',
  'MH/MH': 'Pickups',
  'MH/MH/H': 'Pickups',
  'MH/MH/MH': 'Pickups',
  'MH/MH/S': 'Pickups',
  'MH/S': 'Pickups',
  'MH/S/S': 'Pickups',
  'MH/SS/S': 'Pickups',
  'MHB/MHB': 'Pickups',
  'MHB/S': 'Pickups',
  'MJT2.187': 'Bridge_Type',
  'Mach II': 'Model',
  'Mach II-JZ': 'Model',
  'Macon JR': 'Model',
  'Macon Jr DC': 'Model',
  'Macon Jr SC': 'Model',
  'Macon SC Special': 'Model',
  'Macon SC': 'Model',
  'Macon Vintage': 'Model',
  'Mann 2P Trem': 'Bridge_Type',
  'Map/3A FLM': 'Wood',
  'Map/3ANatBseMap': 'Wood',
  'Map/3ANatFlameMap': 'Wood',
  'Map/5ANatBseMap': 'Wood',
  'Map/CS': 'Wood',
  'Map/CS-Wenge': 'Wood',
  'Map/EBY': 'Wood',
  'Map/FLM 3A': 'Wood',
  'Map/Flame 3A': 'Wood',
  'Map/Hon RW': 'Wood',
  'Map/IRW': 'Wood',
  'Map/MacEBY': 'Wood',
  'Map/Mad RW': 'Wood',
  'Map/Map': 'Wood',
  'Map/Mapl': 'Wood',
  'Map/PauFerro': 'Wood',
  'Map/Self': 'Wood',
  'Melody Maker': 'Model',
  'Model-T Tele': 'Model',
  'MopBlock/2mmBlueLumiS': 'Inlay',
  'MopBlock/3mmBlueBlackLumiS': 'Inlay',
  'MopBlock/BlackS': 'Inlay',
  'MopBlock/MopS': 'Inlay',
  'MopBlock/WhiteS': 'Inlay',
  'MopBlocks/2mmBlueLumiS': 'Inlay',
  'MopBlocks/3mmBlueBlackLumiS': 'Inlay',
  'MopBlocks/BlackS': 'Inlay',
  'MopBlocks/MopS': 'Inlay',
  'MopBlocks/WhiteS': 'Inlay',
  'MopBlocksF/MopS': 'Inlay',
  'MopCrown/BlackS': 'Inlay',
  'MopCrown/NoS': 'Inlay',
  'MopF/2mmBlueLumiS': 'Inlay',
  'MopF/3mmBlueBlackLumiS': 'Inlay',
  'MopF/3mmBlueWhiteLumiS': 'Inlay',
  'MopF/3mmGreenWhiteLumiS': 'Inlay',
  'MopF/BlackS': 'Inlay',
  'MopF/MopS': 'Inlay',
  'MopF/WhiteS': 'Inlay',
  'MopF/WhitelS': 'Inlay',
  'NAshVILLE S/S/H': 'Pickups',
  'Nashville S/S/H': 'Pickups',
  'NO  FACE/WhiteS': 'Inlay',
  'NO CRVE': 'Body_Feature',
  'NO Controls Holes': 'Body_Feature',
  'NO F-HOLE': 'Body_Feature',
  'NO F-Hole': 'Body_Feature',
  'NO F/WhiteS': 'Inlay',
  'NO FC': 'Body_Feature',
  'NO FHOLE': 'Body_Feature',
  'NO MACHINE RELIEF': 'Neck_Feature',
  'NO PU': 'Pickups',
  'NO PUs': 'Pickups',
  'NO R/O': 'Body_Feature',
  'NO RO': 'Body_Feature',
  'NO STH': 'Bridge_Modifier',
  'NO Step': 'Body_Feature',
  'NO WCrv': 'Body_Feature',
  'NOF/IvoryS': 'Inlay',
  'NP & FC Only': 'Body_Feature',
  'NP & FHole ONLY': 'Body_Feature',
  'NS 4/5St': 'Model',
  'NYC-G1': 'Model',
  'NYC-G2': 'Model',
  'NYLN-BRDWY': 'Model',
  'NYLN-TELE': 'Model',
  'NatQSMap/CS': 'Wood',
  'NatQSMap/IRW': 'Wood',
  'NatQSMap/IRW/BlackComp': 'Wood',
  'NatQSMap/PauFerro/BlackComp': 'Wood',
  'Neck Plate': 'Body_Feature',
  'No B': 'Body_Feature',
  'No Batt': 'Body_Feature',
  'No F-Hole': 'Body_Feature',
  'No F/3mmBlueBlackLumiS': 'Inlay',
  'No F/3mmBlueWhiteLumiS': 'Inlay',
  'No FRT': 'Neck_Feature',
  'No Ferrule Recess': 'Body_Feature',
  'No Neck Holes': 'Body_Feature',
  'No NeckPocket': 'Body_Feature',
  'No PU': 'Pickups',
  'No RO': 'Body_Feature',
  'No Sanding': 'Body_Feature',
  'No SideJack': 'Body_Feature',
  'No Stop Tail Holes': 'Body_Feature',
  'NoF hole': 'Body_Feature',
  'NoF/2mmBlueLumiS': 'Inlay',
  'NoF/3mmBlueBlackLumiS': 'Inlay',
  'NoF/3mmBlueLumiS': 'Inlay',
  'NoF/3mmGreenLumiS': 'Inlay',
  'NoF/3mmGreenWhiteLumiS': 'Inlay',
  'NoF/BlackS': 'Inlay',
  'NoF/CSS': 'Inlay',
  'NoF/IvoryS': 'Inlay',
  'NoF/MopS': 'Inlay',
  'NoF/NoS': 'Inlay',
  'NoF/PearloidS': 'Inlay',
  'NoF/PocketS': 'Inlay',
  'NoF/WhiteS': 'Inlay',
  'NoFrets 47095': 'Fretwire',
  'NoPU/60s J': 'Pickups',
  'NoNPU/60s J': 'Pickups',
  'NoNPU/S': 'Pickups',
  'NoPU/P90': 'Pickups',
  'NoPU/S': 'Pickups',
  'NoPocket/PocketS': 'Inlay',
  'NoR/O': 'Body_Feature',
  'NoTopR/O': 'Body_Feature',
  'NovakMH/S': 'Pickups',
  'Nylon-Tele': 'Model',
  'OGRW Lite': 'Wood',
  'OGRW/Alder/CS': 'Wood',
  'OGRW/CS': 'Wood',
  'OGRW/CS/CS': 'Wood',
  'OGRW/OGRW': 'Wood',
  'OS REV PH': 'Model',
  'OS STD PH': 'Model',
  'Off-Standard': 'Wood_Feature',
  'Oko/Oko': 'Wood',
  'Okoume/BlackVen/CS': 'Wood',
  'Okoume/BlackVen/Okoume': 'Wood',
  'Okoume/CS': 'Wood',
  'Okoume/Okoume': 'Wood',
  'Okume/CS': 'Wood',
  'Olinto J4': 'Model',
  'Olinto P4': 'Model',
  'Original MJ': 'Model',
  'Oz Cabronita': 'Model',
  'P BB': 'Model',
  'P Bass': 'Model',
  'P Bridge': 'Bridge_Type',
  'P NP': 'Body_Feature',
  'P-4': 'Model',
  'P-5': 'Model',
  'P-5/24/34-35': 'Model',
  'P-ACrv': 'Body_Feature',
  'P-Bass': 'Model',
  'P/DC': 'Pickups',
  'P/DualCoil': 'Pickups',
  'P/EMG-LJV': 'Pickups',
  'P/J': 'Pickups',
  'P/NDC': 'Pickups',
  'P/RevP': 'Pickups',
  'P/SB': 'Pickups',
  'P/T': 'Pickups',
  'P/TVJ': 'Pickups',
  'P4 LG': 'Model',
  'P52 TeleBass': 'Model',
  'P54 BMH': 'Bridge_Modifier',
  'P90/DMH': 'Pickups',
  'P90/FmH': 'Pickups',
  'P90/H': 'Pickups',
  'P90/Hf': 'Pickups',
  'P90/JM': 'Pickups',
  'P90/MH': 'Pickups',
  'P90/MHB': 'Pickups',
  'P90/NearBridgeP90': 'Pickups',
  'P90/P90': 'Pickups',
  'P90/P90/P90': 'Pickups',
  'P90/P90/S': 'Pickups',
  'P90/RevS': 'Pickups',
  'P90/S': 'Pickups',
  'P90/S/S': 'Pickups',
  'P90/SS/S': 'Pickups',
  'P90/TVJ': 'Pickups',
  'P90/TightP90': 'Pickups',
  'P90HX/P90HX': 'Pickups',
  'P90Metal/H': 'Pickups',
  'P90Plastic/H': 'Pickups',
  'PH Cap': 'Body_Feature',
  PRSHeel: 'Neck_Feature',
  'Pearloid  Block': 'Inlay',
  'Pearloid F/BlackS': 'Inlay',
  'Pearloid F/WhiteS': 'Inlay',
  'PearloidBlock & S': 'Inlay',
  'PearloidBlock& S': 'Inlay',
  'PearloidBlock/BlackPl S': 'Inlay',
  'PearloidBlock/BlackS': 'Inlay',
  'PearloidBlock/Ivory S': 'Inlay',
  'PearloidBlock/MopS': 'Inlay',
  'PearloidBlock/PearloidS': 'Inlay',
  'PearloidBlock/WhiteS': 'Inlay',
  'PearloidBlocks & S': 'Inlay',
  'PearloidBlocks& S': 'Inlay',
  'PearloidBlocks/BlackPl S': 'Inlay',
  'PearloidBlocks/BlackS': 'Inlay',
  'PearloidBlocks/Ivory S': 'Inlay',
  'PearloidBlocks/MopS': 'Inlay',
  'PearloidBlocks/PearloidS': 'Inlay',
  'PearloidBlocks/WhiteS': 'Inlay',
  'PearloidF/PearloidS': 'Inlay',
  'PearloidF/WhiteS': 'Inlay',
  'Peter Buck': 'Model_Submodel',
  'Pine Lite': 'Wood',
  'PocketBlock/2.5mmGreenLumiS': 'Inlay',
  'PocketBlock/3mmGreenBlackLumiS': 'Inlay',
  'PocketBlock/NoS': 'Inlay',
  'PocketBlock/PocketS': 'Inlay',
  'PocketBlocks/2.5mmGreenLumiS': 'Inlay',
  'PocketBlocks/3mmGreenBlackLumiS': 'Inlay',
  'PocketBlocks/PocketS': 'Inlay',
  'PocketCSFrame/PocketS': 'Inlay',
  'PocketCrown/PocketS': 'Inlay',
  'PocketCrowns/PocketS': 'Inlay',
  'PocketDots/PocketS': 'Inlay',
  'PocketF/3mmGreenWhiteLumiS': 'Inlay',
  'PocketF/PocketS Only': 'Inlay',
  'PocketF/PocketS': 'Inlay',
  'PocketF/WhiteS': 'Inlay',
  'PocketFrame/PocketS': 'Inlay',
  'PocketLeaves/PocketS': 'Inlay',
  'PocketMopCrown/NoS': 'Inlay',
  'PocketOpenFrame/PocketS': 'Inlay',
  'Pocketed Slots': 'Neck_Feature',
  'QS Map/IRW': 'Wood',
  'QS/CS': 'Wood',
  'QSGenMah/CS': 'Wood',
  'QSMap/3ANatBseMap': 'Wood',
  'QSMap/3ARstFlameMap': 'Wood',
  'QSMap/5ANatBseMap': 'Wood',
  'QSMap/CS': 'Wood',
  'QSMap/EBY': 'Wood',
  'QSMap/FSMap': 'Wood',
  'QSMap/IRW': 'Wood',
  'QSMap/Map': 'Wood',
  'QSMap/Morado': 'Wood',
  'QSMap/PauFerro': 'Wood',
  'QSMap/QSMap': 'Wood',
  'QSMap/Self': 'Wood',
  'QSMap/Wenge': 'Wood',
  'QSNatMap/EBY': 'Wood',
  'QSRstFlameMap/IRW': 'Wood',
  'QSRstMap/IRW': 'Wood',
  'R Bat': 'Body_Feature',
  'RC Cover': 'Body_Feature',
  'REV PH': 'Neck_Composition',
  'Rainmaker II': 'Model',
  'Rainmaker II-5St': 'Model',
  'Rear Ctrl': 'Body_Feature',
  'Rec Floyd': 'Bridge_Type',
  'RecessFloyd NF': 'Bridge_Type',
  'Recessed Floyd': 'Bridge_Type',
  'Rev PH': 'Neck_Composition',
  'RevP/RevP': 'Pickups',
  'Roasted/Roasted': 'Wood',
  'Rst Flame 3A/Self': 'Wood',
  'Rst Flame 5A/Self': 'Wood',
  'Rst. Alder': 'Wood',
  'Rst3AFlameMap/Self': 'Wood',
  'Rst5AFlameMap/Self': 'Wood',
  'Rst5ANatFlameMap/Self': 'Wood',
  'RstAlder Lite': 'Wood',
  'RstAlder Lite/BlackVen/CS': 'Wood',
  'RstAlder/BlackVen/CS': 'Wood',
  'RstAlder/RstAlder': 'Wood',
  'RstAsh /5AFlameMap': 'Wood',
  'RstAsh Lite': 'Wood',
  'RstAsh Lite/BlackVen/CS': 'Wood',
  'RstAsh Lite/Ven/CS': 'Wood',
  'RstAsh XLite/5AFlameMap': 'Wood',
  'RstAsh XLite/CS': 'Wood',
  'RstAsh/4AMapFlm': 'Wood',
  'RstAsh/5AFlameMap': 'Wood',
  'RstAsh/5AMyrtlewood': 'Wood',
  'RstAsh/5AQuiltMap': 'Wood',
  'RstAsh/6AFlameMap': 'Wood',
  'RstAsh/6AFlmMap': 'Wood',
  'RstAsh/BlackVen/CS': 'Wood',
  'RstAsh/BlackVen/RstAsh': 'Wood',
  'RstAsh/CS': 'Wood',
  'RstAsh/Map FS-4lb 0': 'Wood',
  'RstAsh/Myrtlewood 5A': 'Wood',
  'RstAsh/RstAsh': 'Wood',
  'RstAsh/RstAsh3Lb 2': 'Wood',
  'RstAsh/Ven/CS': 'Wood',
  'RstAsh4Lb 4': 'Wood',
  'RstAshXLite /5AFlameMap': 'Wood',
  'RstAshXLite /6AFlameMap': 'Wood',
  'RstEWPine 4Lb 2': 'Wood',
  'RstEWPine/3AFlameMap': 'Wood',
  'RstEWPine/CS': 'Wood',
  'RstEWPine/CS/CS': 'Wood',
  'RstEWPine/RstEWPine': 'Wood',
  'RstFlatMap/3ARstBseMap': 'Wood',
  'RstFlatMap/5ARstBseMap': 'Wood',
  'RstFlatMap/5ARstFlameMap': 'Wood',
  'RstFlatMap/EBY': 'Wood',
  'RstFlatMap/IRW': 'Wood',
  'RstFlatMap/RstFlatMap': 'Wood',
  'RstFlatMap/Self': 'Wood',
  'RstMap/3ARstBseMap': 'Wood',
  'RstPine 3Lb 5': 'Wood',
  'RstPine/RstPine': 'Wood',
  'RstQSMap/CS': 'Wood',
  'RstQSMap/EBY': 'Wood',
  'RstQSMap/IRW': 'Wood',
  'RstQSMap/Map': 'Wood',
  'RstQSMap/Morado': 'Wood',
  'RstQSMap/PauFerro': 'Wood',
  'RstQSMap/RstQSMap': 'Wood',
  'RstQSMap/Self': 'Wood',
  'RstQSMaple/Self': 'Wood',
  'RstSugarPine/3AFlameMap': 'Wood',
  'RvrsP/J': 'Pickups',
  'S&B': 'Neck_Feature',
  'S/AngledP90': 'Pickups',
  'S/BGS': 'Pickups',
  'S/DeepS': 'Pickups',
  'S/GotohH': 'Pickups',
  'S/H': 'Pickups',
  'S/H/H': 'Pickups',
  'S/H/H-CUSTOM': 'Pickups',
  'S/HDm': 'Pickups',
  'S/Hf': 'Pickups',
  'S/J': 'Pickups',
  'S/MH': 'Pickups',
  'S/NoPU': 'Pickups',
  'S/P90': 'Pickups',
  'S/Rev S': 'Pickups',
  'S/RevS': 'Pickups',
  'S/S': 'Pickups',
  'S/S/AngleH': 'Pickups',
  'S/S/H': 'Pickups',
  'S/S/HDm': 'Pickups',
  'S/S/Hd': 'Pickups',
  'S/S/Hf': 'Pickups',
  'S/S/RevS': 'Pickups',
  'S/S/S': 'Pickups',
  'S/S/TeleS': 'Pickups',
  'S/S/U': 'Pickups',
  'S/S/UNI': 'Pickups',
  'S/TVJ': 'Pickups',
  'S/WRH': 'Pickups',
  'SATIN 2024': 'Model',
  'SB-4': 'Model',
  'SB-Jazzmaster': 'Model',
  'SB/SB': 'Pickups',
  'SDMH/S': 'Pickups',
  'SDMH/S/S': 'Pickups',
  'SDm/SDm/HDm': 'Pickups',
  'SEMI-Hollow': 'Model',
  'SF/SF/SF': 'Pickups',
  'SG - Speed Guide': 'Model',
  'SJ/LJ': 'Pickups',
  'SK&B': 'Neck_Feature',
  'SKY-HAS': 'Model',
  'SKY-TAL': 'Model',
  'SOR-BB': 'Model',
  'SOR-BD': 'Model',
  'SS-13': 'Model',
  'SS/H': 'Pickups',
  'SS/S': 'Pickups',
  'SS/SS/H': 'Pickups',
  'SS/SS/S': 'Pickups',
  'SS/SS/SS': 'Pickups',
  'SS/SS/TS': 'Pickups',
  'STRAT HEEL': 'Neck_Feature',
  'STRAT VIN': 'Model',
  'STRATHAM 57': 'Model',
  'SanB1 KM-6': 'Model',
  'SanB2 KM-6': 'Model',
  'Semi-Hollow': 'Model',
  'ShortJ/ShortJ': 'Pickups',
  'Side Dots Only': 'Inlay',
  'Side Jack': 'Body_Feature',
  'Silvertone/Silvertone': 'Pickups',
  'Sk&B': 'Neck_Feature',
  'Sk/B': 'Neck_Feature',
  'Skunk&Bee': 'Neck_Feature',
  'Slanted H': 'Pickups',
  'Slotted ONLY': 'Fretwire',
  'Solar Arrow': 'Model',
  'Solar Flight': 'Model',
  'Solar Sonic': 'Model',
  'SolarArrow-NP': 'Model',
  'Springfield Strat': 'Model',
  'StMac 154': 'Fretwire',
  'Starcaster TLF': 'Model',
  'Star Pockets': 'Inlay',
  'StlDeV TiltB': 'Model',
  'StlPhonic TiltB': 'Model',
  'StlTop Deluxe': 'Model',
  'StlTop TL': 'Model',
  'Strat 50s': 'Model',
  'Strat 57': 'Model',
  'Strat 60s': 'Model',
  'Strat 62': 'Model',
  'Strat 96': 'Model',
  'Strat Heel': 'Neck_Feature',
  'Strat NP': 'Body_Feature',
  'Strat Trem': 'Bridge_Type',
  'Strat Vin': 'Model',
  'StratS/S': 'Pickups',
  'SugarPine Lite': 'Wood',
  'SugarPine/SugarPine': 'Wood',
  'Super Strat': 'Model',
  'T-Rod': 'Model',
  'T-Strat': 'Model',
  'T/S': 'Model',
  'T4/T4': 'Model',
  'T4/T4T': 'Model',
  'T5-NR': 'Model',
  'T5/T5': 'Model',
  'T72 DLX': 'Model',
  'T72 Deluxe': 'Model',
  'TB/TB': 'Pickups',
  'TCM-BB': 'Model',
  'TCP-BD': 'Model',
  'TELE 60s': 'Model_Submodel',
  'TELHAM 52T': 'Model',
  'TEU Ash XLite': 'Model',
  'TEU-LH': 'Model',
  'TKS/S': 'Pickups',
  'TL 70s Hybrid': 'Model',
  'TL 70s': 'Model',
  'TL Ctrl': 'Model',
  'TL DLX FH': 'Model',
  'TL DLX NoFH': 'Model',
  'TL FH': 'Model',
  'TL Hybrid70s': 'Model',
  'TL NoFH': 'Model',
  'TL Strat': 'Model',
  'TL Tele': 'Model',
  'TOM HT': 'Bridge_Type',
  'TOP & BACK .060 Bindshelf': 'Binding',
  'TVJ/H': 'Pickups',
  'TVJ/RevS': 'Pickups',
  'TVJ/S': 'Pickups',
  'TVJ/TVJ': 'Pickups',
  'TVJ/TVJ/TVJ': 'Pickups',
  'TVJ/WRH': 'Pickups',
  'TbdF/TbdS': 'Inlay',
  'Tele 3DEG': 'Model_Submodel',
  'Tele 60s': 'Model_Submodel',
  'Tele 70s Hybrid': 'Model_Submodel',
  'Tele Esq': 'Model',
  'Tele FC': 'Body_Feature',
  'Tele H/S': 'Pickups',
  'Tele LEFTY': 'Model',
  'Tele NP': 'Body_Feature',
  'Tele Paul': 'Model',
  'Tele S/H': 'Pickups',
  'Tele S/S': 'Pickups',
  'Tele TL': 'Model',
  'Tele UniMd/S': 'Pickups',
  'Tele Vin': 'Model',
  'Tele-DLX': 'Model',
  'TeleBMH/STH': 'Bridge_Modifier',
  'TeleS/H': 'Pickups',
  'TeleS/NoPu': 'Pickups',
  'Texola TeleM': 'Model',
  'Top&Ven': 'Model_Modifier',
  'Top/Ven': 'Model_Modifier',
  'Trem2.062': 'Bridge_Type',
  'Truxton Tele': 'Model',
  'Truxton-B Tele': 'Model',
  'Truxton-L Tele': 'Model',
  'Truxton-SP Tele': 'Model',
  'Truxton-TL Tele': 'Model',
  'U/S': 'Pickups',
  'U/SS/S': 'Pickups',
  'UNI/H': 'Pickups',
  'UNI/J': 'Pickups',
  'UNI/S': 'Pickups',
  'UNI/S/S': 'Pickups',
  'UNI/S/UNI': 'Pickups',
  'UNI/TVJ': 'Pickups',
  'UNISm/S': 'Pickups',
  'UNIWRH/WRH': 'Pickups',
  'Uni-Sm': 'Pickups',
  'Uni/S': 'Pickups',
  'UniMd/S': 'Pickups',
  'UniSm/S': 'Pickups',
  'UniSm/S/S': 'Pickups',
  'V-Trem': 'Bridge_Type',
  'V52 Snakehead': 'Model',
  'Vin 1Pc': 'Neck_Composition',
  'Vin 2Pc': 'Neck_Composition',
  'Vin Tele STH': 'Bridge_Modifier',
  'Vin Tele': 'Neck_Composition',
  'W/C': 'Body_Feature',
  'WRH/P90': 'Pickups',
  'WRH/S': 'Pickups',
  'WRH/S/S': 'Pickups',
  'WRH/S/WRH': 'Pickups',
  'WRH/SS/S': 'Pickups',
  'WRH/WRH': 'Pickups',
  'WRHB/WRHB': 'Pickups',
  'WW-1WH': 'Truss_Rod',
  'White Korina': 'Wood',
  'WhiteF/WhiteS': 'Inlay',
  'WhiteKorina/IRW': 'Wood',
  'WhiteKorina/PauFerro': 'Wood',
  'WhiteMopF/BlackS': 'Inlay',
  'WhiteMopF/MopS': 'Inlay',
  'Whitel F/WhiteS': 'Inlay',
  'Will Lee': 'Model',
  'WireRout TremToCtrl': 'Body_Feature',
  'Wood Cover': 'Body_Feature',
  'XS DINKY': 'Model',
  'XSC DINKY': 'Model',
  'XSCREV PH': 'Model',
  'Xtra Top Ctrl': 'Body_Feature',
  // '2Pc Off': 'Wood_Feature',
  // '2Pc': 'Wood_Feature',
  0.125: 'Body_Feature',
  0.16: 'Body_Feature',
  0.187: 'Body_Feature',
  0.23: 'Body_Feature',
  1.3125: 'Body_Thickness',
  1.375: 'Body_Thickness',
  1.625: 'Body_Thickness',
  1.65: 'Body_Thickness',
  1.67: 'Body_Thickness',
  1.6875: 'Body_Thickness',
  1.687: 'Body_Thickness',
  1.73: 'Body_Thickness',
  1.75: 'Body_Thickness',
  1.835: 'Body_Thickness',
  1.95: 'Body_Thickness',
  234.5: 'Model_Scale',
  24.75: 'Model_Scale',
  24: 'Model_Scale',
  25.5: 'Model_Scale',
  30.5: 'Model_Scale',
  30: 'Model_Scale',
  32: 'Model_Scale',
  34: 'Model_Scale',
  43080: 'Fretwire',
  45100: 'Fretwire',
  47095: 'Fretwire',
  47104: 'Fretwire',
  47905: 'Fretwire',
  51108: 'Fretwire',
  52: 'Model',
  55090: 'Fretwire',
  55110: 'Fretwire',
  57110: 'Fretwire',
  57: 'Model',
  672: 'Model_Submodel',
  71: 'Model',
  9.5: 'Neck_Shape_Radius',
  920: 'Neck_Shape_Carve',
  950: 'Neck_Shape_Carve',
  96: 'Model',
  '2FHole': 'Model_Submodel',
  AB: 'Body_Feature',
  ACrv: 'Body_Feature',
  Alder: 'Wood',
  AlderCS: 'Wood',
  AlderXLite: 'Wood',
  AllCarves: 'Body_Feature',
  AllSTH: 'Bridge_Modifier',
  AngleH: 'Pickups',
  AngledH: 'Pickups',
  AngledNP: 'Body_Feature',
  AnyPUs: 'Pickups',
  ArmB: 'Body_Feature',
  Armitage: 'Model',
  Ash: 'Wood',
  AshXLite: 'Wood',
  AshXXLite: 'Wood',
  BADASS: 'Bridge_Type',
  BAT: 'Body_Feature',
  BATT: 'Body_Feature',
  BB: 'Archetype',
  BBMF5: 'Model',
  BBMF: 'Model',
  BC: '',
  BGSP: 'Bridge_Type',
  BIGSBY: 'Bridge_Type',
  BIND: 'Binding',
  BMH: 'Bridge_Modifier',
  BN: 'Archetype',
  BT140: 'Body_Feature',
  BT1875: 'Body_Feature',
  BT: 'Body_Feature',
  Babicz: 'Model',
  Basswood: 'Wood',
  Bat: 'Body_Feature',
  Batt: 'Body_Feature',
  BiSonic: 'Pickups',
  Bigsby: 'Bridge_Type',
  Bind: 'Binding',
  BindNeedDef: 'Binding',
  BindShelf: 'Binding',
  Bindshelf: 'Binding',
  BlackBind: 'Binding',
  BlackBlock: 'Inlay',
  BlackS: 'Inlay',
  Brawler: 'Model',
  BrawlerSC: 'Model',
  BrazRW: 'Wood',
  BridgeGnd: 'Body_Feature',
  CALI: 'Model',
  CBS: 'Model',
  CCS: 'Pickups',
  CE2: 'Model',
  CE: 'Model',
  CL: 'Wood_Feature',
  CLT: 'Wood_Feature',
  CNC: 'Finish_Option',
  COPY: '',
  CP: 'Archetype',
  CS1WH: 'Truss_Rod',
  CS47104EG: 'Fretwire',
  CS57110EG: 'Fretwire',
  CS: 'Wood',
  CSBind: 'Binding',
  CSBlock: 'Inlay',
  CSFW: 'Fretwire',
  CSFret: 'Fretwire',
  CSGraph: 'Neck_Feature',
  CT: 'Construction_Top',
  Cabronita: 'Model',
  Calavera: 'Model',
  CallahamFerrules: 'Body_Feature',
  Chambered: 'Body_Construction_Modifier',
  Chatham: 'Model',
  Chubby: 'Model',
  Cornerstone: 'Model_Modifier',
  Cougar: 'Model',
  CreamBind: 'Binding',
  CrvHeel: 'Body_Feature',
  CrvTop: 'Body_Construction_Modifier',
  CustNP: 'Body_Feature',
  CustomP: 'Pickups',
  DC: 'Model',
  DINKY: 'Model',
  DLX: 'Model',
  DS1: '',
  DT: 'Construction_Top',
  Darkstar: 'Pickups',
  DblCutaway: 'Body_Feature',
  DblGraph: 'Neck_Feature',
  DeepS: 'Pickups',
  Dinky: 'Model',
  DoubleCut: 'Model_Submodel',
  DoubleNeck: 'Body_Feature',
  DuoSonic: 'Model',
  ELL: '',
  EMGMM: 'Pickups',
  EWPine: 'Wood',
  Ebony: 'Wood',
  EdgeBevels: 'Neck_Feature',
  Esquire: 'Model',
  FC: 'Body_Feature',
  FCOnly: 'Body_Feature',
  FHT: 'Bridge_Type',
  FHoleOnly: 'Body_Feature',
  FJ: 'Model',
  FJack: 'Body_Feature',
  FLAT: 'Construction_Top',
  FLOYD: 'Neck_Shape_Nut',
  FRETLESS: 'Fretwire',
  FRTLESS: 'Fretwire',
  FRTLSS: 'Fretwire',
  FT: 'Body_Feature',
  FX: 'Model',
  Filtertrons: 'Pickups',
  Firebird: 'Model',
  FlameMapBind: 'Binding',
  Flat: 'Construction_Top',
  Fretless: 'Fretwire',
  FrontJack: 'Body_Feature',
  FtJack: 'Body_Feature',
  G5: 'Model',
  GB: 'Archetype',
  GN: 'Archetype',
  GRAND: 'Model',
  GROVE: 'Model',
  GenMah: 'Wood',
  GibsonH: 'Pickups',
  GlnDale: 'Model',
  GndWire: 'Body_Feature',
  GoldMopCrown: 'Inlay',
  GotohDlx: 'Bridge_Type',
  GotohTeleBR: 'Bridge_Type',
  GotohTrem: 'Bridge_Type',
  Grand: 'Model',
  Graph: 'Neck_Feature',
  Graphite: 'Neck_Feature',
  Gresham: 'Model',
  H: 'Pickups',
  HBB: 'Bridge_Type',
  HBridge: 'Bridge_Type',
  HCrv: 'Body_Feature',
  HEARTS: 'Inlay',
  HH: 'Pickups',
  HSBMH: 'Bridge_Modifier',
  HSHT: 'Bridge_Type',
  HSTrem: 'Bridge_Type',
  HT: 'Bridge_Type',
  HTF: 'Bridge_Type',
  Hf: 'Pickups',
  HornCrv: 'Body_Feature',
  IMA5: 'Model',
  IMA: 'Model',
  INSERT: '',
  IRW: 'Wood',
  IvoryF: 'Inlay',
  J430: 'Model',
  J4: 'Model',
  J64: 'Model',
  J: 'Model_Supermodel',
  JAMENT: 'Model',
  JBHeel: 'Body_Feature',
  JBU: 'Model',
  JD: 'Bridge_Type',
  JJ: 'Pickups',
  JMBMH: 'Bridge_Modifier',
  JMBridge: 'Bridge_Type',
  JMTrem: 'Bridge_Type',
  JNoSTH: 'Bridge_Modifier',
  JP: 'Model',
  JT4: 'Model',
  JT5: 'Model',
  JX: 'Model',
  JagNP: 'Body_Feature',
  Jaguar: 'Model',
  JazzB: 'Model',
  JazzCaster: 'Model',
  JazzM: 'Model',
  JazzMaster: 'Model',
  Jazzmaster2PTrem: 'Model',
  Jazzmaster: 'Model',
  JazzmasterLEFTY: 'Model',
  JazzmasterNoTrem: 'Model',
  JazzmasterStlTop: 'Model',
  JazzmasterTOM: 'Model',
  JimD: 'Model_Submodel',
  JoaquinD8: 'Model_Submodel',
  KhyMah: 'Wood',
  Kluson: 'Neck_Feature',
  L6: 'Bridge_Type',
  LAVD: 'Model_Submodel',
  LCCS: 'Pickups',
  LEFTY: 'Model_Modifier',
  LEFTYPHCap: 'Body_Feature',
  LEFTYWoodCover: 'Body_Feature',
  LFS: 'Pickups',
  LFT: 'Model_Modifier',
  LG: 'Neck_Composition',
  LINCOLN: 'Model',
  LKSpecial: 'Model',
  LP: 'Model',
  LPHCap: 'Body_Feature',
  LPJr: 'Model',
  LeftPHCap: 'Body_Feature',
  LeftWoodCover: 'Body_Feature',
  Leopard: 'Model_Submodel',
  Lincoln: 'Model',
  LinedFretless: 'Fretwire',
  LinedFrets: 'Fretwire',
  Lion: 'Model',
  LollarH: 'Pickups',
  LongHeel: 'Neck_Composition',
  LongTenon: 'Neck_Composition',
  Loose43080: 'Fretwire',
  Loose47095: 'Fretwire',
  'LooseBlackF/LooseBlackS': 'Inlay',
  'LooseMopF/LooseMopS': 'Inlay',
  Loose: 'Fretwire_Suffix',
  LooseDots: 'Inlay_Suffix',
  LooseFrets: 'Fretwire_Suffix',
  LrgFerrules: 'Body_Feature',
  Lynx: 'Model',
  M4: 'Model',
  M4V: 'Model',
  M4X: 'Model',
  M4XL: 'Model',
  M4XLite: 'Model',
  M5: 'Model',
  M5V: 'Model',
  M6: 'Model',
  MC: 'Neck_Shape_Carve',
  MH: 'Pickups',
  MHB: 'Pickups',
  MOD: 'Model_Modifier',
  MP4: 'Model',
  MPJ4: 'Model',
  MST: 'Model',
  MUSTANG: 'Model',
  Map: 'Wood',
  Maple: 'Wood',
  MelodyM: 'Model',
  Midwestern2: 'Model',
  Midwestern: 'Model',
  Mirror: 'Model',
  MopBlock: 'Inlay',
  Morningstar: 'Model',
  Mustang: 'Model',
  NBMH: 'Bridge_Modifier',
  NBatt: 'Body_Feature',
  NFBS: 'Finish_Option',
  NFS: 'Finish_Option',
  NMH: 'Body_Feature',
  NOFBS: 'Finish_Option',
  NPO: 'Model_Modifier',
  NPU: 'Pickups',
  NR: 'Model_Modifier',
  NYC: 'Model',
  NarNP: 'Body_Feature',
  NarrowNP: 'Body_Feature',
  NarrowSlots: 'Neck_Feature',
  Nashville: 'Pickups',
  NashvilleTOM: 'Bridge_Type',
  NeckPlate: 'Body_Feature',
  NoACrv: 'Body_Feature',
  NoArmB: 'Body_Feature',
  NoBMH: 'Bridge_Modifier',
  NoBatt: 'Body_Feature',
  NoBridge: 'Body_Feature',
  NoCarves: 'Body_Feature',
  NoCtrl: 'Body_Feature',
  NoCtrlR: 'Body_Feature',
  NoCtrls: 'Body_Feature',
  NoFBS: 'Finish_Option',
  NoFC: 'Body_Feature',
  NoFH: 'Model_Modifier',
  NoFrets: 'Fretwire_Suffix',
  NoFrontJack: 'Body_Feature',
  NoGndWire: 'Body_Feature',
  NoJack: 'Body_Feature',
  NoJackCup: 'Body_Feature',
  NoLogo: 'Neck_Feature',
  NoLowB: 'Body_Feature',
  NoNH: 'Body_Feature',
  NoNMH: 'Body_Feature',
  NoNP: 'Body_Feature',
  NoPG: 'Body_Feature',
  NoPGScrew: 'Body_Feature',
  NoPOTS: 'Body_Feature',
  NoPU: 'Pickups',
  NoPUs: 'Pickups',
  NoPickGuard: 'Body_Feature',
  NoPotHples: 'Body_Feature',
  NoPots: 'Body_Feature',
  NoPu: 'Pickups',
  NoRO: 'Body_Feature',
  NoRoll: 'Neck_Feature',
  NoRouts: 'Body_Feature',
  NoSTH: 'Bridge_Modifier',
  NoShoulderCtrl: 'Body_Feature',
  NoSideJack: 'Body_Feature',
  NoStep: 'Model',
  NoSwitch: 'Body_Feature',
  NoTRA: 'Body_Feature',
  NoTopCtrl: 'Body_Feature',
  NoTopRO: 'Body_Feature',
  NoTrem: 'Body_Feature',
  NoWCrv: 'Body_Feature',
  NoWIreR: 'Body_Feature',
  NoWireR: 'Body_Feature',
  OFFSET: 'Model',
  OGRW: 'Wood',
  OS: 'Model',
  OffSet: 'Model',
  Oko: 'Wood',
  Okou: 'Wood',
  Okoume: 'Wood',
  Olinto: 'Model',
  Oz: 'Model',
  P4: 'Model',
  P51: 'Model',
  P52: 'Model',
  P54: 'Model',
  P55: 'Model',
  P5: 'Model',
  P90: 'Pickups',
  P: 'Pickups',
  PADDLE: 'Neck_Composition',
  PBMH: 'Bridge_Modifier',
  PDC: 'Model_Submodel',
  PG: 'Body_Feature',
  PHCap: 'Body_Feature',
  PHLogoRout: 'Neck_Feature',
  PJ4: 'Model',
  PJ5: 'Model',
  PJ: 'Model',
  PJBU: 'Model',
  POTS: 'Body_Feature',
  PT4: 'Model',
  Paddle: 'Neck_Composition',
  Paint: 'Wood_Feature',
  Panther: 'Model',
  Paulownia: 'Wood',
  PearloidBind: 'Binding',
  PearloidBlock: 'Inlay',
  Pi: 'Neck_Feature',
  PiGraph: 'Neck_Feature',
  Pine: 'Wood',
  Pots: 'Body_Feature',
  Premier: 'Model',
  QSMap: 'Wood',
  Quote: 'Model_Supermodel',
  RC: 'Body_Feature',
  RCrv: 'Body_Feature',
  REVERSEPH: 'Neck_Composition',
  REVPH: 'Neck_Composition',
  RIGHTY: 'Neck_Composition',
  Rainmaker: 'Model',
  RecFloyd: 'Bridge_Type',
  RecessFloyd: 'Bridge_Type',
  RecessedFloyd: 'Bridge_Type',
  RevF: 'Neck_Composition',
  RevP: 'Pickups',
  RevPH: 'Neck_Composition',
  RevS: 'Pickups',
  RndJack: 'Body_Feature',
  RoundFB: 'Neck_Feature',
  RoundJack: 'Body_Feature',
  RoundedJack: 'Body_Feature',
  RouterBump: 'Body_Feature',
  RstAlder: 'Wood',
  RstAlderCS: 'Wood',
  RstAsh: 'Wood',
  RstEWPine: 'Wood',
  RstPine: 'Wood',
  S: 'Pickups',
  SATIN: 'Model',
  SC: 'Model',
  SF: 'Model_Submodel',
  SFM: 'Model_Submodel',
  SIDEJACK: 'Body_Feature',
  SIDEJack: 'Body_Feature',
  SJ: 'Pickups',
  SJack: 'Body_Feature',
  SM14: 'Model',
  SOLID: 'Body_Construction_Modifier',
  SOR: 'Model',
  SRVTrem: 'Bridge_Type',
  SSB: 'Pickups',
  SSH: 'Pickups',
  SSS: 'Pickups',
  ST: '',
  STBBridge: 'Bridge_Type',
  STEPPED: 'Model',
  STH: 'Bridge_Modifier',
  STP: 'Model_Submodel',
  STRAT: 'Model',
  STU: 'Body_Feature',
  SV: 'Bridge_Type',
  SWITCH: 'Body_Feature',
  SWS: 'Model',
  SX: 'Model',
  Sanded: 'Finishing_Option',
  Sassafras: 'Wood',
  Sassafrass: 'Wood',
  Schaller: 'Neck_Feature',
  SetNeck: 'Neck_Composition',
  ShNP: 'Body_Feature',
  ShallowNP: 'Body_Feature',
  ShortCtrl: 'Body_Feature',
  ShortLineFretless: 'Fretwire',
  ShortTenon: 'Neck_Composition',
  SideJack: 'Body_Feature',
  SingleCut: 'Model',
  Skunk: 'Neck_Feature',
  SkunkStr: 'Neck_Feature',
  Soapbar: 'Pickups',
  SolarArrow: 'Model',
  Solid: 'Body_Construction_Modifier',
  Special: 'Model_Submodel',
  Spl: 'Mod',
  Sprl: 'Neck_Feature',
  Square: '',
  StMac154: 'Fretwire',
  Stainless: 'Fretwire',
  Std: 'Body_Feature',
  Stepped: 'Model',
  StlDeVJr: 'Model',
  StlGuard: 'Model',
  StlTop: 'Model',
  Strat: 'Model',
  StratACrv: 'Body_Feature',
  StratBMH: 'Bridge_Modifier',
  StratH: 'Neck_Feature',
  StratHeel: 'Neck_Feature',
  StratNP: 'Body_Feature',
  StratTrem: 'Bridge_Type',
  Stratham: 'Model',
  SugarPine: 'Wood',
  Switch: 'Body_Feature',
  T4: 'Model',
  T5: 'Model',
  T69: 'Model_Submodel',
  T72: 'Model_Submodel',
  TB: 'Model',
  TBATT: 'Body_Feature',
  TBatt: 'Body_Feature',
  TC4: 'Model',
  TC5: 'Model',
  TC63: 'Model_Submodel',
  TC72: 'Model_Submodel',
  TELE: 'Model',
  TEU: 'Model',
  TFC: 'Model',
  TILT: 'Neck_Composition',
  TL: 'Model',
  TLCtrl: 'Body_Feature',
  TOM: 'Bridge_Type',
  TOP: 'Body_Construction_Modifier',
  TOR: 'Model_Submodel',
  TPSIG: 'Model_Submodel',
  TPTOM: 'Model_Submodel',
  TRA: 'Body_Feature',
  TSPH: 'Model_Submodel',
  TT: 'Model_Submodel',
  TVJ: 'Pickups',
  TX: 'Model',
  TXB4: 'Model',
  TXB5: 'Model',
  Tele: 'Model',
  TeleBMH: 'Bridge_Modifier',
  TeleBR: 'Bridge_Type',
  TeleBaritone: 'Model',
  TeleBass: 'Model',
  TeleBr: 'Bridge_Type',
  TeleBridge: 'Bridge_Type',
  TeleCtrl: 'Body_Feature',
  TeleHeel: 'Neck_Feature',
  TeleJack: 'Body_Feature',
  TeleM: 'Model',
  TeleMaster: 'Model',
  TeleMstr: 'Model',
  TeleNP: 'Body_Feature',
  TeleSTH: 'Bridge_Modifier',
  Telemaster: 'Model',
  Telham: 'Model',
  Tenon: 'Neck_Composition',
  ThickTop: 'Body_Construction_Modifier',
  ThinlineFC: 'Body_Feature',
  Tilt: 'Neck_Composition',
  TiltB: 'Neck_Composition',
  Toggle: 'Body_Feature',
  Top: 'Body_Construction_Modifier',
  TopCaster: 'Model',
  TopFloyd: 'Bridge_Type',
  Trem: 'Bridge_Type',
  Truxton: 'Model',
  UNI: 'Pickups',
  Underdog: 'Model',
  UniLg: 'Pickups',
  UniMd: 'Pickups',
  V4: 'Model',
  V52: 'Model',
  V5: 'Model',
  VIN: 'Neck_Composition',
  VTG: 'Wood',
  VTrem: 'Bridge_Type',
  Verdine: 'Model',
  Vin1Pc: 'Neck_Composition',
  Vin: 'Neck_Composition',
  VinHalfPaddle: 'Neck_Composition',
  Vintage: 'Neck_Composition',
  VolPotOnly: 'Body_Feature',
  WCrv: 'Body_Feature',
  WR: 'Model_Modifier',
  WRH: 'Pickups',
  WRHC: 'Model_Modifier',
  WRSH: 'Model_Modifier',
  WWGraph: 'Neck_Feature',
  Wayfarer: 'Model_Submodel',
  WhiteBind: 'Binding',
  WhiteS: 'Inlay',
  WillLee: 'Model',
  WireR: 'Body_Feature',
  Wood: 'Wood',
  WoodCover: 'Body_Feature',
  Wraptail: 'Bridge_Type',
  XJ4: 'Model_Supermodel',
  XJ5: 'Model_Supermodel',
  XJC4: 'Model_Supermodel',
  XS: 'Model_Supermodel',
  XSC: 'Model_Supermodel',
  XSS: 'Model_Supermodel',
  XTC: 'Model_Supermodel',
  XXLite: 'Wood',
};
