import { find, findIndex } from 'lodash';
import { resolveRealtimePath, useTestData } from 'shared/util';
import { IShopOrder } from 'pages/Orders/types';
import { IOrderItem } from 'shared/types/dbRecords';

export const mapInventoryData = (pricingData: any, inventoryPartCosting: { Sku: string, PurchaseCost: number, UnitPrice: number, Description: string }[]) => pricingData.map((p) => {
  const materialCost = { S: 0, M: 0, L: 0 };
  const price = { S: 0, M: 0, L: 0 };
  const label = p.label;

  if (p.partNumber.S) {
    const sPart = find(inventoryPartCosting, (d) => d.Sku === p.partNumber.S.toString());
    materialCost.S = sPart ? sPart.PurchaseCost : (p.materialCost?.S || 0);
    price.S = sPart ? sPart.UnitPrice : p.price.S;
    // label = sPart ? sPart.Description : label;
  }
  const lPart = find(inventoryPartCosting, (d) => d.Sku === p.partNumber.L.toString());
  materialCost.L = lPart ? lPart.PurchaseCost : (p.materialCost?.L || 0);
  price.L = lPart ? lPart.UnitPrice : p.price.L;
  // label = lPart ? lPart.Description : label;

  const mPart = find(inventoryPartCosting, (d) => d.Sku === p.partNumber.M.toString());
  materialCost.M = mPart ? mPart.PurchaseCost : (p.materialCost?.M || 0);
  price.M = mPart ? mPart.UnitPrice : p.price.M;
  // label = mPart ? mPart.Description : label;

  return {
    ...p, materialCost, price, label,
  };
});

const pricingPaths = {
  DT_: { root: 'body', path: 'topWood' },
  CT_: { root: 'body', path: 'topWood' },
  BBK: { root: 'body', path: 'bodyWood' },
  GNB: { root: 'neck', path: 'neckWood' },
  BNB: { root: 'neck', path: 'neckWood' },
  GFB: { root: 'neck', path: 'fretboardWood' },
  BFB: { root: 'neck', path: 'fretboardWood' },
};
export const pricingPathFromPartType = (partType: string) => {
  const { root, path } = pricingPaths[partType];
  return `/${resolveRealtimePath(root)}${useTestData ? 'Dev' : ''}/${path}`;
};

export const updateRevisionNeeded = async (skus: string[], firestore: any, ordersDbString: string, orderItemsDbString: string, customer: string, revisionNeeded: boolean) => {
  const orderDocs = await firestore.collection(ordersDbString).where('customer.DisplayName', '==', customer).where('completed', '==', false).get();
  const orders = orderDocs.docs.map((o) => o.data() as IShopOrder);
  if (orders.length === 0) {
    return true;
  }

  const orderItemsDocs = await Promise.all(orders.map((o: IShopOrder) => firestore.collection(orderItemsDbString).doc(o.id).get()));
  const orderItemsData = orderItemsDocs.map((d) => ({ id: d.id, data: d.data() }))
    .filter((d) => !!d.data)
    .map((d) => ({ id: d.id, orderItems: d.data.orderItems }));

  const updatePromises = orderItemsData.map((o: { id: string, orderItems: IOrderItem[] }) => {
    const updatedItems = o.orderItems.map((item) => {
      if (!item) return;
      if (skus.includes(item.Sku)) {
        return { ...item, revisionNeeded };
      }
      return item;
    }).filter((i) => !!i);

    if (JSON.stringify(updatedItems) !== JSON.stringify(o.orderItems)) {
      return firestore.collection(orderItemsDbString).doc(o.id).update({ orderItems: updatedItems });
    }
    return Promise.resolve();
  });

  await Promise.all(updatePromises);

  return true;
};
